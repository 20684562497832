export default {
  path: '/aplicativo-jogo-do-bicho',
  name: 'download',
  component: () => import('./index.vue'),
  meta: {
    title: 'Baixe o Aplicativo do Jogo do Bicho Online | Bicho no Pix',
    description: 'Instale o aplicativo do Jogo do Bicho online do Bicho no Pix e jogue de forma fácil e segura. Aproveite a melhor experiência de apostas direto no seu celular.',
    canonical: 'aplicativo-jogo-do-bicho'
  }
}
