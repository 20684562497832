import http from '@/core/http'
import _config from '@/core/config'
import { cloneDeep } from 'lodash'

const state = {
  campanha: false,
  promocoes: {
    // minRollOver: '',
    // maxBonusValue: '',
    // maxValue: '',
    // minBonusValue: ''
  }
}

const actions = {
  getPromocoes: async ({ commit }, payload) => {
    let result
    if (payload.isAuth) {
      result = await http.post(`${_config.API}get-avaible-web-bonuses`)
    } else {
      result = await http.post(`${_config.API}get-avaible-web-bonuses-open`)
    }
    if (!result.data) throw new Error(result.data.message)
    commit('ADD_PROMOCOES', result.data)
    return Promise.resolve(result)
  },
  setPromocao: async ({ commit }, payload) => {
    const result = await http.post(`${_config.API}set-bonus-web`, payload)
    if (!result.data) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  set: async ({ commit }, payload) => {
    const result = await http.post(`${_config.API}set-bonus-web`, payload)
    if (!result.data) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  updateDadosPromocao: async ({ commit }, payload) => {
    const result = await http.post(`${_config.API}cadastro`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  getCampanhaBtag: async ({ commit }, payload) => {
    const result = await http.post(`${_config.API}get-campanha`, payload)
    if (!result.data.success) throw new Error(result.data.message)      
    commit('ADD_TAG_CAMPANHA', result.data.data)
    return Promise.resolve(result)
  },
  // cancelarBonus: async (_, payload) => {
  //   const result = await http.post(`${_config.API}cancel-bonus`, payload)
  //   if (!result.data) throw new Error(result.data.message)
  //   return Promise.resolve(result)
  // },
  limparPromocoes: ({ commit }) => {
    commit('LIMPAR_PROMOCOES')
  }
}
const mutations = {
  'ADD_PROMOCOES' (state, payload) {
    state.promocoes = cloneDeep(payload.data)
  },
  'ADD_TAG_CAMPANHA' (state, payload) {
    state.campanha = payload
  },
  'LIMPAR_PROMOCOES' (state) {
    state.promocoes = []
  }
}

const getters = {
  listPromocoes: (state) => state.promocoes,
}

export default { actions, mutations, state, getters, namespaced: true }
