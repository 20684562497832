import BTModalidades from './modalidades/mBT'
import { tabelaGrupos } from './helpers/'
export default {
  active: true,
  // titulo: 'Tradicional',
  // sub: 'Ou se preferir, confira o passo a passo para realizar a sua aposta.',
  // sub1: 'Para começar a apostar, é muito fácil!',
  sub: `
    <div class="mb-2">
      O Bilhete Tradicional existe te há mais de um século, em que são feitos sorteios aleatórios de um conjunto finito de milhares entre 0000 e 9999. O Bicho no Pix contempla o sorteio de 5 milhares do 1º ao 5º prêmio sequencialmente a cada concurso de prognósticos. Os sorteios são realizados pela Loteria Federal, Look Loterias e Paratodos do Rio de Janeiro.
    </div>

    <div>
      No Bilhete Tradicional, cada milhar sorteada representa um bicho, de acordo com a sua dezena, ou seja, os dois algarismos finais. As dezenas são agrupadas de 4 em 4 e por isso, as modalidades recebem o nome de "Grupo".
    </div>
  `,

  /* 2 */
  listMolidades: true, /* mostra modaldiades  */

  sub2: '<div class="text-center mb-2"> Modalidades: Como funciona </div>',
  sub21: `
    Veja na tabela abaixo, os grupos de cada dezena e os bichos que eles representam.
  `,
  sub23: 'Tabela de Grupos agrupados em suas respectivas dezenas',

  /* Tabela de bichos */
  tableBicho: tabelaGrupos(),

  /* 3 */
  sub3: 'Modalidades',
  sub31: `
    <div class="mt-1">Veja abaixo as modalidades do Bilhete Tradicional e o valor da cotação de cada uma delas.</div>
    <div class="text-pontilhados mt-1">
      <span> Milhar </span>
      <span class="mx-1"></span>
      <span>   6.000x </span>
    </div>

    <div class="text-pontilhados">
      <span> Centena </span>
      <span class="mx-1"></span>
      <span> 600x  </span>
    </div>

    <div class="text-pontilhados">
      <span> Dezena </span>
      <span class="mx-1"></span>
      <span> 60x   </span>
    </div>

    <div class="text-pontilhados">
      <span> Unidade </span>
      <span class="mx-1"></span>
      <span> 7x </span>
    </div>

    <div class="text-pontilhados">
      <span> Duque de Dezenas </span>
      <span class="mx-1"></span>
      <span> 300x </span>
    </div>

    <div class="text-pontilhados">
      <span> Terno de Dezenas - Terno </span>
      <span class="mx-1"></span>
      <span> 5.000x  </span>
    </div>

    <div class="text-pontilhados">
      <span> Terno de Dezenas - Duque </span>
      <span class="mx-1"></span>
      <span> 40x  </span>
    </div>

    <div class="text-pontilhados">
      <span> Grupo </span>
      <span class="mx-1"></span>
      <span>  18x  </span>
    </div>

    <div class="text-pontilhados">
      <span> Dupla de Grupo 1º ao 2º </span>
      <span class="mx-1"></span>
      <span> 160x  </span>
    </div>

    <div class="text-pontilhados ">
      <span> Dupla de Grupo 1º ao 5º </span>
      <span class="mx-1"></span>
      <span> 18x  </span>
    </div>

    <div class="text-pontilhados ">
      <span> Terno de Grupo 1º ao 3º </span>
      <span class="mx-1"></span>
      <span> 1300x  </span>
    </div>
    
    <div class="text-pontilhados ">
      <span> Terno de Grupo 1º ao 5º </span>
      <span class="mx-1"></span>
      <span> 150x  </span>
    </div>

    <div class="text-pontilhados ">
      <span> Quadra de Grupo - Quadra </span>
      <span class="mx-1"></span>
      <span> 1.000x  </span>
    </div>

    <div class="text-pontilhados ">
      <span> Quadra de Grupo - Terno </span>
      <span class="mx-1"></span>
      <span> 15x  </span>
    </div>

    <div class="text-pontilhados ">
      <span> Quina de Grupos - Quina  </span>
      <span class="mx-1"></span>
      <span> 5.000x  </span>
    </div>

    <div class="text-pontilhados ">
      <span> Quina de Grupos - Quadra </span>
      <span class="mx-1"></span>
      <span> 80x  </span>
    </div>

    <div class="text-pontilhados ">
      <span> Quina de Grupos - Terno  </span>
      <span class="mx-1"></span>
      <span> 10x  </span>
    </div>

    <div class="text-pontilhados">
      <span> Passe 1º ao 2º Prêmio </span>
      <span class="mx-1"></span>
      <span> 300x  </span>
    </div>

    <div class="text-pontilhados mb-4">
      <span> Passe 1º ao 5º Prêmio  </span>
      <span class="mx-1"></span>
      <span> 90x  </span>
    </div>

    Para jogar no Bilhete Tradicional, escolha a data de sua aposta e a loteria de sua preferência, clique em continuar. Você pode marcar mais de uma loteria e mais de um horário. Marque a opção Todos, se quiser jogar em todos os horários.
  `,

  sub4: 'Veja como apostar em cada modalidade',

  sub41: 'Milhar',

  sub42: `
    <div class="text-cinza mb-4 text-justify mb-4">
      Para jogar na Milhar, digite uma sequência de 4 algarismos, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantas Milhares desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar apenas no primeiro prêmio ou nos cinco prêmios sorteados, ou ainda, nas duas opções preenchendo os campos com os valores de suas apostas. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,

  sub43: 'Centena',
  sub44: `
  <div class="text-cinza mb-4 text-justify mb-4">
    Para jogar na Centena, digite uma sequência de 3 algarismos, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantas Centenas desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
  </div>

  <div class="text-cinza mb-4 text-justify mb-4">
    Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar apenas no primeiro prêmio ou nos cinco prêmios sorteados, ou ainda, nas duas opções preenchendo os campos com os valores de suas apostas. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
    A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
    Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,

  sub45: 'Dezena',
  sub46: `
    <div class="text-cinza mb-4 text-justify mb-4">
      Para jogar na Dezena, digite uma sequência de 2 algarismos, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantas Dezenas desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>
    <div class="text-cinza mb-4 text-justify mb-4">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar apenas no primeiro prêmio ou nos cinco prêmios sorteados, ou ainda, nas duas opções preenchendo os campos com os valores de suas apostas. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,

  sub47: 'Unidade',
  sub48: `
    <div class="text-cinza mb-4 text-justify mb-4">
      Para jogar na Unidade, digite um algarismo, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantas Unidades desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar apenas no primeiro prêmio ou nos cinco prêmios sorteados, ou ainda, nas duas opções preenchendo os campos com os valores de suas apostas. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="mb-3">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,

  sub49: 'Duque de Dezenas',
  sub400: `
    <div class="text-cinza mb-4 text-justify mb-4">
      Para jogar no Duque de Dezenas, digite duas Dezenas, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantos Duques de Dezenas desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar apenas do primeiro ao quinto prêmio preenchendo o campo com o valor de sua aposta. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,
  sub401: 'Terno de Dezenas',
  sub402: `
    <div class="text-cinza mb-4 text">
      Para jogar no Terno de Dezenas, digite três Dezenas, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantos Ternos de Dezenas desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>

    <div class="text-cinza mb-4 text">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar apenas do primeiro ao quinto prêmio preenchendo o campo com o valor de sua aposta. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,

  sub403: 'Grupo',
  sub404: `
    <div class="text-cinza mb-4 text">
      Para jogar no Grupo, escolha um número entre 1 e 25, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantos Grupos desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>

    <div class="text-cinza text-justify mb-4">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar apenas no primeiro prêmio ou nos cinco prêmios sorteados, ou ainda, nas duas opções preenchendo os campos com os valores de suas apostas. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4 text">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    <div/>

    <div class="text-cinza mb-4 text-justify mb-4">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,

  sub405: 'Dupla de Grupos',
  sub406: `
    <div class="text-cinza mb-4 text-justify mb-4">
      Para jogar na Dupla de Grupos, escolha dois números entre 1 e 25, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantas Duplas de Grupos desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4 text">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar do primeiro ao segundo prêmio ou do primeiro ao quinto, ou ainda, nas duas opções preenchendo os campos com os valores de suas apostas. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,

  sub407: 'Terno de Grupos',
  sub408: `
    <div class="text-cinza mb-4 text-justify mb-4">
      Para jogar no Terno de Grupos, escolha três números entre 1 e 25, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantos palpites desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4 text">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar do primeiro ao terceiro prêmio ou do primeiro ao quinto, ou ainda, nas duas opções preenchendo os campos com os valores de suas apostas. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,

  sub409: 'Quadra de Grupos',
  sub410: `
    <div class="text-cinza mb-4 text-justify mb-4">
      Para jogar na Quadra de Grupos, escolha quatro números entre 1 e 25, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantas Quadras de Grupos desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4 text">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar apenas do primeiro ao quinto prêmio, preenchendo o campo com o valor de sua aposta. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>
    <div class="text-cinza mb-4 text-justify mb-4">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,
  sub411: 'Quina de Grupos',
  sub412: `
    <div class="text-cinza mb-4 text-justify mb-4">
      Para jogar na Quina de Grupos, escolha 5 números entre 1 e 25, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantas Quinas de Grupos desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4 text">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar apenas do primeiro ao quinto prêmio, preenchendo o campo com o valor de sua aposta. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>
    <div class="text-cinza mb-4 text-justify mb-4">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,

  sub413: 'Passe',
  sub414: `
    <div class="text-cinza mb-4 text-justify mb-4">
      Para jogar no Passe é fácil, escolha dois números entre 1 e 25, ou se preferir, dê um clique no botão laranja para gerar um número aleatório. Repita o processo para apostar em quantos palpites desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4 text">
      Quando terminar de escolher todos os seus palpites clique em "Continuar". Você pode apostar do primeiro ao segundo prêmio ou do primeiro ao quinto, ou ainda, nas duas opções preenchendo os campos com os valores de suas apostas. Lembre-se de que o valor inserido será dividido pela quantidade de palpites que você tiver marcado. Ao preencher o valor, clique no botão "Adicionar Aposta".
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: Ao clicar em fazer mais apostas você volta para a tela inicial do Bilhete Tradicional, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
    </div>

    <div class="text-cinza mb-4 text-justify mb-4">
      Ao clicar em finalizar você volta para a página principal do Bicho no Pix, onde poderá apostar em outros produtos.
    </div>
  `,
  ...BTModalidades,
}
