export default {
  path: '/:pathMatch(.*)*',
  name: 'auth',
  component: () => import(/* webpackChunkName: "auth" */'./index'),
  children: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "auth" */'./index'),
      meta: {
        title: 'Jogo do Bicho Online: Aposte e Ganhe | BICHO NO PIX',
        description: 'Aposte no jogo do bicho online no BICHO NO PIX e descubra como ganhar grandes prêmios. Jogue agora e divirta-se com muitas opções de apostas!',
        ogDescription: 'Aposte no jogo do bicho online no BICHO NO PIX e descubra como ganhar grandes prêmios. Jogue agora e divirta-se com muitas opções de apostas!',
        canonical: window.location.href
      }
    }
  ]
}
