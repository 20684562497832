// import mQB from './modalidades/mQUINA'

export default {
  tableBicho: [],
  listMolidades: true,
  videoModalidade: true,
  titulo: 'Quina Brasil',
  isModalidadeCotacaoUnica: true, /* mostra modalidade mas apenas 1 */
  active: true,
  dadosCotacao: {
    text: 'Quina Brasil'
  },
  sub: '',
  // imgAjuda: true,
  sub2: 'Como apostar',
  sub21: `
    <div class="mb-2">
      Na Quina Brasil você concorre com o resultado da Quina da Loteria da CAIXA, que acontece de segunda-feira a sábado às 20h. Em um único evento são sorteadas 5 dezenas aleatórias entre 01 e 80 e você ganha se acertar na Quina, Quadra e terno. O seu prêmio é calculado de acordo com a cotação abaixo:
    </div>

    <div class="text-pontilhados bold">
      <span> Quina </span>
      <span class="mx-1"></span>
      <span>  3.000x </span>
    </div>

    <div class="text-pontilhados bold">
      <span> Quadra </span>
      <span class="mx-1"></span>
      <span>  100x  </span>
    </div>

    <div class="text-pontilhados bold">
      <span> Terno </span>
      <span class="mx-1"></span>
      <span>  8x  </span>
    </div>

    <div class="mb-2"> Para jogar na Quina Brasil, siga os passos abaixo: </div>

    <ul class="lista">
      <li>
        Marque os seus números até completar 13 dezenas, ou se preferir, dê um clique no botão laranja para gerar uma aposta aleatória;
      </li>

      <li>
        Repita o processo para apostar em quantos palpites desejar; <br/>
        Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no xis ao lado do botão laranja para remover todos os palpites; <br/>
        Ao realizar várias apostas, elas são empilhadas e só as últimas ficam visíveis no painel. As demais vão aparecer no bilhete ao final da aposta.
      </li>
      <li>
        Clique em "Continuar";
      </li>

      <li>
        Informe o valor que deseja apostar;
      </li>

      <li>
        Informe o valor que deseja apostar; <br/>
        A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: “+ Apostas” e “Finalizar”.
      </li>

      <li>
        Clicando em fazer mais apostas você volta para a tela inicial da Surpresinha, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo;
      </li>

      <li>
        Clicando em finalizar você volta para a página principal do BICHO NO PIX, onde poderá apostar em outros produtos.
      </li>

    </ul>
  `,
  imgAjuda2: true,

  // /* 3 */
  // sub3: '3º - ESCOLHA DO PALPITE',
  // sub31: 'Escolha 13 dezenas de 0 à 80 números para formar um palpite.',
  // imgAjuda3: true,

  // /* 4 */
  // sub4: '4º - VALOR DA APOSTA',
  // sub41: 'Insira o valor desejado e clique no botão “apostar”. ',
  // imgAjuda4: true,

  // /* 5 */
  // sub5: '5- CONCLUINDO SUA APOSTA',
  // sub51: `
  //   Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio. <br/>
  // `,
  imgAjuda5: true,
  // modalidade
  // ...mQB
}
