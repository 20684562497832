import currency from 'currency.js'

export default (value, notSimbol = false, decimal = false) => {
  // numero usado para cotacao
  if (notSimbol && decimal) return new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 0 }).format(value)

  const valueFormat = String(value).replace('.', ',')
  if (notSimbol) return currency(valueFormat, { separator: '.', decimal: ',', symbol: '', pattern: '! #', negativePattern: '! -#' }).format()
  return currency(valueFormat, { separator: '.', decimal: ',', symbol: 'R$', pattern: '! #', negativePattern: '! -#' }).format()
}
