// import mSUPRESINHA from './modalidades/mSUPRESINHA'

export default {
  tableBicho: [],
  listMolidades: true,
  isModalidadeCotacaoUnica: true, /* mostra modalidade mas apenas 1 */
  active: true,
  dadosCotacao: {
    text: 'Surpresinha'
  },
  sub: '',

  /* 2 */
  sub2: 'Como apostar',
  sub21: `
  <div class="mb-2">
    Na Surpresinha você concorre com todos os sorteios realizados pela Look Loterias, PT Rio e Loteria Federal. O resultado da Surpresinha é extraído do sorteio do Tradicional, ou seja, as 5 dezenas obtidas do 1° ao 5° Prêmio. Se vierem dezenas repetidas no resultado, vale a próxima dezena, somando +1 ao seu valor. Se ao desempatar, a outra dezena também foi sorteada, soma-se +1, até que não haja mais repetições.
  </div>
  <div class="mb-2">
    Em um único evento são sorteadas 17 dezenas aleatórias entre 01 e 00 e concorre com a quina, quadra, terno ou dupla e concorre aos prêmios conforme a cotação abaixo:
  </div>
    <div class="text-pontilhados">
      <span> Quina </span>
      <span class="mx-1"></span>
      <span>   2.000x </span>
    </div>

    <div class="text-pontilhados">
      <span> Quadra </span>
      <span class="mx-1"></span>
      <span>   50x </span>
    </div>

    <div class="text-pontilhados">
      <span> Terno </span>
      <span class="mx-1"></span>
      <span>   5x </span>
    </div>

    <div class="text-pontilhados mb-3">
      <span> Dupla </span>
      <span class="mx-1"></span>
      <span>   1x </span>
    </div>

    <div class="mb-2"> Para jogar na Surpresinha, siga os passos abaixo: </div>
    <ul class="lista">
      <li>
        Marque os seus números até completar 17 dezenas, ou, se preferir, dê um clique no botão laranja para gerar uma aposta aleatória;
      </li>

      <li>
        Repita o processo para apostar em quantos palpites desejar; <br/>
        Se você errar ao escolher um número e quiser substituí-lo, clique no “X” no final da linha. Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites; <br/>
        Ao realizar várias apostas, elas são empilhadas e só as últimas ficam visíveis no painel. As demais vão aparecer no bilhete ao final da aposta.
      </li>

      <li>
        Clique em "Continuar";
      </li>

      <li>
        Informe o valor que deseja apostar; <br/>
        A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: “+ Apostas” e “Finalizar”.
      </li>

      <li>
        Clicando em fazer mais apostas você volta para a tela inicial da Surpresinha, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo;
      </li>
      <li>
        Clicando em finalizar você volta para a página principal do BICHO NO PIX, onde poderá apostar em outros produtos.
      </li>

    </ul>
  `,
  // ...mSUPRESINHA
}
