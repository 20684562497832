
const state = {
  dados: false,
  storage: false
}

const actions = {
  async setDados ({ commit }, payload) {
    commit('SET_DADOS', payload)
    return Promise.resolve(payload)
  },
  async applyStorage ({ commit }, payload) {
    commit('SET_DADOS_STORAGE', payload)
    return Promise.resolve(payload)
  },

  async clear ({ commit }, payload) {
    state.dados = false
    return Promise.resolve(true)
  },

  async clearStorage ({ commit }, payload) {
    state.storage = false
    return Promise.resolve(true)
  }
}

const mutations = {
  'SET_DADOS' (state, { modalidade, palpites, loterias, premios, value }) {
    state.dados = ''
    state.dados = {
      modalidade,
      palpites,
      loterias,
      premios,
      value
    }
  },
  'SET_DADOS_STORAGE' (state, payload) {
    state.dados = false
    state.storage = payload
  },

  'SET_SALDOS' (state, payload) {
    state.dados = payload
  }
}

const getters = {
  getStorage: state => state.storage
}

export default { state, actions, mutations, getters, namespaced: true }
