import { map, sortBy, size, flatten, groupBy, maxBy, range } from 'lodash'
import moment from 'moment'
import http from '@/core/http'
import session from '@/core/service/session'
import _config from '@/core/config'
import { formatPremioModalidade } from '@/core/service/utils'
import { Events } from '@/main'

const state = {
  carrinho: [],
  comprovante: [],
  resultadoInsta: [],
  updateValue: 0,
  pixData: {},
  total: 0,
  pixVerication: null,
  isRedirectFinalizarAposta: false,
  fullNameEntityGameOpen: ''
}

const sizePremios = (dados, modalidade) => {
  if (modalidade.MULT_PREMIOS_WEB === 1) return 1
  if (dados === 12) return 2
  if (dados === 13) return 3
  if (dados === 14) return 4
  if (dados === 15) return 5
  return (size(dados) || 1)
}

const calculoValorPalpite = (payload, type = false) => {
  const { total, palpites, minValue } = payload
  const premios = sizePremios(+payload.formatPremios.id, payload.modalidade)
  let calculo
  /* se for valor minimo multiplica tudo se for maximo divide tudo */
  if (type === 'minValue') {
    calculo = parseFloat(minValue * premios * size(palpites))
  } else {
    calculo = parseFloat((total || 1) / premios / size(palpites))
  }
  return calculo
}

const formatPremios = (premio, modalidade, palpites = []) => {
  if (!palpites && !palpites[0]) return 'Não encontrado prêmio'
  const text = formatPremioModalidade(premio, modalidade)
  if (text.dezena) return palpites[0].val.split('-').length
  return text.text
}

// eslint-disable-next-line
const possivelGanho = (game) => {
  let premio = formatPremios(game.formatPremios.id, game.modalidade, game.palpites)
  const { dezena } = formatPremioModalidade(premio, game.modalidade)
  if (!dezena) premio = parseInt(game.formatPremios.id)
  const cotacoes = map(game.modalidade.AR_COTACAO, (item) => ({ ...item, VL_PREMIO: parseFloat(item.VL_PREMIO) }))
  const arrCotacoes = cotacoes.filter(it => it.IN_PREMIO === parseInt(premio))
  const cotacao = arrCotacoes.length ? maxBy(arrCotacoes, 'VL_PREMIO') : maxBy(cotacoes, 'VL_PREMIO')

  const premios = game.modalidade.CD_PREM
  const qtdPalpites = game.palpites.length
  const isDividir = premios.some(item => size(String(item)) === 1)
  let qtdPremios = 1
  if (!dezena) {
    const [num1, num2] = String(game.formatPremios.id).split('')
    const qtd = String(game.formatPremios.id).length >= 2 ? range(parseInt(num1), parseInt(num2) + 1).length : 1
    qtdPremios = isDividir ? qtd : 1
  }

  const valor = ((game.total / qtdPalpites) / qtdPremios) * (cotacao ? cotacao.VL_PREMIO : game.modalidade.COTACAO)
  game.ganho = valor
  return Math.floor(valor * 100) / 100
}

const actions = {
  startPixVerification: ({ commit, state, dispatch }, payload) => {
    if (state.pixVerication) clearInterval(state.pixVerication)

    state.pixVerication = setInterval(async () => {
      const check = await dispatch('checkGamePaymentStatus', state.pixData.id)
      if (check.data.data && check.data.data.result) {
        commit('STOP_INTERVAL_PIX')
        Events.emit('PIX_ADVANCE_REDIRECT_CARRINHO', check.data)
      }
      return Promise.resolve(check.data)
    }, 10000)
  },
  clearPixVerification: ({ commit }) => {
    commit('STOP_INTERVAL_PIX')
  },
  addPixData: async ({ state, commit }, payload) => {
    commit('ADD_PIX_DATA', payload)
    return Promise.resolve(payload)
  },
  clearPixData: async ({ state, commit }, payload) => {
    commit('CLEAR_PIX_DATA', {})
    return Promise.resolve(payload)
  },

  addShop: async ({ state, dispatch, root }, payload) => {
    const dados = Object.assign({}, payload)
    dados.minValue = calculoValorPalpite(dados, false)
    dados.minValueFormat = dados.minValue

    /* multiplicacao valores por tudo qtd de palpites premios  */
    // const qtdPalpites = size(payload.palpites)
    // const qtdPremios = sizePremios(+payload.formatPremios.id, payload.modalidade)
    // dados.totalFormat = (payload.total / 100) * qtdPalpites * qtdPremios
    // dados.total = (payload.total / 100) * qtdPalpites * qtdPremios

    dados.totalFormat = payload.total
    dados.total = payload.total

    dados.possivelGanho = possivelGanho(dados)

    state.carrinho.total = state.carrinho.total + payload.total
    state.carrinho.push(dados)

    session.set('user-shop', state.carrinho)
  },

  setSessionCart: async ({ state }, payload) => {
    const shop = session.get('user-shop')
    if (!shop) {
      state.carrinho = []
      return Promise.resolve(true)
    }
    state.carrinho = shop
  },

  deleteShop: async ({ state, dispatch }, payload) => {
    const teste = [...state.carrinho]
    state.carrinho = []

    state.carrinho = teste.filter((v, k) => JSON.stringify(v) !== JSON.stringify(payload))
    state.carrinho.total = state.carrinho.reduce((a, b) => a.total + b.total, { total: 0 })
    await dispatch('checkItensCarrinhoExpire')
    return Promise.resolve(state.carrinho)
  },

  deletePalpiteShop: async ({ state }, payload) => {
    const dados = Object.assign(state.carrinho)
    if (dados[payload.indice]) {
      const palpites = dados[payload.indice].palpites
      dados[payload.indice].palpites = palpites.filter((v, k) => k !== payload.deleted)
    }

    state.carrinho[payload.indice].palpites = dados[payload.indice].palpites
    state.carrinho[payload.indice].total = parseFloat(calculoValorPalpite(dados[payload.indice], 'minValue'))
    // state.carrinho[payload.indice].possivelGanho = possivelGanho(state.carrinho[payload.indice])

    // state.carrinho = dados
    session.set('user-shop', state.carrinho)
    return Promise.resolve({ total: state.carrinho[payload.indice].total, minValue: state.carrinho[payload.indice].minValue })
  },

  updateValue: async ({ state }, payload) => {
    const valueFormat = payload.value
    payload.value = String(valueFormat).indexOf('$') !== -1 ? parseFloat(String(valueFormat).replace('R$', '').replace(',', '.')) : parseFloat(payload.value)

    const item = state.carrinho.filter((v, k) => k === payload.indice)[0]
    if (!item) {
      return Promise.resolve(item)
    }

    // realiza calculo valor minimo que altera valor maximo
    if (payload.type === 'minValue') {
      item.minValue = payload.value || 0
      item.minValueFormat = payload.value || 0

      item.valorPorPalpite = payload.value
      item.total = parseFloat(calculoValorPalpite(state.carrinho[payload.indice], payload.type))
      item.valorPalpite = item.total || 0
      item.totalFormat = item.total

      item.formatPremios.value = item.total
      state.carrinho[payload.indice] = item

      // state.carrinho[payload.indice].possivelGanho = possivelGanho(state.carrinho[payload.indice])

      session.set('user-shop', state.carrinho)

      state.updateValue = 1
      return Promise.resolve(item)
    }

    item.total = payload.value || 0
    item.totalFormat = payload.value || 0

    item.formatPremios.total = payload.value
    item.minValue = parseFloat(calculoValorPalpite(state.carrinho[payload.indice])) || 0
    item.minValueFormat = item.minValue || 0

    item.valorPorPalpite = item.minValue
    item.valorPalpite = item.total
    item.formatPremios.minValue = item.minValue

    state.carrinho[payload.indice] = item

    // state.carrinho[payload.indice].possivelGanho = possivelGanho(state.carrinho[payload.indice])

    session.set('user-shop', state.carrinho)

    state.updateValue = 1
    return Promise.resolve(item)
  },

  finalizarAposta: async ({ commit, rootGetters }, payload) => {
    const itens = state.carrinho
    if (!size(itens)) return
    const results = []

    const defaults = {
      D: moment().format('YYMMDD'), // data aposta
      H: '',
      T: rootGetters['carrinho/listCarrinho'].total,
      U: itens[0].userId,
      B: [],
      V: rootGetters['_getVersion'],
      palpiteDigitado: itens[0].palpiteValue
      // possitions: session.get('gps')
    }
    if (payload.isBonus) defaults.type = 'bonus'

    for (const payload of itens) {
      const formatLoterias = map([payload.formatLoterias], v => {
        const { data, modalidade, palpites, formatPremios, valorPalpite, productId } = payload
        const validate = [!data, !modalidade, !palpites, !formatPremios, !valorPalpite, !productId]
        // const validate = [!data, !modalidade, !palpites, !formatPremios, !productId]
        if (validate.indexOf(true) !== -1) {
          throw new Error('Dados invalidos para aposta')
        }
        if (modalidade.IN_PREMIO_SAO_DEZENAS === 1) {
          const qtdPalpite = palpites[0].val.split('-').length
          formatPremios.id = modalidade.CD_PREM.indexOf(qtdPalpite) !== -1 ? qtdPalpite : false
          if (!formatPremios.id) throw new Error(`Prêmios invalido para tamanho do palpite ${qtdPalpite}`)
        }

        return {
          data,
          idLoteria: v.ID_LOT,
          modalidade,
          palpites: [palpites],
          premios: [formatPremios.id],
          valorPalpite,
          produtoId: productId
        }
      })

      const _bilhetes = map(formatLoterias, (it, k) => {
        return {
          Produto: it.produtoId,
          A: moment(it.data).format('YYMMDD'), // data aposta
          L: [it.idLoteria], // loteria
          R: it.repeticao || 1, // numero de repeticoes
          P: map(it.palpites, (o) => { // palpites
            return {
              M: it.modalidade.ID_MODAL, // modalidades
              R: it.repeticao || 1, // numero de repeticoes
              E: it.premios, // premios
              J: flatten(((value) => { // palpites do jogo
                if (it.modalidade.IN_PALP_ORD === 1) return map(value, v => sortBy(String(v.val).split('-')).join('').replace(/,/g, ''))
                return map(value, v => String(v.val).replace(/-/g, '').replace(/,/g, ''))
              })(o)),
              V: it.valorPalpite
            }
          })
        }
      })
      defaults.B.push(_bilhetes[0])
    }

    if (payload.saveHistory) {
      const historyCarrinho = await http.post(_config.SAVE, defaults)
      return Promise.resolve(historyCarrinho.data)
    }

    if (payload.saleWithoutRegistration) {
      defaults.CID = payload.entityCPF
      defaults.dataInfo = payload.dados
      const request = await http.post(_config.GAME_OPEN, defaults)
      const historyCarrinho = request
      if (!historyCarrinho.data.success) throw new Error(historyCarrinho.data.message)
      commit('SET_FULLNAME_GAME_OPEN', historyCarrinho.data.data.entityFullName)
      return Promise.resolve(historyCarrinho.data)
    }

    const aposta = await http.post(_config.START_GAME, defaults)
    if (!aposta.data.success) throw new Error(aposta.data.message)
    results.push(aposta.data.data)
    session.remove('user-shop')
    // commit('ADD_COMPROVANTE', { response: results, request: itens })

    return Promise.resolve(results)
  },

  finalizarApostaRaspadinha: async ({ commit }, payload) => {
    payload.date = moment().format('YYMMDD')
    const aposta = await http.post('scratch/buy', payload)
    if (!aposta.data.success) throw new Error(aposta.data.message)

    commit('ADD_COMPROVANTE_RASPADINHA', { response: aposta.data.data, request: payload })
    return Promise.resolve(aposta)
  },

  clearCarrinho: async ({ commit, state }, _) => {
    state.carrinho = []
    session.remove('user-shop')
  },

  clearComprovante: async ({ commit, state }, _) => {
    state.comprovante = []
  },

  addParseJson: async ({ commit }, _) => {
    const request = await http.post(_config.SAVE, state.carrinho)
    if (!request.data.success) throw new Error('Dados do carrinho e enviados inválidos.!')
  },

  checkItensCarrinhoExpire: async ({ commit }, _) => {
    const list = state.carrinho
    const dataAtual = await http.get('data-system')
    if (!dataAtual.data.success) throw new Error('Nao foi posivel verificar data')

    const filtro = list.filter(v => {
      // console.log('expire', v.expireDate)
      // console.log('atual', dataAtual.data.data.now)
      // console.log('resultado', moment('2024-06-06 11:30', 'YYYY-MM-DD HH:mm').isAfter(dataAtual.data.data.now))
      return moment(v.expireDate, 'YYYY-MM-DD HH:mm').isAfter(dataAtual.data.data.now)
    })

    commit('EXPIRE_CARRINHO', filtro)
    return Promise.resolve(true)
  },

  checkGamePayment: async ({ commit }, payload) => {
    console.log('chegou parametros', payload)
    if (typeof payload !== 'object') payload = { pendingId: parseInt(payload) }
    const aposta = await http.post('check-game-payment', payload)
    if (!aposta.data.success) throw new Error(aposta.data.message)
    return Promise.resolve(aposta.data)
  },

  addRedirectFinalizarAposta: async ({ commit }) => {
    commit('ADD_REDIRECT_FINALIZAR_APOSTA', true)
  },

  removeRedirectFinalizarAposta: async ({ commit }) => {
    commit('ADD_REDIRECT_FINALIZAR_APOSTA', false)
  },
  checkGamePaymentStatus: async ({ commit }, payload) => {
    console.log('chegou parametros', payload)
    if (typeof payload !== 'object') payload = { pendingId: parseInt(payload) }
    const aposta = await http.post('check-game-status', payload)
    if (!aposta.data.success) throw new Error(aposta.data.message)
    return Promise.resolve(aposta.data)
  }
}

const mutations = {
  'STOP_INTERVAL_PIX' (state) {
    if (state.pixVerication) {
      clearInterval(state.pixVerication)
    }
  },
  'ADD_PIX_DATA' (state, payload) {
    state.pixData = payload
  },
  'CLEAR_PIX_DATA' (state, payload) {
    state.pixData = payload
  },
  'SET_FULLNAME_GAME_OPEN' (state, payload) {
    state.fullNameEntityGameOpen = payload
  },
  'ADD_COMPROVANTE' (state, payload) {
    state.comprovante = payload
    console.log(JSON.stringify(payload))
  },
  'ADD_COMPROVANTE_RASPADINHA' (state, payload) {
    state.comprovante = payload
    console.log(JSON.stringify(payload))
  },
  'EXPIRE_CARRINHO' (state, payload) {
    state.carrinho = payload
    session.set('user-shop', payload)
  },
  'ADD_REDIRECT_FINALIZAR_APOSTA' (state, paylaod) {
    state.isRedirectFinalizarAposta = paylaod
  },
}

const getters = {
  listCarrinho: state => {
    const updateValues = state.carrinho[0] ? state.carrinho.map(v => ({ ...v, palpiteValue: v.palpiteValue * 100, qtdPalpites: v.palpites.length })) : []
    return {
      qtd: size(state.carrinho),
      list: updateValues,
      total: updateValues.reduce((acc, item) => (item.total) + acc, 0)
    }
  },

  getComprovante: (state) => {
    if (!size(state.comprovante)) return false
    const req = state.comprovante.request
    const res = state.comprovante.response.responseJson

    const format = {
      bilheteUnico: 'GYN.' + res.I, // id Bilhete Unico
      dataHora: moment(res.A, 'YYMMDDHHmmss').format('DD/MM/YYYY [-] HH:mm'),
      games: map(res.B, v => {
        return {
          dataAposta: moment(v.D, 'YYMMDDHHmmss').format('DD/MM/YYYY HH:mm:ss'), // vendo ws
          dataApostaFormat: moment(v.D, 'YYMMDDHHmmss').format('DD/MM/YYYY'),
          loterias: req.formatLoterias,
          modalidade: req.modalidade,
          premios: req.formatPremios,
          palpites: req.formatPalpites,
          total: req.total,
          valorPalpite: req.valorPalpite
        }
      }),
      gameBonus: size(state.comprovante.response.gameBonus) ? state.comprovante.response.gameBonus : { format: false },
      resultadoInsta: size(state.comprovante.response.instaRes) ? state.comprovante.response.instaRes : false
    }

    return format
  },
  formatCarrinhoRequest: (state) => {
    if (!size(state.carrinho)) return false
    const group = groupBy(state.carrinho, v => `${moment(v.data).format('DD/MM/YYYY')}#${v.modalidade.ID_MODAL}#${v.total}`)
    return group
  },
  getPixData: state => state.pixData,
  isRedirectFinalizarAposta: state => state.isRedirectFinalizarAposta,
  getFullNameEntityGameOpen: state => state.fullNameEntityGameOpen,
}
export default { state, actions, mutations, getters, namespaced: true }
