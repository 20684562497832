import currency from '../filters/currency'
import data from '../filters/dates'
import numeral from '../filters/numeral'

// tratamento de dados visualizados na tela
export const filterMixins = {
  methods: {
    _currency: (val, simbol, decimal) => currency(val || 0, simbol, decimal),
    _date: (val, format = null, convert = 'DD/MM/YYYY') => data(val, format, convert),
    _numeral: (val) => numeral(val),
  }
}
