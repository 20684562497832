// import bingo from './bingo'
// import phaser from './phaser'
import associarJogos from './associarJogos'
import login from './loginCadastro'
import saldos from './saldos'
import listagemProdutos from './listagemProdutos'
import resultados from './resultados'
import carrinho from './carrinho'
import minhasApostas from './minhasApostas'
import cadastroUsuario from './cadastroUsuario'
import extrato from './extrato'
import perfil from './perfil'
import resgate from './resgate'
import creditos from './creditos'
import conferirBilhete from './conferirBilhete'
import repetirAposta from './repetirAposta'
import ajuda from './ajuda'
import revenda from './revenda'
import raspadinha from './raspadinha'
import ultimosGanhadores from './ultimosGanhadores'
import cotacoes from './cotacoes'
import bonus from './bonus'
import promocoes from './promocoes'
import galaxsys from './galaxsys'
import cassinos from './cassinos'
import esportes from './esportes'

export default {
  login,
  // bingo,
  // phaser,
  raspadinha,
  associarJogos,
  saldos,
  listagemProdutos,
  resultados,
  carrinho,
  cadastroUsuario,
  minhasApostas,
  extrato,
  perfil,
  resgate,
  creditos,
  conferirBilhete,
  repetirAposta,
  ajuda,
  revenda,
  ultimosGanhadores,
  cotacoes,
  bonus,
  promocoes,
  galaxsys,
  cassinos,
  esportes
}
