export const notAuthRouters = [
  'auth',
  'conferir.bilhetes',
  'revenda',
  'resultados',
  'promocoes',
  'cadastro.afiliado',
  'cadastro.afiliado.concluido',
  'cadastro',
  'bnp1',
  'bnp2',
  'configure.ios',
  'download',
  'download-rf',
  'videoComoJogar',
  'home',
  'decifrador-de-sonhos',
  'cotacoes',
  'slots',
  'dicas',
  'esportes',
  'apostas',
  'apostaPix',
  'termos.condicoes',
  'politica.privacidade',
  'bilhete.terminal'
]
