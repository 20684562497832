// import modalidadeS from './modalidades/mSENINHA'
export default {
  tableBicho: [],
  videoModalidade: true,
  listMolidades: true,
  isModalidadeCotacaoUnica: true, /* mostra modalidade mas apenas 1 */
  active: true,
  titulo: 'Seninha',
  dadosCotacao: {
    text: 'SENINHA'
  },
  // sub: 'Ou se preferir, confira o passo a passo para realizar a sua aposta.',
  // sub1: 'Para começar a apostar, é muito fácil!',

  /* 1 */
  sub: '',
  /* 2 */
  sub2: 'Como apostar',
  sub21: `
    <div class="mb-2">
      A Seninha concorre com o resultado da Mega Sena da Caixa em todos os seus concursos. Em um único evento são sorteadas 06 dezenas aleatórias entre 01 e 60. O apostador escolhe entre 14 a 40 dezenas. A cotação é de acordo com a quantidade de números jogados e a quantidade de acertos conforme a tabela abaixo:
    </div>

    <div class="text-pontilhados">
      <span> 14 Dezenas - Sena</span>
      <span>  </span>
      <span>  6.000x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 14 Dezenas - Quina </span>
      <span>  </span>
      <span>  85x  </span>
    </div>

    <div class="text-pontilhados  mb-3">
      <span> 14 Dezenas - Quadra </span>
      <span>  </span>
      <span>  5x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 15 Dezenas - Sena</span>
      <span>  </span>
      <span> 5.000x </span>
    </div>

    <div class="text-pontilhados  mb-3">
      <span> 15 Dezenas - Quina </span>
      <span>  </span>
      <span> 40x </span>
    </div>

    <div class="text-pontilhados  mb-3">
      <span> 16 Dezenas - Sena </span>
      <span>  </span>
      <span>  3.700x  </span>
    </div>

    <div class="text-pontilhados  mb-3">
      <span> 17 Dezenas - Sena  </span>
      <span> </span>
      <span> 2.400x </span>
      </div>

    <div class="text-pontilhados  mb-3">
      <span> 18 Dezenas - Sena  </span>
      <span>  </span>
      <span> 1.600x </span>
    </div>

    <div class="text-pontilhados  mb-3">
      <span> 19 Dezenas - Sena  </span>
      <span>  </span>
      <span> 1.100x </span>
    </div>

    <div class="text-pontilhados  mb-3">
      <span> 20 Dezenas - Sena  </span>
      <span>  </span>
      <span> 700x </span>
    </div>

    <div class="text-pontilhados mb-3">
      <span> 25 Dezenas - Sena  </span>
      <span>  </span>
      <span> 170x </span>
    </div>

    <div class="text-pontilhados mb-3">
      <span> 30 Dezenas - Sena  </span>
      <span>  </span>
      <span> 50x </span>
    </div>

    <div class="text-pontilhados mb-3">
      <span> 35 Dezenas - Sena  </span>
      <span>  </span>
      <span> 17x </span>
    </div>

    <div class="text-pontilhados mb-3">
      <span> 40 Dezenas - Sena  </span>
      <span>  </span>
      <span> 7x </span>
    </div>


    <div class="mb-2"> Para jogar na SENINHA, siga os passos abaixo: </div>
      <ul class="lista">
      <li>
        Marque a quantidade de dezenas que você quer jogar entre 17 a 40 números;
      </li>
      <li>
        Comece a marcar os seus números até completar a quantidade que que você escolheu, ou, se preferir, dê um clique no botão laranja para gerar uma aposta aleatória;
      </li>
      <li>
        Repita o processo para apostar em quantos palpites desejar; <br/>
        Se você errar ao escolher um número e quiser substituí-lo, clique sobre ele novamente para desmarcar. Se a aposta já foi inserida, para apagar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites; <br/>
        Ao realizar várias apostas, elas são empilhadas e só as últimas ficam visíveis no painel. As demais vão aparecer no bilhete ao final da aposta.
      </li>

      <li>
        Clique em "Continuar";
      </li>
      <li>
        Informe o valor que deseja apostar; <br/>
        A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: “+ Apostas” e “Finalizar”.
      </li>

      <li>
        Clicando em fazer mais apostas você volta para a tela inicial da Seninha, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
      </li>

      <li>
        Clicando em finalizar você volta para a página principal do BICHO NO PIX, onde poderá apostar em outros produtos.
      </li>
    </ul>
  `,

  // ...modalidadeS
}
