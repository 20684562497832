export default {
  path: '/video-como-jogar',
  name: 'videoComoJogar',
  component: () => import('./index.vue'),
  meta: {
    title: 'Como Jogar Jogo do Bicho: Vídeo Tutorial | BICHO NO PIX',
    description: 'Aprenda a jogar jogo do bicho com nosso vídeo tutorial no BICHO NO PIX e aumente suas chances de ganhar. Assista agora e domine as regras do jogo!',
    ogDescription: 'Aprenda a jogar jogo do bicho com nosso vídeo tutorial no BICHO NO PIX e aumente suas chances de ganhar. Assista agora e domine as regras do jogo!',
    canonical: window.location.href
  }
}
