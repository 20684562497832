import http from '@/core/http'
import session from '@/core/service/session'

const state = {
  tokenIntegration: null
}

const actions = {
  async getTokenIntegration ({ commit }, payload) {
    const result = await http.get('get-token-integration')
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_TOKEN_INTEGRATION', result.data.data)
  }
}

const mutations = {
  'SET_TOKEN_INTEGRATION' (state, payload) {
    session.set('token-integration', payload.tokenIntegration)
    state.tokenIntegration = payload.tokenIntegration
  }
}

const getters = {
  tokenIntegration: state => state.tokenIntegration
}

export default { state, actions, mutations, getters, namespaced: true }
