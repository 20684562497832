<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'border-rounded': borderRound}" :style="`border-color: ${color};`">
    <mask id="mask0_3726_8484" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="23">
      <path d="M22.9996 0.679993H0.194336V23H22.9996V0.679993Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_3726_8484)">
      <path d="M11.1674 2.55682C7.49676 2.55682 6.57387 4.71653 6.63889 5.61952C6.72423 6.3071 7.03734 6.9463 7.5283 7.4352L10.5294 10.1951C10.7051 10.3518 10.9323 10.4383 11.1677 10.4383C11.4031 10.4383 11.6302 10.3518 11.806 10.1951L14.807 7.4352C15.298 6.9463 15.6111 6.3071 15.6964 5.61952C15.7615 4.71653 14.8386 2.55682 11.1674 2.55682Z" :fill="color"/>
      <path d="M11.1679 21.2396C14.8386 21.2396 15.7615 19.059 15.6964 18.1473C15.612 17.4543 15.299 16.8093 14.807 16.3141L11.806 13.5275C11.6309 13.3695 11.4035 13.282 11.1677 13.282C10.9318 13.282 10.7044 13.3695 10.5294 13.5275L7.5283 16.3141C7.03629 16.8093 6.72334 17.4543 6.63889 18.1473C6.57387 19.059 7.49724 21.2396 11.1679 21.2396Z" :fill="color"/>
      <path d="M17.1789 7.36967C16.4428 7.44779 15.7509 7.75946 15.2046 8.25907L12.2258 10.7492C11.9423 11.0306 11.5638 11.1959 11.1647 11.2126C10.7785 11.1994 10.4109 11.0438 10.1326 10.7759L7.13008 8.26198C6.58392 7.76244 5.89224 7.45077 5.15622 7.37258C4.17462 7.30465 1.82617 8.22753 1.82617 11.8982C1.82617 15.5689 4.17462 16.4918 5.15622 16.4267C5.89224 16.3485 6.58392 16.0369 7.13008 15.5373L10.1098 12.9312C10.4004 12.6705 10.7744 12.5218 11.1647 12.512C11.5511 12.5221 11.922 12.6663 12.2137 12.9201L15.2046 15.5373C15.7509 16.0369 16.4428 16.3486 17.1789 16.4267C18.1605 16.4918 20.509 15.5689 20.509 11.8982C20.509 8.22753 18.1605 7.30465 17.1789 7.36967Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ProductCloverSVG',
  props: {
    color: {
      type: String,
      default: '#FFF'
    },
    borderRound: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.border-rounded {
  border: 1px solid;
  border-radius: 30px;
  padding: 1px 1px 2px 2px;
  height: 30px;
  width: 30px;
  margin-right: 7px;
}
</style>
