import http from '@/core/http'
import session from '@/core/service/session'
import { size, orderBy } from 'lodash'
import Cookies from 'js-cookie'

const state = {
  user: {},
  config: null,
  token: false,
  modal: null,
  questions: false,
  configPixFinancial: false,
  configBonusWeb: false,
  dadosWhatsapp: [],
  incompleteRegistration: null,
  banners: [],
}

const headerAuth = (userToken, password) => window.btoa(`${userToken}:${password}`)

const actions = {
  async userLogin ({ commit }, payload) {
    const headers = { headers: { authorization: 'Basic ' + headerAuth(payload.name, payload.password) } }
    const data = { lang: payload.lang || 'pt-br', tipo: 'cpf', recaptcha: payload.recaptcha, otptoken: payload.otptoken }
    const firebaseToken = session.get('firebase-token')
    const messageChannelId = session.get('message-channel-id')

    if (firebaseToken) data.firebaseToken = firebaseToken
    if (messageChannelId) data.messageChannelId = messageChannelId

    const result = await http.post('autenticar', data, headers)
    if (!result.data.success) throw new Error(result.data.message)
    commit('LOGIN_SUCCESS', result.data.data)
    return Promise.resolve(result.data.data)
  },
  validacaoCpfLogin: async ({ commit }, payload) => {
    const result = await http.get('validacao-cpf-login', payload)
    if (!result.data.success) throw new Error(result.data.message)
    commit('SET_DADOS_CPF', result.data.data)
    return Promise.resolve(result.data.data)
  },
  async getDadosUsuario ({ commit }) {
    const result = await http.post('regiao')
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_CONFIG', result.data.data)
    return Promise.resolve(result.data)
  },

  async recuperarSenha (_, payload) {
    const result = await http.post('recuperar', payload)
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },
  async alterarSenha (_, payload) {
    const result = await http.post('senha/reset-password', payload)
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },

  async alterarSenhaOtp (_, payload) {
    const result = await http.post('senha/reset-password-otp', payload)
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },

  async addPositions (_, payload) {
    if (payload && payload.latitude) await http.post('add-positions', payload)
  },

  async openModal ({ commit }, payload) {
    commit('OPEN_MODAL', payload)
    return Promise.resolve(true)
  },

  async logout () {
    window.location = '/'
    session.remove('auth-token')
    Cookies.remove('popupBalanceLow')
    state.token = false
    state.config = null
    state.user = {}
  },

  async suporteWhatsapp ({ state }, payload) {
    const result = await http.get('suporte-watsapp', payload)
    if (!result.data.success) throw Error(result.data.message)
    state.dadosWhatsapp = result.data.data
    return Promise.resolve(result.data)
  },

  async getTagByAfilliate ({ state }, payload) {
    const result = await http.post('campanha-pixel-afiliate', payload)
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },

  async refreshToken ({ commit }) {
    const result = await http.get('refresh-token-web')
    commit('LOGIN_SUCCESS_REFRESH', result.data.data)
    return Promise.resolve(result)
  },

  async checkJWT () {
    const result = await http.get('check-jwt')
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result)
  },

  startIntervalCheckJWT: ({ commit }) => {
    commit('ADD_INTERVAL_JWT')
  },

  async getBanners ({ commit }) {
    const result = await http.post('lista-banners')
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_BANNERS', result.data)
    return Promise.resolve(result.data)
  },
}

const mutations = {
  'OPEN_MODAL' (state, payload) {
    state.modal = payload
  },
  'LOGIN_SUCCESS' (state, payload) {
    session.set('auth-token', payload.token)
    state.token = true
  },
  'LOGIN_SUCCESS_REFRESH' (state, payload) {
    session.set('auth-token', payload.token)
    state.token = true
  },

  'ADD_INTERVAL_JWT' (state, payload) {
    state.intervalCheckJWT = setInterval(() => {
      console.log('teste')
      http.get('/check-jwt')
    }, 1000 * 60 * 5)
  },
  'SET_CONFIG' (state, payload) {
    state.config = payload.data
    state.user = { ...payload.user, id: payload.id }
    state.token = true
    state.configPixFinancial = payload.configPixFinancial
    state.configBonusWeb = payload.configBonusWeb
    // resposta de questionario ativadas e usuario ainda nao respondeu formulario
    const isRespondeu = payload.user.jsonFlags.answeredQuestion === undefined
    state.questions = payload.globalQuestions === true && isRespondeu
    state.user.revenda = state.user.jsonFlags && state.user.jsonFlags.revenda && state.user.jsonFlags.revenda.text ? state.user.jsonFlags.revenda.text : ''
    state.user.phoneRevenda = state.user.jsonFlags && state.user.jsonFlags.revenda && state.user.jsonFlags.revenda.phone ? state.user.jsonFlags.revenda.phone : ''
    state.user.limitPremioViaPix = state.user.jsonFlags && state.user.jsonFlags.limitPremioViaPix ? state.user.jsonFlags.limitPremioViaPix : 700
  },
  'SET_DADOS_CPF' (state, payload) {
    state.incompleteRegistration = payload.incompleteRegistration
  },
  'SET_BANNERS' (state, payload) {
    state.banners = orderBy(payload.data, ['jsonData.nrOrder'], ['asc']).map(it => ({ url: it.url, targetLink: it.jsonData.targetLink }))
  }
}

const getters = {
  globalConfigProdutos: (state) => size(state.config) ? state.config.CONFIG : null,
  getToken: () => state.token,
  isActiveQuestions: () => state.questions,
  getUser: (state) => state.user,
  loginOpenModal: (state) => state.modal,
  auth: (state, getters) => {
    const user = state.user
    if (user.id && state.token && !user.jsonFlags.resetPassword) return true
    return false
  },
  isResetPassword: (state, getters) => {
    const user = state.user
    if (user.id && state.token && user.jsonFlags.resetPassword) return true
    return false
  },
  isResgatePremiosPix: (state) => state.user.jsonFlags && state.user.jsonFlags.pixResgatePremiosVisible === true,
  urlRepositorioAWS: (state) => {
    const { CONFIG: { URL_REPSITORIO_AWS } } = state.config
    if (URL_REPSITORIO_AWS) return URL_REPSITORIO_AWS
    return ''
  },

  telefoneSuporte: (state) => state.dadosWhatsapp || '5562998386563',

  configPixFinancial: (state) => state.configPixFinancial,
  configBonusWeb: (state) => state.configBonusWeb,
  listBanners: (state) => state.banners,
}

export default { state, actions, mutations, getters, namespaced: true }
