<template>
  <!-- eslint-disable -->
   <transition name="menu-animation">
    <div v-if="menu" id="menu" :class="[!menu ? 'offcanvas': 'offcanvas offcanvas-start show']" :style="{visibility: menu ? 'visible !important' : 'none !important'}" tabindex="0" data-bs-backdrop="false">
      <div class="offcanvas-header flex-row px-4">
        <div class="d-flex align-items-center justify-content-between">
          <img :src="require('@/assets/logo.png')" alt="menu Bicho no pix" class="img-fluid img-logo  w-50" @click="$router.push({ name: 'home'})" />
          <!-- <button type="button" @click="closeMenu" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
        </div>

        <!-- <div class=" w-100 d-flex justify-content-start gap-2 align-items-center ms-2 mt-2" v-if="isAuth">
          <div class="d-flex flex-column justify-content-start align-items-center h-100">
            <div class="circle">
              <img :src="require('@/assets/icons/person.svg')" class="header-icon-person" />
            </div>
          </div>
          <div class="d-flex flex-column align-items-start justify-content-start gap-1">
            <div class="d-flex flex-column justify-content-start align-items-end overflow-hidden">
              <div class="w-100 d-flex justify-content-start gap-2 align-items-center ms-2">
                <img :src="require('@/assets/icons/saldo_creditos.svg')" class="currency-img" />
                <span class="currency">{{ _currency(listSaldos.saldo) }}</span>
              </div>
              <span class="label-currency-field text-end">Saldo créditos</span>
            </div>
            <div class="currency-separator"></div>
            <div class="d-flex flex-column justify-content-start align-items-end overflow-hidden">
              <div class="w-100 d-flex justify-content-start gap-2 align-items-center ms-2">
                <img :src="require('@/assets/icons/saldo_creditos.svg')" class="currency-img" />
                <span class="currency">{{ _currency(listSaldos.bonus) }}</span>
              </div>
              <span class="label-currency-field text-end">Saldo bonus</span>
            </div>
          </div>
        </div> -->
      </div>
      <div class="offcanvas-body px-4 menu">

        <!-- Card d saldos -->
        <!-- <cardSaldos v-if="isAuth" /> -->

          <router-link :to="{ name: 'creditos'}" class="d-flex align-items-center justify-content-center menu-credito" v-if="isAuth && isPix">
            <img src="@/assets/icons/cash-stack.svg" class="img-fluid img-cash"/>
            <span class="text-depositar">Depositar</span>
          </router-link>

          <!-- <router-link :to="{ name: 'resgate'}" class="d-flex align-items-center justify-content-center menu-credito premio mt-1" v-if="isAuth">
            Receber Prêmios
          </router-link>
          <span class="d-flex align-items-center justify-content-center menu-credito premio mt-1">  </span> -->

        <span class="version">{{ vversion }}</span>
        <!-- <hr class="divider" v-if="isAuth"> -->

        <ul class="nav flex-column align-items-start mb-4" id="menu">

          <li class="w-100">
            <router-link :to="{ name: 'slots' }" class="d-block result">
              <img src="@/assets/icons/jogos-instantaneos.svg" class="img-fluid img-menu" alt="slot"/>
              <span>Jogos instantâneos</span>
            </router-link>
          </li>

          <hr>

          <li class="w-100">
            <div @click="selectProduct('BT')" class="d-block result">
              <img src="@/assets/icons/jb.svg" class="img-fluid img-menu" alt="resultados"/>
              <span>Jogo do Bicho</span>
            </div>
          </li>

          <hr>

          <li class="w-100">
            <router-link :to="{ name: 'esportes' }" class="d-block result">
              <img src="@/assets/icons/sports_soccer.svg" class="img-fluid img-menu" alt="Esportes"/>
              <span>Esportes</span>
            </router-link>
          </li>

          <hr>

          <li class="w-100 menu-itens">
            <li @click="toggleApostar">
              <img :src="require('@/assets/icons/ic_apostar_nav.svg')" alt="apostar" class="img-fluid img-menu" />
              <span class="apostar-link">Loterias</span>
              <!-- <img :src="require('@/assets/icons/arrow_down.svg')" alt="super25" class="img-fluid img-arrow" /> -->
              <i v-if="showApostarSubmenu" class="bi bi-chevron-up"></i>
              <i v-else class="bi bi-chevron-down"></i>
            </li>
            <div class="menu-item-img"></div>
            <ul v-if="showApostarSubmenu" class="lista-produtos">
              <li v-for="produto in produtos" :key="produto" class="img-produtos" @click="selectProduct(produto.id)">
                <span>
                  <ProductCloverSVG color="#7c7c89"/>
                  <span class="ms-1 p-0"> {{ produto.config.DS_PROD }} </span>
                  <!-- <img :src="require(`@/assets/produtos/svg/${produto.config.ID_PROD}.svg`)" alt="produtos" class="img-fluid" /> -->
                </span>
              </li>
            </ul>
          </li>

          <hr>

          <li class="w-100">
            <router-link :to="{ name: 'decifrador-de-sonhos' }" class="d-block result">
              <img src="@/assets/icons/decifrador.svg" class="img-fluid img-menu" alt="Decifrador de Sonhos"/>
              <span>Decifrador de Sonhos</span>
              <span class="badged-novo"> novo </span>
            </router-link>
          </li>

          <hr>

          <li class="w-100">
            <router-link :to="{ path: '/resultados/jogo-do-bicho/hoje' }" class="d-block result">
              <img src="@/assets/icons/resultados-bnp_nav.svg" class="img-fluid img-menu" alt="resultados"/>
              <span>Resultados</span>
            </router-link>
          </li>

          <hr>

          <li class="w-100">
            <router-link class="d-block result" :to="{ name: 'promocoes' }">
              <img src="@/assets/icons/ic-promocoes.svg" class="img-fluid img-menu" alt="promoções"/>
              <span>Promoções</span>
            </router-link>
          </li>

          <hr>

          <li class="w-100">
            <!-- <router-link :to="{ name: '' }" class="d-block result" @click="openPromocoes">
              <img src="@/assets/icons/ic-promocoes.svg" class="img-fluid img-menu" alt="promoções"/>
              <span>Promoções</span>
            </router-link> -->
            <router-link :to="{ name: 'cotacoes' }" class="d-block result">
              <img src="@/assets/icons/cotacao_nav.svg" class="img-fluid img-menu" alt="promoções"/>
              <span> Cotacões </span>
            </router-link>
          </li>

          <hr v-if="isAuth">

          <li class="w-100 menu-itens">
            <li @click="toggleMeuPerfil" v-if="isAuth">
              <img :src="require('@/assets/icons/meu_perfil.svg')" alt="apostar" class="img-fluid img-menu" />
              <span class="meuperfil-link">Minha Conta</span>
              <!-- <img :src="require('@/assets/icons/arrow_down.svg')" alt="super25" class="img-fluid img-arrow" /> -->
              <i v-if="showMeuPerfilSubmenu" class="bi bi-chevron-up"></i>
              <i v-else class="bi bi-chevron-down"></i>
            </li>

            <div class="menu-item-img"></div>
            <ul v-if="showMeuPerfilSubmenu && isAuth">
              <!-- <li class="img-produtos">
                <router-link :to="{ name: 'extrato' }">
                  <img :src="require(`@/assets/icons/extrato.svg`)" alt="Extratos" class="img-fluid img-menu"/>
                  <span>Extrato</span>
                </router-link>
              </li> -->
              <li class="img-produtos">
                <router-link :to="{ name: 'perfil' }">
                  <img :src="require(`@/assets/icons/meu_perfil_sub.svg`)" alt="Meu Perfil" class="img-fluid img-menu"/>
                  <span>Meu Perfil</span>
                </router-link>
              </li>
              <li class="img-produtos">
                <router-link :to="{ name: 'minhasApostas' }">
                  <img :src="require(`@/assets/icons/minhas_apostas_nav.svg`)" alt="Minhas Apostas" class="img-fluid img-menu"/>
                  <span>Minhas apostas</span>
                </router-link>
              </li>
              <li class="img-produtos">
                <router-link :to="{ name: 'resgate' }">
                  <img :src="require(`@/assets/icons/saques.svg`)" alt="Saques" class="img-fluid img-menu"/>
                  <span>Saques</span>
                </router-link>
              </li>
              <li class="img-produtos">
                <router-link :to="{ name: 'bonus' }">
                  <img :src="require(`@/assets/icons/gift.svg`)" alt="Bônus" class="img-fluid img-menu"/>
                  <span>Bônus</span>
                </router-link>
              </li>
              <li class="img-produtos" @click.prevent="logout" href="javascript:void;">
                <!-- <router-link :to="{ name: 'resgate' }"> -->
                  <img :src="require(`@/assets/icons/sair.svg`)" alt="Sair" class="img-fluid img-menu"/>
                  <span>Sair</span>
                <!-- </router-link> -->
              </li>

              <!-- <li class="img-produtos" @click="cadastrarAfiliado()">
                <router-link :to="{ name: 'resgate' }">
                  <img :src="require(`@/assets/icons/saques.svg`)" alt="Saques" class="img-fluid img-menu"/>
                  <span>Seja um afiliado</span>
                </router-link>
              </li> -->
            </ul>
          </li>

          <!-- <hr v-if="isAuth"> -->

          <!-- <li class="w-100" @click.prevent="logout" href="javascript:void;" v-if="isAuth">
              <img src="@/assets/icons/sair.svg" class="img-fluid img-menu" alt="Sair"/>
              <span>Sair</span>
          </li> -->

          <hr>

          <li class="w-100">
            <router-link :to="{ name: 'videoComoJogar' }" class="d-block result">
              <img src="@/assets/icons/como-jogar-bnp.svg" class="img-fluid img-menu" alt="como jogar"/>
              <span>Como Jogar?</span>
            </router-link>
          </li>

        <!--  isAuth login e cadastrar -->
          <!-- <li class="w-100" @click.prevent="openModal('open::modal::login')" v-if="!isAuth">
              <img src="@/assets/icons/logar-nav.svg" class="img-fluid img-menu" alt="Login"/>
              <span>Entrar</span>
          </li>

          <hr v-if="!isAuth">

          <li class="w-100" @click.prevent="openModal('open::modal::cadastro')" v-if="!isAuth">
              <img src="@/assets/icons/person-plus.svg" class="img-fluid img-menu" alt="Cadastrar"/>
              <span>Cadastre-se</span>
          </li> -->
        </ul>

        <!-- <hr v-if="!isAuth"> -->

      </div>
      <!-- <p> {{ _date('now', null, 'YYYY') }} © Bicho no pix. Todos os direitos reservados. </p> -->
    </div>
  </transition>
</template>
<!-- eslint-enable -->
<script>
import { computed, watch, getCurrentInstance, ref, onMounted, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { filterMixins } from '@/core/mixins'
import { useStore } from 'vuex'
import { size } from 'lodash'
// eslint-disable-next-line no-unused-vars
import session from '@/core/service/session'
import { _alert } from '@/core/service/utils'
import ProductCloverSVG from '@/views/minhasApostas/components/ProductCloverSVG.vue'

export default {
  name: 'menu-nav',
  mixins: [filterMixins],
  components: {
    ProductCloverSVG,
    // cardSaldos: defineAsyncComponent(() => import('./cardSaldos.vue'))
  },
  setup () {
    const app = getCurrentInstance()
    const { Events, $loading } = app.appContext.config.globalProperties

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const menu = computed(() => store.state.menu)
    const isAuth = computed(() => store.getters['login/auth'])
    const configBonusWeb = computed(() => store.getters['login/configBonusWeb'])
    const produtos = computed(() => store.getters['listagemProdutos/listProdutos'].filter(it => it.id !== 'BT'))
    const vversion = computed(() => store.getters['_getVersion'])

    const showRaspadinha = computed(() => {
      const list = store.getters['listagemProdutos/listProdutosResultado']
      return size(list) ? list.filter(v => v.id === 'RASPADINHA')[0] : false
    })

    const listSaldos = computed(() => store.getters['saldos/listSaldos'])

    const showApostarSubmenu = ref(true)

    const showMeuPerfilSubmenu = ref(true)

    const selectProduct = async (productId) => {
      if (!isAuth.value) return Events.emit('open::modal::login::produto::selecionado', productId)

      const prod = await store.dispatch('listagemProdutos/setSelectProdutoAtual', { productId, routeName: route.name })
      if (!prod) {
        return
      }

      const load = $loading.show()

      Events.emit('clear::all::aposta')
      Events.emit('clear::loterias::change::date')
      Events.emit('clear::modalidade')

      store.dispatch('listagemProdutos/getModalitiesCategories', { id: productId })
        .then(() => {
          store.dispatch('listagemProdutos/getLoteriasProdutos', productId).then(() => {
            router.push({ name: 'apostas' }).then(() => {
              load.hide()
              store.dispatch('setTabActive', 1)
            })
          })
        }).catch(e => {
          load.hide()
          if (e === 'Token não fornecido') {
            Events.emit('open::modal::login', true)
          } else {
            _alert(e, 'error')
          }
        }).finally(() => store.dispatch('setMenu', false))
    }

    // permite visualizar pix
    const isPix = computed(() => {
      const dados = store.getters['login/getUser']
      return dados.jsonFlags && dados.jsonFlags.pixVisible === true ? 1 : 0
    })

    const toggleApostar = () => {
      showApostarSubmenu.value = !showApostarSubmenu.value
    }

    const toggleMeuPerfil = () => {
      showMeuPerfilSubmenu.value = !showMeuPerfilSubmenu.value
    }

    const cadastrarAfiliado = async () => {
      // await store.dispatch('perfil/cadastrarAfiliado')
      Events.emit('open::modal::afiliado', true)
    }

    // Detecta click outside para fechar menu
    const closeMenuOnOutsideClick = (event) => {
      const menuElement = document.getElementById('menu')
      if (menuElement && !menuElement.contains(event.target)) {
        closeMenu()
      }
    }

    onMounted(() => {
      document.addEventListener('click', closeMenuOnOutsideClick)
    })

    onUnmounted(() => {
      document.removeEventListener('click', closeMenuOnOutsideClick)
    })

    const openModal = (name) => Events.emit(name, true)

    const isResetPassword = computed(() => store.getters['login/isResetPassword'])
    const closeMenu = () => {
      store.dispatch('setMenu', false)
    }

    const logout = () => {
      store.dispatch('login/logout')
    }

    const openModalJogoResponsavel = () => {
      Events.emit('modal::produtos::jogoResponsavel', true)
    }

    watch(() => route.path, async () => {
      closeMenu()
    })
    return {
      closeMenuOnOutsideClick,
      closeMenu,
      menu,
      logout,
      isAuth,
      isResetPassword,
      isPix,
      configBonusWeb,
      showRaspadinha,
      openModalJogoResponsavel,
      toggleApostar,
      toggleMeuPerfil,
      showApostarSubmenu,
      showMeuPerfilSubmenu,
      produtos,
      listSaldos,
      selectProduct,
      router,
      openModal,
      vversion,
      cadastrarAfiliado
    }
  }
}

</script>
<style lang="scss" scoped>
@import '@/styles/variables';

@keyframes inOut {
  0% {
    left: -400px;
    width: 0px;
  }
  100% {
    left: 0;
  }
}

i.bi.bi-chevron-up,
i.bi.bi-chevron-down {
  pointer-events: none;
  cursor: default;
}

.perfil-position {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px;
}

.currency-separator{
  width: 100%;
  border: 1px solid #838494;
}

.label-currency-field {
  color: #838494;
  font-size: 10px;
  letter-spacing: 0px;
  padding-left: 0px;
}

.circle {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.currency-img {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  // margin-left: 10px; /* Ajuste a margem à direita do circle conforme necessário */
}

.currency {
  font-size: 16px;
  font-family: 'Roboto' !important;
  font-weight: bold;
  color: white;
}

i.bi.bi-chevron-up {
  color: white !important;
}

i.bi.bi-chevron-down {
  color: white !important;
}
.header-icon-person {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}

.img-cash {
  position: relative;
  right: 60px;
  height: 24px;
}

.text-depositar {
  right: 50px;
  position: relative;
  font-size: 15px;
  font-family:'Roboto' !important;
}

hr {
  /* margin-top: 25px; */
  color: #525569 !important;
  background-color: #525569;
  opacity: 1 !important;
}

.nav {

  display: list-item;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  span {
    font-family: 'Roboto' !important;
    font-size: 16px;
    text-align: left;
    color: #FFFFFF;
    padding-left: 20px;
    font-weight: 550;
    cursor: pointer;
  }

  .img-produtos {
    padding-left: 15px;
    cursor: pointer;
  }
  .img-menu {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.bi-chevron-up::before  {
  float: right;
  font-weight: bold !important;
  font-variant: all-small-caps !important;
  width: 7px;
  height: 4px;
  line-height: unset !important;
  cursor: pointer;
}

.bi-chevron-down::before  {
  float: right;
  font-weight: bold !important;
  font-variant: all-small-caps !important;
  width: 7px;
  height: 4px;
  line-height: unset !important;
  cursor: pointer;
}

li {
  margin-top: 15px;
}

.menu-animation-enter-active{
  animation: inOut 0.5s linear;
}
.menu-animation-leave-to,
.menu-animation-leave-active{
  animation: inOut 0.5s linear reverse;
}

/* Efeito do menu */
.offcanvas {
  background: none;
  position: fixed;
  z-index: 9999999;
  width: 330px;
  /* Antiga animacao menu movendo body todo
    position: relative;
    border-right: transparent;
    z-index: 1;
  */
}

.divider {
  margin-top: 30px;
}

.btn-close {
  background-color: white;
}

.offcanvas-header {
  flex-direction: column;
  background: $bg-index;
  align-items: center;
  border-bottom: 1px solid rgb(0, 0, 0);
  background: #090a14;
  max-height: 185px;
  justify-content: flex-start;
}

.offcanvas-body {
  background: #090A14;
}

.menu {
  background: #090a14;
  // transition: all linear 0.5s;
  &-credito {
    position: relative;
    top: 11px;
    cursor: pointer;
    height: 45px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    transition: 1s;
    background-color:#20b947 !important;
    color: white !important;
    font-family: 'Roboto' !important;
    border: 1px solid $green;
    &:hover {
      color: white !important;
      background: $green;
    }
    &.premio {
      background: white;
      color: $roxo !important;
      box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2);
      transition: 1s;
      border: 1px solid $roxo;
      &:hover {
        background: $roxo;
        border: transparent;
        color: white !important;
      }
    }
  }

  a {
    font-style: none;
  }
  /* a {
    color: $second;
    font-size: 14px;
    text-transform: uppercase;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    &.result {
      font-weight: bold;
      color: $primary;
    }
  } */
}

p {
  color: #b0bec5;
  font-size: 8px;
  text-align: center;
}

.version {
  position: absolute;
  bottom: 0;
  left: 20px;
  font-size: 12px;
  font-family: monospace !important;
}
.lista-produtos {
  svg {
    width: 21px !important;
    height: 21px !important;
  }
}

.badged-novo {
  background: #D91E1E;
  padding: 1px 4px 2px 2px !important;
  margin-left: 8px;
  border-radius: 5px;
}
</style>
