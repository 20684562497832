// import modalidadeS from './modalidades/mSENINHA'
export default {
  tableBicho: [],
  videoModalidade: true,
  listMolidades: true,
  isModalidadeCotacaoUnica: true, /* mostra modalidade mas apenas 1 */
  active: true,
  titulo: 'Keno da Look',
  dadosCotacao: {
    text: 'KENODALOOK'
  },
  // sub: 'Ou se preferir, confira o passo a passo para realizar a sua aposta.',
  // sub1: 'Para começar a apostar, é muito fácil!',

  /* 1 */
  sub: '',
  /* 2 */
  sub2: 'Como apostar',
  sub21: `
    <div class="mb-2">
      A cotação é de acordo com a quantidade de números jogados e a quantidade de acertos conforme a tabela abaixo:
    </div>

    <div class="text-pontilhados">
      <span> 13 dezenas - Quina </span>
      <span>  </span>
      <span>  5.000x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 13 dezenas - Quadra </span>
      <span>  </span>
      <span>  85x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 13 dezenas - Terno </span>
      <span>  </span>
      <span>  6x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 14 dezenas - Quina </span>
      <span>  </span>
      <span>  4.000x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 14 dezenas - Quadra </span>
      <span>  </span>
      <span>  40x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 14 dezenas - Terno </span>
      <span>  </span>
      <span>  5x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 15 dezenas - Quina </span>
      <span>  </span>
      <span>  3.000x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 15 dezenas - Quadra </span>
      <span>  </span>
      <span>  60x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 16 dezenas - Quina </span>
      <span>  </span>
      <span>  2.500x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 16 dezenas - Quadra </span>
      <span>  </span>
      <span>  30x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 17 dezenas - Quina </span>
      <span>  </span>
      <span>  1.800x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 17 dezenas - Quadra </span>
      <span>  </span>
      <span>  20x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 18 dezenas - Quina </span>
      <span>  </span>
      <span>  1.200x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 18 dezenas - Quadra </span>
      <span>  </span>
      <span>  20x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 19 dezenas - Quina </span>
      <span>  </span>
      <span>  1.000x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 19 dezenas - Quadra </span>
      <span>  </span>
      <span>  10x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 20 dezenas - Quina </span>
      <span>  </span>
      <span>  900x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 25 dezenas - Quina </span>
      <span>  </span>
      <span>  270x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 30 dezenas - Quina </span>
      <span>  </span>
      <span>  100x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 35 dezenas - Quina </span>
      <span>  </span>
      <span>  40x  </span>
    </div>

    <div class="text-pontilhados">
      <span> 40 dezenas - Quina </span>
      <span>  </span>
      <span>  22x  </span>
    </div>

    <div class="mb-2"> Para jogar na KENO DA LOOK, siga os passos abaixo: </div>
      <ul class="lista">
      <li>
        Marque a quantidade de dezenas que você quer jogar entre 13 a 40 números;
      </li>
      <li>
        Comece a marcar os seus números até completar a quantidade que que você escolheu, ou, se preferir, dê um clique no botão laranja para gerar uma aposta aleatória;
      </li>
      <li>
        Repita o processo para apostar em quantos palpites desejar; <br/>
        Se você errar ao escolher um número e quiser substituí-lo, clique sobre ele novamente para desmarcar. Se a aposta já foi inserida, para apagar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites; <br/>
        Ao realizar várias apostas, elas são empilhadas e só as últimas ficam visíveis no painel. As demais vão aparecer no bilhete ao final da aposta.
      </li>

      <li>
        Clique em "Continuar";
      </li>
      <li>
        Informe o valor que deseja apostar; <br/>
        A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: “+ Apostas” e “Finalizar”.
      </li>

      <li>
        Clicando em fazer mais apostas você volta para a tela inicial da Seninha, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo.
      </li>

      <li>
        Clicando em finalizar você volta para a página principal do BICHO NO PIX, onde poderá apostar em outros produtos.
      </li>
    </ul>
  `,

  // ...modalidadeS
}
