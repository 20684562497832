import http from '@/core/http'
import { filter, groupBy, map, orderBy, size, maxBy } from 'lodash'
import _numeral from '@/core/filters/numeral'

const state = {
  produtos: null,
  total: 0,
  select: null,
  loterias: null,
  modalidades: null,
  permutacoes: [],
  proximaLoteria: null
}

const formatPremioModalidade = (v, { IN_PREMIO_SAO_DEZENAS }) => {
  if (IN_PREMIO_SAO_DEZENAS) {
    return { text: v, id: v }
  }

  let result = ''
  const text = String(v).split('')
  if (size(text) !== 1) result = map(text, it => `${it}º`).join('-')
  if (size(text) === 1) result = `${text[0]}º`
  return { text: result, id: v, value: 0 }
}

const formatPremio = (modalidade) => {
  const dados = modalidade
  if (size(dados) && dados.ID_MODAL === undefined) {
    const itens = modalidade.CD_PREM.map(v => formatPremioModalidade(v, dados))
    return itens
  }

  // se dezenas for premio formata pelo CD_PREM
  if (dados.IN_PREMIO_SAO_DEZENAS) {
    const dezenas = map(dados.CD_PREM, it => ({ text: it, id: it }))
    return dezenas
  }
  const itens = map(dados.CD_PREM, v => formatPremioModalidade(v, dados))
  return itens
}

const actions = {
  async getProdutos ({ commit }) {
    const result = await http.post('lista-produtos')
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_LIST_PRODUTOS', result.data)
    return Promise.resolve(result.data)
  },

  async setSelectProdutoAtual ({ commit, state }, payload) {
    let { routeName, productId } = payload
    if (routeName && productId) {
      if (state.select === productId && routeName !== 'resultados') {
        return Promise.resolve(false)
      }
    } else {
      if (state.select === payload) {
        return Promise.resolve(false)
      }
    }
    if (productId) {
      productId = productId === 'QUINZAO' ? '15ZAO' : productId
    } else {
      payload = payload === 'QUINZAO' ? '15ZAO' : payload
    }

    commit('SET_PRODUTO_ATUAL', productId || payload)
    return Promise.resolve(payload)
  },

  async cleanSelectProdutoAtual ({ commit }, payload) {
    commit('CLEAN_PRODUTO_ATUAL')
    return Promise.resolve(true)
  },

  async getLoterias ({ commit }, payload) {
    const result = await http.get('lista-loterias')
    if (!result.data.success) throw (result.data.message)

    commit('SET_LOTERIAS_ATUAL', result.data.data)
    return Promise.resolve(payload)
  },

  async proximoResultadoLoteria ({ commit }, payload) {
    const result = await http.get('proximo-resultado-loteria', { params: payload })
    if (!result.data.success) throw (result.data.message)

    commit('SET_PROXIMA_LOTERIA', result.data.data)
    return Promise.resolve(payload)
  },

  async getLoteriasProdutos ({ commit }, payload) {
    const result = await http.get('lista-produto-loterias', { params: { id: payload === 'QUINZAO' ? '15ZAO' : payload } })
    if (!result.data.success) throw (result.data.message)

    commit('SET_LOTERIAS_ATUAL', result.data.data)
    return Promise.resolve(payload)
  },

  async getModalitiesCategories ({ commit }, payload) {
    const result = await http.get('lista-produto-modalidades', { params: { id: payload.id === 'QUINZAO' ? '15ZAO' : payload.id } })
    if (!result.data.success) throw (result.data.message)

    commit('SET_MODALIDADES_ATUAL', result.data.data)
    return Promise.resolve(result.data.data)
  },

  async obterPermutacao ({ commit }, payload) {
    const result = await http.get('generate-permutations', { params: payload })
    if (!result.data.success) throw (result.data.message)
    commit('SET_PERMUTACOES', result.data.data)
    return Promise.resolve(result.data.data)
  },

  async limparPermutacao ({ commit }, payload) {
    commit('LIMPAR_PERMUTACOES')
  },

  limparLoterias ({ commit }) {
    commit('LIMPAR_LOTERIAS_ATUAL')
  },

  validateSelectedProduct ({ getters, state }, payload) {
    payload = payload === '15ZAO' ? 'QUINZAO' : state.select
    return Promise.resolve(payload === getters.getSelect)
  }

}

const mutations = {
  'SET_LIST_PRODUTOS' (state, payload) {
    state.produtos = payload.data
    state.total = payload.total
  },
  'SET_PRODUTO_ATUAL' (state, payload) {
    state.select = payload
  },
  'CLEAN_PRODUTO_ATUAL' (state) {
    state.select = ''
  },
  'SET_LOTERIAS_ATUAL' (state, payload) {
    state.loterias = payload
  },
  'LIMPAR_LOTERIAS_ATUAL' (state) {
    state.loterias = null
  },
  'SET_MODALIDADES_ATUAL' (state, payload) {
    state.modalidades = payload
  },
  'SET_PERMUTACOES' (state, payload) {
    state.permutacoes = payload && payload.map(it => it.guessespermutations)
  },
  'LIMPAR_PERMUTACOES' (state) {
    state.permutacoes = []
  },
  'SET_PROXIMA_LOTERIA' (state, payload) {
    state.proximaLoteria = payload
  }
}

const getters = {
  listProdutos: (state) => orderBy(state.produtos, 'config.NR_ORD_WEB', 'asc').filter(it => it.config.IN_JOGAVEL === undefined || it.config.IN_JOGAVEL),
  listProdutosResultado: (state) => orderBy(state.produtos, 'config.NR_ORD_WEB', 'asc').filter(it => it.config.IN_JOGAVEL === undefined || !it.config.IN_JOGAVEL),
  getSelect: (state) => state.select === '15ZAO' ? 'QUINZAO' : state.select,
  produtoSelecionado: (state) => {
    if (!state.select) return { config: {} }
    return filter(state.produtos, v => v.id === state.select)[0] || false
  },
  getProdActive: (state) => state.select,
  listLoterias: (state) => state.loterias, // para resultado
  getModalidades: (state) => state.modalidades, // modalidades de um produto

  // Agrupa pelo numero da coluna que esta configurado no json de cada modalidade
  modalidadesAgrupadas: (state) => {
    const modalidades = state.modalidades
    if (!size(modalidades)) return false

    const formatLabel = map(orderBy(modalidades, ['NR_ORD_NO_PROD'], ['asc']), v => { // formata label das modalidades
      if (!v) return []
      const label = String(v.DS_MODAL).split(' ')
      const labelFormat = map(label, (v, k) => k !== 1 ? String(v[0]).toUpperCase() : v[0]).join('')
      v.LABEL = labelFormat
      if (labelFormat === 'MeC') v.LABEL = 'MC'
      if (labelFormat === 'MeCI') v.LABEL = 'MeC'
      if (labelFormat === 'DdD') v.LABEL = 'DD'
      if (labelFormat === 'DDF') v.LABEL = 'DF'
      if (labelFormat === 'TdD') v.LABEL = 'TD'
      if (labelFormat === 'TDC') v.LABEL = 'TC'
      if (labelFormat === 'TDC') v.LABEL = 'TC'
      if (labelFormat === 'DdG') v.LABEL = 'D'
      if (labelFormat === 'TdG') v.LABEL = 'TG'
      if (labelFormat === 'TGC') v.LABEL = 'TC'
      if (labelFormat === 'QdG') v.LABEL = 'Q'
      if (labelFormat === 'S5A7') v.LABEL = '7'
      if (labelFormat === 'S5') v.LABEL = '5'
      if (labelFormat === 'S5A6') v.LABEL = '6'
      if (labelFormat === 'S5A8') v.LABEL = '8'

      if (v.DS_MODAL.indexOf('-') === -1) {
        let cotacoes
        if (size(v.AR_COTACAO)) {
          cotacoes = map(v.AR_COTACAO, (item) => ({ ...item, VL_PREMIO: parseFloat(item.VL_PREMIO) }))
        }
        const cotacao = size(cotacoes) ? maxBy(cotacoes, 'VL_PREMIO') : { VL_PREMIO: 0 }
        v.DS_MODAL = cotacao && cotacao.VL_PREMIO ? `${v.DS_MODAL}` : v.DS_MODAL
        v.COTACAO = cotacao.VL_PREMIO
        v.DS_COTACAO = _numeral(cotacao.VL_PREMIO) + 'X'
      }
      const prizesFormat = formatPremio(v)
      return {
        ...v,
        id: v.ID_MODAL,
        value: v,
        sig: v.DS_SIG,
        label: v.DS_MODAL,
        modalityPrizeFormat: prizesFormat
      }
    })

    return groupBy(formatLabel, v => v.COL_GROUP)
  },
  modalidadesInLine: (state) => {
    const modalidades = state.modalidades
    if (!size(modalidades)) return false
    const formatLabel = map(orderBy(modalidades, ['NR_ORD_NO_PROD'], ['asc']), v => { // formata label das modalidades
      if (!v) return []

      const label = String(v.DS_MODAL).split(' ')
      const labelFormat = map(label, (v, k) => k !== 1 ? String(v[0]).toUpperCase() : v[0]).join('')
      v.LABEL = labelFormat
      if (labelFormat === 'MeC') v.LABEL = 'MC'
      if (labelFormat === 'MeCI') v.LABEL = 'MeC'
      if (labelFormat === 'DdD') v.LABEL = 'DD'
      if (labelFormat === 'DDF') v.LABEL = 'DF'
      if (labelFormat === 'TdD') v.LABEL = 'TD'
      if (labelFormat === 'TDC') v.LABEL = 'TC'
      if (labelFormat === 'TDC') v.LABEL = 'TC'
      if (labelFormat === 'DdG') v.LABEL = 'D'
      if (labelFormat === 'TdG') v.LABEL = 'TG'
      if (labelFormat === 'TGC') v.LABEL = 'TC'
      if (labelFormat === 'QdG') v.LABEL = 'Q'
      if (labelFormat === 'QdG') v.LABEL = 'Q'
      if (labelFormat === 'S5A7') v.LABEL = '7'
      if (labelFormat === 'S5') v.LABEL = '5'
      if (labelFormat === 'S5A6') v.LABEL = '6'
      if (labelFormat === 'S5A8') v.LABEL = '8'

      const prizesFormat = formatPremio(v)
      return { ...v, id: v.ID_MODAL, label: 'label', value: v, text: v.DS_MODAL, modalityPrizeFormat: prizesFormat }
    })
    return orderBy(formatLabel, e => e.COL_GROUP)
  },

  apostaLoterias: (state) => { // para realizar aposta
    let dados = state.loterias
    if (!size(dados)) return []
    dados = dados.flat()
    return dados.filter(v => v.DS_LOT !== 'Todas')
  },

  listProdutosSelect: (state) => state.produtos?.map(it => ({
    text: it.name.replaceAll('_', ' '),
    value: it.id
  })),

  listModalitiesSelect: (state) => state.modalidades?.map(it => ({
    text: it.NO_MODAL.replaceAll('_', ' '),
    value: it.ID_MODAL
  })),

  permutacoes: (state) => state.permutacoes,
  proximaLoteria: (state) => state.proximaLoteria,
}

export default { state, actions, mutations, getters, namespaced: true }
