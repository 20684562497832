import http from '@/core/http'
import _config from '@/core/config'
import { cloneDeep } from 'lodash'

const state = {
  bonus: {
    id: '',
    status: '',
    bonusValue: '',
    rollOverValue: '',
    rollOverValueTotal: '',
    formatExpirationDate: '',
    rollOverValueRestante: '',
    webBonuses: {
      minRollOver: '',
      description: ''
    }
  }
}

const actions = {
  getBonus: async ({ commit }) => {
    const result = await http.post(`${_config.API}bonus-web`)
    if (!result.data) throw new Error(result.data.message)
    commit('ADD_BONUS', result.data)
    return Promise.resolve(result)
  },
  cancelarBonus: async (_, payload) => {
    const result = await http.post(`${_config.API}cancel-bonus`, payload)
    if (!result.data) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  limparBonus: ({ commit }) => {
    commit('LIMPAR_BONUS')
  }
}
const mutations = {
  'ADD_BONUS' (state, payload) {
    state.bonus = cloneDeep(payload.data)
  },
  'LIMPAR_BONUS' (state) {
    state.bonus = {
      id: '',
      status: '',
      bonusValue: '',
      rollOverValue: '',
      rollOverValueTotal: '',
      formatExpirationDate: '',
      rollOverValueRestante: '',
      webBonuses: {
        minRollOver: '',
        description: ''
      }
    }
  }

}

const getters = {
  listBonus: (state) => state.bonus,
}

export default { actions, mutations, state, getters, namespaced: true }
