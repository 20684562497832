import session from '@/core/service/session'
import moment from 'moment'

const list = [
  { url: 'top-winners', ttl: 1440 }, // 1 dia
  { url: 'lista-produtos', ttl: 1440 },
  { url: 'lista-banners', ttl: 1440 },
  { url: 'suporte-watsapp', ttl: 60 }, // 
  { url: 'slots-casinos/get-games-home', ttl: 1440 },
  { url: 'slots-casinos', ttl: 1440 },
]

const format = 'YYYY-MM-DD HH:mm:ss'
export const verifyCache = (url) => {
  const isUrl = list.filter(v => v.url === url)[0]
  if (!isUrl) return false
  const cache = session.get(`resCache-${url}`)
  if (!cache) return false
  // console.log(cache.expireIn, moment().format(format), moment().isAfter(cache.expireIn))
  const invalid = cache ? moment().isAfter(cache.expireIn) : false
  if (invalid) {
    session.remove(`resCache-${url}`)
    return false
  }

  return cache.data
}

export const saveCookiesUrl = (url, data) => {
  const isUrl = list.filter(v => v.url === url)[0]
  if (!isUrl) return false  
  const cache = session.get(`resCache-${url}`)
  if (cache) return cache
  
  // const date = new Date()
  /* seta valores em minutos */
  session.set(`resCache-${url}`, { data, expireIn: moment().add(isUrl.ttl, 'minutes').format(format) })
}
