import http from '@/core/http'
// import { filter, groupBy, map, orderBy, size } from 'lodash'
import { orderBy } from 'lodash'
const state = {
  listaPremiosResgate: [],
  listaRevendas: [],
  listaPremiosResgatePendete: [],
  listaPremiosResgatePendetePix: [],
  listaPremiosIndexados: [],
  listaPalpitesPremiadosIndexados: [],
  dadosTransacaoPagamentoPix: null,
  jogoIndexado: {
    entity: {
      cod: ''
    },
    transmissions: {
      cod: ''
    },
    created: '',
    gameNumber: '',
    sweepstakes: {
      cod: '',
      dtDraw: ''
    },
    value: '',
    codGameId: '',
    winners: []
  },
  total: 0,
  loader: false,
}

const actions = {
  async getListaPremiosResgate ({ commit }, payload) {
    const result = await http.post('resgate/lista-operacoes', payload)
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_LISTA_PREMIOS_RESGATE', result.data)
    return Promise.resolve(result.data)
  },

  async getListaPremiosResgatePendentes ({ commit }, payload) {
    const result = await http.post('resgate/lista-operacoes-pendentes', payload)
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_LISTA_PREMIOS_RESGATE_PENDENTES', orderBy(result.data.data.format, 'dtHoraReferenciaExtend') || [])
    commit('SET_LISTA_PREMIOS_RESGATE_PENDENTES_INDEXADOS', orderBy(result.data.data.formatPix, 'dtHoraReferenciaExtend') || [])
    return Promise.resolve(result.data)
  },

  async solicitarSaque ({ commit }, payload) {
    let result
    if (payload.resgateCredito) {
      result = await http.post('resgate/creditos', payload)
    } else {
      result = await http.post('resgate/premios', payload)
    }
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },

  async getListaRevendas ({ commit }, payload) {
    const result = await http.get('resgate/lista-revendas-compradas', payload)
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_LISTA_REVENDAS', result.data)
    return Promise.resolve(result.data)
  },

  async getListaPremiosIndexados ({ commit }, payload) {
    const result = await http.post('resgate/lista-jogos-premiados-associados', payload)
    if (!result.data.success) throw Error(result.data.message)
    commit('LISTA_PREMIOS_INDEXADOS', result.data.data)
    return Promise.resolve(result.data)
  },

  async getJogoIndexado ({ commit }, payload) {
    const result = await http.post('associated-games', payload)
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_JOGO_INDEXADO', result.data.data)
    return Promise.resolve(result.data)
  },

  async solicitarPagamentoPix (_, payload) {
    const result = await http.post('resgate/solicitar-pagamento-pix-associados', payload)
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },

  async converterSolicitacaoPremioWebParaPix (_, payload) {
    const result = await http.post('resgate/converter-solicitacao-para-pix', payload)
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },

  async getPremioPagoPix ({ commit }, payload) {
    const result = await http.post('resgate/premio-pago-pix', payload)
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_DADOS_TRANSACAO_PAGAMENTO_PIX', result.data.data)
    return Promise.resolve(result.data)
  },

  async confirmarPagamentoPix (_, payload) {
    const result = await http.post('resgate/confirmar-pagamento-pix', payload)
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },

  async obterPalpitesPremiados ({ commit }, payload) {
    const result = await http.get('resgate/obter-palpites-premiados', { params: payload })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_LISTA_PALPITES_PREMIADOS_INDEXADOS', result.data.data)
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'SET_LISTA_PREMIOS_RESGATE' (state, payload) {
    state.listaPremiosResgate = payload.data
  },

  'SET_LISTA_REVENDAS' (state, payload) {
    state.listaRevendas = payload.data
  },

  'SET_LISTA_PREMIOS_RESGATE_PENDENTES' (state, payload) {
    state.listaPremiosResgatePendete = payload
  },

  'SET_LISTA_PREMIOS_RESGATE_PENDENTES_INDEXADOS' (state, payload) {
    state.listaPremiosResgatePendetePix = payload
  },

  'LISTA_PREMIOS_INDEXADOS' (state, payload) {
    state.listaPremiosIndexados = payload
  },

  'SET_JOGO_INDEXADO' (state, payload) {
    state.jogoIndexado = payload
  },

  'SET_DADOS_TRANSACAO_PAGAMENTO_PIX' (state, payload) {
    state.dadosTransacaoPagamentoPix = payload
  },

  'SET_LISTA_PALPITES_PREMIADOS_INDEXADOS' (state, payload) {
    state.listaPalpitesPremiadosIndexados = payload
  }
}

const getters = {
  listaPremiosResgate: (state) => state.listaPremiosResgate,
  listaRevendas: (state) => state.listaRevendas,
  listaPremiosResgatePendete: (state) => state.listaPremiosResgatePendete,
  listaPremiosIndexados: (state) => state.listaPremiosIndexados,
  jogoIndexado: (state) => state.jogoIndexado,
  listaPremiosResgatePendetePix: (state) => state.listaPremiosResgatePendetePix,
  dadosTransacaoPagamentoPix: (state) => state.dadosTransacaoPagamentoPix,
  listaPalpitesPremiadosIndexados: (state) => state.listaPalpitesPremiadosIndexados,
}

export default { state, actions, mutations, getters, namespaced: true }
