<template>
<!-- eslint-disable -->
<div :class="['container-fluid menu', 'bnp-header d-flex align-items-center flex-nowrap ps-0']" :style="[isDeposito ? 'min-height: unset !important' : '']">
  <div class="w-100" v-if="!isDeposito">
    <div class="d-flex justify-content-star align-items-center w-100 h-100" v-if="!isSlots">
      <div  class="header-version bold f10 d-none"> {{ vversion }} </div>

      <button v-if="!isCadastroPage" class="btn bnp-header-menu"  role="button" @click.stop="openMenu">
        <img :src="require('@/assets/icons/menu.svg')" alt="menu Bicho no pix" class="img-fluid ">
      </button>


      <div class="d-flex h-100">
        <h1 class="img-logo" style="font-size: 0px;" @click="redirect">
          Plataforma Jogo do Bicho no PIX Online
        </h1>
      </div>

      <!-- Header user não logado -->
      <template v-if="!isAuth">
        <div class="d-flex justify-content-center align-items-end w-100 bnp-header-text f14 w-100" >
          <div @click="openModal('open::modal::login')">
            <span class="btn entrar">
              <!-- <img :src="require('@/assets/header/perfil.svg')" alt="Bincho no pix Entrar" class="pe-1 bold f14" /> -->
              Entrar
            </span>
          </div>
        </div>

        <div class="center mx-3" :class="{'bnp-header-text bnp-header-button': !isCadastroPage}">
          <btn v-if="isCadastroPage" @click="redirect" class="btn btn-sair-cadastro text-white px-3"> <i class="bi bi-x-lg"></i> </btn>
          <span v-else @click="openModal('open::modal::cadastro')" class="btn default f12"> Cadastre-se </span>
        </div>
      </template>

      <!-- Header user logado -->
      <template v-if="isAuth">
        <div class="d-flex justify-content-center align-items-end bnp-header-text f10 ajuste-mobile-saldo w-100">
          <div class="saldo white fw-bold ">
              <div class="d-flex flex-column jutify-content-start align-items-start">
                <!-- <label class="label-currency text-end"> Saldo </label> -->
                <span class="w-100 text-center" v-if="isSlots">-</span>
                <span style="font-size: 14px;" v-if="!isSlots">{{ _currency(listSaldos.saldo) }}</span>
                <span v-if="!isSlots">{{ _currency(listSaldos.bonus) }}</span>
              </div>
              <!-- <div class="spacer"></div>
              <div class="d-flex flex-column jutify-content-start align-items-end">
                <label class="label-currency text-end"> Bônus </label>
                <span class="w-100 text-center" v-if="isSlots">-</span>
                <span v-else>{{ _currency(listSaldos.bonus) }}</span>
              </div> -->
              <btn @click="addCredit" class="btn btn-depositar"> Depositar </btn>
          </div>
        </div>

          <div class="header-perfil ms-1" @click="toggleMenuPerfil" ref="menu">
            <div class="header-icons">
              <div class="circle">
                <img :src="require('@/assets/icons/person.svg')" class="header-icon-person" />
              </div>

              <!-- <img :src="require('@/assets/icons/arrow_down.svg')" class="header-icon-arrow"/> -->
            </div>
            <ul v-if="isMenuOpenPerfil" class="dropdown-menu">

              <li @click="openRouter('perfil')">
                <div class="justify-content-center">
                  <img :src="require('@/assets/icons/meu_perfil_sub.svg')" alt="perfil" class="img-fluid img-menu px-2" />
                  <span class="px-2">Meu Perfil</span>
                </div>
              </li>

              <li @click="openRouter('minhasApostas')">
                <div class="justify-content-center">
                  <img :src="require('@/assets/icons/minhas_apostas_nav.svg')" alt="minhas apostas" class="img-fluid img-menu px-2" />
                  <span class="px-2">Minhas apostas</span>
                </div>
              </li>

              <li @click="openRouter('resgate')">
                <div class="justify-content-center">
                  <img :src="require(`@/assets/icons/saques.svg`)" alt="Saques" class="img-fluid img-menu px-2" style="width: 32px;"/>
                  <span class="px-2"> Saques </span>
                </div>
              </li>

              <li @click="openRouter('bonus')">
                <div class="justify-content-center">
                  <img :src="require('@/assets/icons/gift.svg')" alt="bonus" class="img-fluid img-menu px-2" />
                  <span class="px-2">Bônus</span>
                </div>
              </li>

              <li @click.prevent="logout" href="javascript:void;">
                <div class="justify-content-center">
                  <img src="@/assets/icons/sair.svg" class="img-fluid img-menu px-2" alt="sair"/>
                  <span class="px-2">Sair</span>
                </div>
              </li>
            </ul>
          </div>
      </template>
    </div>

    <div class="d-flex justify-content-between align-items-center w-100 h-100" v-else>
      <div class="d-flex justify-content-start align-items-center gap-2 h-100">
        <btn @click="redirect" class="btn btn-action-slot" ><i class="bi bi-house-door-fill"></i></btn>
        <btn @click="reload" class="btn btn-action-slot"><i class="bi bi-arrow-clockwise"></i></btn>
      </div>

      <div class="d-flex h-100">
        <h1 class="img-logo ms-0" @click="redirect">
          Jogo do Bicho Online
        </h1>
      </div>

      <div class="d-flex justify-content-end align-items-center gap-2 h-100">
        <btn @click="addCredit" class="btn btn-depositar-slot"> Depositar </btn>
        <btn @click="redirect" class="btn btn-sair"> <i class="bi bi-x-lg"></i> </btn>
      </div>
    </div>
  </div>
  <div class="w-100" v-if="isDeposito">
    <div class="d-flex justify-content-between align-items-center w-100 h-100">
      <div class="d-flex justify-content-start align-items-center gap-2 h-100" style="min-width: 78px;">
        <btn @click="redirect" class="btn btn-action-slot" style="font-size: 30px;" ><i class="bi bi-arrow-left-short"></i></btn>
      </div>

      <div class="d-flex h-100">
        <h1 class="ms-0 mb-0" style="font-weight: 700; font-size: 18px;">
          Depositar
        </h1>
      </div>

      <div class="d-flex justify-content-end align-items-center gap-2 h-100">
        <btn @click="redirect" class="btn text-white" style="font-weight: 400; font-size: 16px;"> Fechar </btn>
      </div>
    </div>
  </div>
</div>

<!-- <hr> -->
<!-- eslint-enable -->
</template>

<script>
import { filterMixins } from '@/core/mixins'
import { mapGetters, useStore } from 'vuex'
import { computed, onMounted, getCurrentInstance, ref } from 'vue-demi'
import { useRoute, useRouter } from 'vue-router'
import { mask } from 'maska'
import { size } from 'lodash'
import session from '../../core/service/session'
import { _alert, useDetectOutsideClick } from '../../core/service/utils'

export default {
  name: 'header',
  mixins: [filterMixins],
  components: {
    // topDownload: defineAsyncComponent(() => import('@/views/layout/cardDownload.vue'))
  },
  setup () {
    const app = getCurrentInstance()
    const { Events } = app.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()

    const redirect = () => {
      if (isCadastroPage.value) {
        openLeaveCadastroModal()
        return
      }
      if (router.currentRoute.value.fullPath === '/revenda') router.push({ name: 'auth' })
      router.push({ name: 'home' })
    }

    const store = useStore()
    const user = computed(() => store.state.login.user.phoneFormat)
    const vversion = computed(() => store.getters['_getVersion'])
    const isAuth = computed(() => store.getters['login/auth'])
    const isMenuOpenPerfil = ref(false)
    const menu = ref()

    const listSaldos = computed(() => store.getters['saldos/listSaldos'])

    const formatPhone = computed(() => {
      const dados = user.value || ''
      const mascara = mask(dados, '(##) # ####-####')
      const part1 = String(mascara).slice(0, 4)
      const part2 = String(mascara).slice(size(mascara) - 4, size(mascara))
      return `${part1} ... ${part2}`
    })

    const toggleMenuPerfil = () => {
      isMenuOpenPerfil.value = !isMenuOpenPerfil.value
    }

    useDetectOutsideClick(menu, () => {
      if (isMenuOpenPerfil) {
        isMenuOpenPerfil.value = false
      }
    })

    const logout = () => {
      store.dispatch('login/logout')
      store.dispatch('setMenu', false)
    }

    const addCredit = () => {
      router.push({ name: 'creditos' })
    }

    const openRouter = (routerName) => {
      router.push({ name: routerName })
    }

    const iframeCartelas = computed(() => {
      const show = ['/cartelas', '/cartelas2']
      return show.indexOf(route.path) !== -1
    })

    const isCadastroPage = computed(() => {
      return route.path === '/cadastro'
    })

    const isDeposito = computed(() => {
      return route.name === 'creditos'
    })

    const openLeaveCadastroModal = () => {
      openModal('open::modal::cadastro-sair')
    }

    const openModal = (name) => Events.emit(name, true)
    const openMenu = () => {
      store.dispatch('setMenu', !store.state.menu)
    }
    const userDados = computed(() => store.getters['perfil/oldUser']) || {}

    const isLogado = computed(() => {
      if (userDados.value.jsonFlags && userDados.value.jsonFlags.resetPassword) {
        return false
      }
      return store.state.login.token
    })

    const intervalCheckJWT = setInterval(() => {
      if (!isAuth.value) return
      store.dispatch('login/checkJWT').catch(async (e) => {
        console.log('error', e.message)
        if (e.message === 'Service Unavailable' || e.message === 'Cannot read properties of undefined (reading \'url\')') return
        if (e.message === 'jwt expired') {
          store.dispatch('login/refreshToken').catch(async (e) => {
            store.dispatch('logout')
            session.remove('auth-token')

            if (String(e.message).indexOf('Usuário bloqueado') !== -1) _alert(e.message, 'warning')
            else _alert('Sessão expirada!', 'warning')
            setTimeout(() => { window.location = '/' }, 1200)
          })
          return
        }

        store.dispatch('logout')
        session.remove('auth-token')

        if (String(e.message).indexOf('Usuário bloqueado') !== -1) _alert(e.message, 'warning')
        else _alert('Sessão expirada!', 'warning')
        setTimeout(() => { window.location = '/' }, 1200)
      })
    }, 1000 * 60 * 5)

    const initCheckJWT = () => ({
      intervalCheckJWT
    })

    const isSlots = computed(() => {
      return route.name === 'slotsIframe'
    })

    const reload = () => {
      window.location.reload()
    }

    onMounted(() => {
      initCheckJWT()
    })

    return {
      store,
      openMenu,
      isLogado,
      router,
      route,
      redirect,
      formatPhone,
      vversion,
      iframeCartelas,
      openModal,
      isAuth,
      addCredit,
      toggleMenuPerfil,
      isMenuOpenPerfil,
      logout,
      openRouter,
      menu,
      listSaldos,
      isSlots,
      reload,
      isCadastroPage,
      isDeposito,
    }
  },
  computed: {
    ...mapGetters('login', ['auth']),
  }
}

</script>
<style lang="scss" scoped>
@import '@/styles/variables';

.img-logo {
  background-image: url('/src/assets/logo.png');
  background-size: cover;
  color: transparent;
  width: 83px;
  height: 26px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  user-select: none;
}

.btn-action-slot{
  font-weight: bold;
  font-size: 24px;
  padding: 2px 6px;

  i{
    color: white;
  }
}

.btn-sair {
  font-weight: bold;
  font-size: 26px;
  padding: 2px 6px;
}

.btn-sair-cadastro {
  font-weight: bold;
  font-size: 20px;
  padding: 2px 2px;
  i {
    color: #FFF;
  }
}

.btn-depositar{
  border-radius: 4px;
  background-color: #3DBA5F !important;
  font-weight: bold;
  padding: 4px 8px;
  color: white;
  @media (max-width: 450px) {
    font-size: 16px;
    padding: 4px 8px;
  }
  &-slot {
    font-size: 18px;
    border-radius: 4px;
    background-color: #3DBA5F !important;
    font-weight: bold;
    padding: 8px 6px;
    color: white;
  }
}

i {
  color: $bg-index2;
}

.label-currency{
  font-size: 10px;
  color: #838494;
}
.spacer {
  height: 24px !important;
  border: 1px solid #838494;
}

.circle {
  width: 35px;
  height: 35px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 10px;
  &::before{
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: 3px;
    left: -5px;
    background: #3DBA5F;
    border-radius: 50%;
  }

}

.header-icon-person {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.saldo {
  display: flex;
  padding: 3px 5px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.20);

  @media (max-width: 350px) {
    padding: 2px 1px;
    gap: 2px;
  }

  & span {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 360px) {
      font-size: 10px;
    }
  }

  .img-add {
    width: 24px;
    height: 24px;
    background: #3DBA5F;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.header-icons {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 6px;

  @media (max-width: 350px) {
    padding-right: 0;
  }
}

.header-icon-arrow {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.dropdown-menu {
  display: block;
  position: absolute;
  align-items: center;
  background: var(--background-gradient-dark, linear-gradient(90deg, #090A14 3.02%, #0D0F1E 100%));
  border-radius: 8px;
  list-style: none;
  right: 10px;
  top: 70px;

  & li {
    color: white;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    padding: 8px 16px;
    margin: 5px;
    text-align: center;
    cursor: pointer;
  }
}

/* .header-icon-arrow:hover + .dropdown-menu {
  display: block;
} */

hr {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.entrar {
  font-family: 'Roboto';
  font-weight: 700;
  border: 1px solid #3DBA5F;
  color: #FFF;
  border-radius: 6px;
  cursor: pointer;
}
.box {
  color: $bg-index2;
  font-size: 10px;
  line-height: 1.2;
  font-weight: bold;
  &.resgate {
    cursor: pointer;
  }
  .title {
    font-weight: 400;
  }
  .value {
    font-size: 14px !important;
    font-weight: bold;
    letter-spacing: normal;
    @media(max-width: 568px) {
      font-size: 12px !important;
    }
  }
}
.border {
  border: 1px solid $bg-index2 !important;
}
.bi-list {
  font-size: 24px;
  color: white;
  border-radius: 5px;
  padding: 2px;
}
.header-version{
  position: absolute;
  left: -15px;
  color: white;
  transform: rotate(-90deg);
  top: 20px;

}
.bnp-header {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  min-height: 67px;
  padding-right: 0px !important;
  padding-top: 0px !important;
  background: $bg-index;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  background: var(--background-dark, #090A14);
  &-img {
    max-width: 73px;
    margin-left: 5px;
  }
  &-menu {
    padding-left: 5px;
    padding-right: 5px;
    img {
      min-width: 20px;
    }
  }
  &-text {
    color: white;
    font-weight: bold;
    @media (max-width: 350px) {
      .saldo {
        flex-direction: row !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    flex-direction: column;
  }
  &-button {
    min-width: 100px;
    font-weight: 800;
    @media (max-width: 350px) {
      margin-left: 5px !important;
    }
  }
}
.btn.default {
  background: #3DBA5F !important;
}
</style>
