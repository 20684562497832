export default {
  path: '/cassinos',
  name: 'cassinos',
  component: () => import(/* webpackChunkName: "cassinos" */'./index'),
  meta: {
    title: 'Jogue Slots Online: Diversão e Prêmios | BICHO NO PIX',
    description: 'Descubra os melhores slots online no BICHO NO PIX e ganhe prêmios incríveis. Jogue agora e divirta-se com as melhores máquinas de slots!',
    ogDescription: 'Descubra os melhores slots online no BICHO NO PIX e ganhe prêmios incríveis. Jogue agora e divirta-se com as melhores máquinas de slots!',
    canonical: window.location.href,
  },
  children: [
    {
      path: '/cassinos/slots',
      name: 'slots',
      component: () => import(/* webpackChunkName: "slots" */'./slots/index')
    },
    {
      path: '/cassinos/slots/:gameId',
      name: 'slotsIframe',
      component: () => import(/* webpackChunkName: "slots" */'./iframes/index')
    }
  ]
}
