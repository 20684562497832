import http from '@/core/http'
import session from '@/core/service/session'

const state = {
  urlProvider: null
}

const actions = {
  async getUrl ({ commit, rootGetters }, payload) {
    let result
    const token = session.get('auth-token')

    if (token) {
      const view = await http.post('regiao')
      if (view.data.success) {
        result = await http.get('integration-sports-book/url')
      }
    } else {
      result = await http.get('integration-sports-book/url-open')
    }
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_URL', result.data.data)
  }
}

const mutations = {
  'SET_URL' (state, payload) {
    state.urlProvider = payload.launch_url
  },
  'CLEAR' (state, payload) {
    state.urlProvider = null
  }
}

const getters = {
  urlProvider: state => state.urlProvider
}

export default { state, actions, mutations, getters, namespaced: true }
