<template>
  <Modal
    ref="thisModal"
    :visible="show"
    :showButtons="false"
    :close="'modal::produtos::jogoResponsavel'"
    :type="'jogo-responsavel'"
    :modifyClassModal="'modal-dialog modal-dialog-scrollable modal-xl'"
  >

    <template #title>
      <img
        :src="require('@/assets/logo.png')"
        alt="VerticalLoto loterias"
        class="img-fluid termo"
      />
      <div>

      </div>
    </template>

    <template #body>
      <p></p>
      <p style="text-align: center">
        <span style="color: green"><strong>Jogo Responsável</strong></span>
      </p>
      <div class="texto-body">
        <p>
          Jogar no Bichonopix é para ser divertido e interessante. Nosso compromisso
          é com nossos clientes compartilhando a emoção com você. Isso
          significa que é nossa responsabilidade lhe dar um ambiente de jogo
          seguro.
          <!-- , e é por isso que estamos encorajando todos a dar uma olhada em
          nossa seção "dicas e conselhos", para lhe dar todas as ferramentas
          necessárias para jogar com segurança e continuar se divertindo. -->
        </p>
        <br/>
        <span style="color: green"
          ><strong><p>Que tipo de limites posso colocar na minha conta?</p></strong></span
        >
          Você pode fazer um limite de depósito que te permite limitar quanto você pode depositar em um determinado período de tempo.
          Além disso, você pode optar por se autoexcluir por meio de um esquema de autoexclusão multioperador.
          No período que definir, você não poderá mais acessar sua conta.
        <br/>
        <br/>
        <span style="color: green"
          ><strong><p>O que é a autoexclusão?</p></strong></span
        >
          A autoexclusão permite que você feche sua conta por um período especificado de 1 hora, 6 horas, 12 horas, 1 semana, 1 mês, 6 meses, 1 ano, 3 anos ou infinito.
          No período de tempo selecionado, você não poderá jogar, embora ainda possa fazer login para retirar qualquer saldo restante.
          Além disso, nós lhe removeremos de nossos bancos de dados de marketing para não receber notificações.
        <br/>
        <br/>
        <span style="color: green"
          ><strong><p>Jogo para menores de idade</p></strong></span
        >
        É ilegal a abertura de contas no Bichonopix para menores de 18 anos bem como apostar no Bichonopix.
        O Bichonopix toma medidas rígidas neste sentido.
        São realizadas verificações de idade em todos os clientes que usam métodos de pagamento disponíveis para menores de 18 anos.
        Geralmente, realizamos verificações de idade aleatoriamente em clientes usando outros métodos de pagamento. 

        <br/>
        Lembrando que qualquer pessoa com uma conta no Bichonopix menor de 18 ou 21 anos terá seus ganhos confiscados e poderá ser denunciado as autoridades competentes,
        por força da Lei.
        <br/>
        <br/>
        <!-- <span style="color: green"
          ><strong><p>Dicas e conselhos</p></strong></span
        >
        O jogo deve ser apenas uma forma de entretenimento. Mas, às vezes, as
        coisas podem ir um pouco longe demais. Se você está preocupado com o
        quanto você joga, ou está preocupado com os hábitos de uma pessoa
        querida, então dê uma olhada nas seguintes dicas e conselhos.
        <br/>
        <br/>
        <span style="color: green"
          ><strong><p>Administrando seu jogo</p></strong></span
        >
        Jogar é uma forma de se divertir e desfrutar da emoção do esporte. Às
        vezes você pode ficar um pouco entusiasmado - e nós entendemos
        totalmente. É por isso que temos uma série de ferramentas de
        gerenciamento de contas para ajudá-lo a manter as coisas sob controle.
        Limites de Depósito

        <br/>
        <br/>
        <li>Auto-exclusão definitiva</li>
        <p></p>

        <li>Auto-exclusão Indefinida</li>
        <p></p>

        <li>Lembretes de sessão</li>
        <p></p>

        <li>Fechamento de conta</li>
        <br/>
        <br/>
        <span style="color: green"
          ><strong><p>Nossa Política de Jogo Responsável</p></strong></span>

        Para mais opções de gerenciamento de conta, consulte a política de Jogo
        Responsável abaixo ou entre em contato com o serviço de atendimento ao
        cliente. -->
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/modal.vue'

export default {
  name: 'modalJogoResponsavel',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Modal
  }
}
</script>
<style lang="scss" scoped>
.img-fluid.termo {
  max-width: 150px;
}

.divPrincipal {
  font-family: Arial, Helvetica, sans-serif;
}

.texto-body{
  padding-top: 30px;
}

@import '@/styles/variables';
</style>
