import http from '@/core/http'
// import moment from 'moment'
const state = {
  resultado: false,
  loader: false,
  filtros: false,
  stashLotery: '',
  loteriaMap: {
    LK: 'look-goias',
    FD: 'federal',
    PT: 'pt-rio',
    QB: 'quina',
    LF: 'lotofacil',
    SH: 'mega-sena',
  },
  urlLoteria: '',
  urlInitDate: '',
  urlProduto: '',
}

const actions = {
  async getResultados ({ commit, state }, payload) {
    state.resultado = false
    // let result

    // if (!payload.dtEnd || payload.dtEnd === moment().format('YYYY-MM-DD')) {
    // resultado aberto
    // payload.dtEnd = moment().format('YYYY-MM-DD')

    const result = await http.get('resultados/prognosticos-aberto', { params: { ...payload } })
    // } else {
    // precisa estar logado
    // result = await http.get('resultados/prognosticos', { params: { ...payload } })
    // }

    if (!result.data.success) throw Error(result.data.message)

    commit('SET_RESULTADO', result.data)
    return Promise.resolve(result.data)
  },

  // loader resultado
  async setLoader ({ commit, state }, payload) {
    state.loader = payload
  },

  async setFiltros ({ commit, state }, payload) {
    state.filtros = payload
  },

  async setProdutoURL ({ commit, state }, payload) {
    commit('SET_PRODUTO_URL', payload)
  },

  async setLoteriaURL ({ commit, state }, payload) {
    commit('SET_LOTERIA_URL', payload)
  },

  async clearLoteriaURL ({ commit, state }, payload) {
    commit('CLEAR_LOTERIA_URL', payload)
  },

  async setInitDateURL ({ commit, state }, payload) {
    commit('SET_INITDATE_URL', payload)
  },

  async resetarResultados ({ commit }) {
    commit('LIMPAR_RESULTADO', false)
  },

  setStashLotery ({ commit }, payload) {
    commit('STASH_LOTERY', payload)
  }
}

const mutations = {
  'SET_RESULTADO' (state, payload) {
    state.resultado = payload.data.result || false
  },

  'SET_PRODUTO_URL' (state, payload) {
    state.urlProduto = payload
  },

  'SET_LOTERIA_URL' (state, payload) {
    state.urlLoteria = payload
  },

  'CLEAR_LOTERIA_URL' (state) {
    state.urlLoteria = ''
  },

  'SET_INITDATE_URL' (state, payload) {
    state.urlInitDate = payload
  },

  'LIMPAR_RESULTADO' (state, payload) {
    state.resultado = payload
  },

  'STASH_LOTERY' (state, payload) {
    state.stashLotery = payload
  }
}

const getters = {
  listResultados: (state) => state.resultado,
  getLoader: (state) => state.loader,
  getFiltro: (state) => state.filtros,
  getStashLotery: (state) => state.stashLotery,
  getLoteriasMap: (state) => state.loteriaMap,
  getLoteriaURL: (state) => state.urlLoteria,
  getInitDateURL: (state) => state.urlInitDate,
  getProdutoURL: (state) => state.urlProduto,
}

export default { state, actions, mutations, getters, namespaced: true }
