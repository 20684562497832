<template>
  <div id="modal-component" class="modal fade" :class="[bilhete ? 'card-fundo-bilhete' : 'card-fundo']" ref="exampleModal" tabindex="-1"  aria-hidden="true"  :data-bs-backdrop="outclickClose ? 'true' : 'static'" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel">
    <div :class="[ modifyClassModal, 'modal-dialog modal-dialog-centered modal-dialog-scrollable']">
      <div :style="{ 'min-height': '90vh' }"
      :class="['modal-content',
          bilhete ? 'bilhete' : ' ',
          isTransparent ? 'transparent' : '',
          video ? 'video': '',
          geolocation ? 'geolocation' : '',
          infoAposta ? 'info-aposta' : '',
          type
        ]">
      <div class="close-modal" v-if="showClose">
        <button v-if="newClose" type="button" @click="modalClose()" class="close f14 d-flex align-items-center"> Fechar <i class="bi bi-x"></i> </button>
        <button v-else type="button" @click="modalClose()" class="close f14 d-flex align-items-center"> <i class="bi bi-x f10"></i> Fechar</button>
      </div>
      <div :class="[modifyClassModalContentStyle ? modifyClassModalContentStyle : 'modal-content-style']">
        <div class="serrilhado" v-if="bilhete"></div>
        <div :class="[modifyClassModalHeader ? modifyClassModalHeader : 'modal-header w-100 pb-0']">
          <div class="modal-title text-center w-100" id="exampleModalLabel">
            <!-- titulo -->
            <slot name="title"></slot>
          </div>
        </div>
        <div :class="[modifyClassModalBody ? modifyClassModalBody : 'modal-body', geolocation ? 'geolocation' : '']">

          <!-- BODY -->
          <slot name="body"></slot>

        </div>

        <div :class="[modifyClassModalFooter ? modifyClassModalFooter : 'modal-footer']">
          <slot name="footer"></slot>
          <button v-if="showButtons" type="button" class="btn btn-secondary" @click="modalClose()"> Cancelar </button>
          <button v-if="showButtons" type="button" class="btn btn-primary"> Salvar </button>
        </div>
        <!-- <div class="serrilhado-bottom" v-if="bilhete"></div>
        <div class="img-bottom" v-if="bilhete"></div>
        <div class="img-border-left" v-if="bilhete"></div>
        <div class="img-border-rigth" v-if="bilhete"></div> -->
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap'
import store from '@/store'
export default {
  name: 'AppModal',
  props: {
    autoClose: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: Boolean,
      default: true
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    close: {
      type: String,
      default: 'modal::close'
    },
    bilhete: {
      type: Boolean,
      default: false
    },
    video: {
      type: Boolean,
      default: false
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    geolocation: {
      type: Boolean,
      default: false
    },
    infoAposta: {
      type: Boolean,
      default: false
    },
    type: {
      type: [String],
      default: ''
    },
    modifyClassModal: {
      default: false
    },
    modifyClassModalHeader: {
      default: false
    },
    modifyClassModalBody: {
      default: false
    },
    modifyClassModalContentStyle: {
      default: false
    },
    modifyClassModalFooter: {
      default: false
    },
    outclickClose: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    },
    newClose: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    modal: null,
  }),

  mounted () {
    const vm = this
    vm.modal = new Modal(vm.$refs.exampleModal)

    const modalElement = document.getElementById('modal-component')
    modalElement.addEventListener('hidden.bs.modal', () => {
      this.Events.emit(this.close, false)
    })

    vm.$refs.exampleModal.addEventListener('hidden.bs.modal', this.modalClose)
  },

  beforeUnmount () {
    this.Events.emit(this.close, false)
  },

  methods: {
    modalClose () {
      this.Events.emit(this.close, false)
      this.modal.hide()
    },
  },
  watch: {
    visible (val) {
      if (val === true) {
        store.dispatch('setMenu', false)
        return this.modal.show()
      }
      this.modal.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/bnp_variables';

.modal {
  &-content {
    position: relative;
    background: transparent !important;
    border-radius: 20px;
    border: transparent !important;
    min-height: 60vh !important;
    /* max-height: 60vh !important; */
    /* overflow-y: auto !important; */

    .modal-content-style {
      /* background-color: rgb(249, 253, 250); */
      /* background-color: $modal_background; */
      background: var(--background-gradient-dark, linear-gradient(90deg, #090A14 3.02%, #0D0F1E 100%));
      overflow-y: auto !important;
    }
    &.info-aposta {
      min-height: 90vh !important;
      max-height: 90vh !important;
    }
    &.geolocation {
      min-height: 30vh !important;
      max-height: 30vh !important;
      @media(max-height: 650px) {
        max-height: 50vh !important;
        min-height: 50vh !important;
      }
      .modal-body{
        display: flex !important;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
    }
    &.bilhete {
      position: relative;
      border-radius: initial !important;
      min-height: 95vh !important;
      // max-height: 85vh !important;

      @media(max-height: 700px) {
        min-height: 95vh !important;
        // max-height: 95vh !important;
      }

      .close-modal{
        display: none !important;
        button{
          left: 0 !important;
          top: -20px;
          padding-top: 0 !important;
          i { font-size: 32px !important;}
        }
      }

      .modal-content-style {
        overflow-y: auto !important;
        max-height: 90vh !important;
        background: transparent !important;
        @media (max-width: 769px) {
          position: relative;
          top: 10vh;
        }
      }
      border: none !important;
      .modal-header {
        background-position-y: top;
        background-size: 100% 100%;
        position: relative;
        background: #121B36 !important;
        padding: 0 ;
      }
      .modal-body {
        padding: 0px !important;
        background: transparent !important;
      }
      .modal-footer{
        padding: 0px;
        // height: 72px;
        gap: 0px;
        // background: #121B36 !important;
      }

      .serrilhado {
        background: url('@/assets/bilhete/serrilhado-top.png') repeat #121B36;
        background-position-y: top;
        position: absolute;
        top: 0px;
        left: 0;
        height: 11px;
        width: 100%;
        z-index: 9999999999;
        &-bottom {

          background-position-y: bottom;
          background-size: 100% 18px;
          position: absolute;
          bottom: 0px;
          height: 18px;
          width: 100%;
          z-index: 9999999999;
        }
      }
    }
    &.transparent {
      background: rgba(0, 0, 0, -1);
      border: none;
    }
    &.video {
      min-height: 50vh !important;
      max-height: 60vh !important;
      @media(max-height: 700px) {
        min-height: 95vh !important;
        max-height: 95vh !important;
      }
    }
    &.modal-dialog.video{
      min-width: 100vw !important;
      max-height: 10vh !important;
      min-height: 10vh !important;
    }
    &.termo-uso {
      min-height: 95vh !important;
    }

    &.jogo-responsavel{
      min-height: 95vh !important;
    }
  }
  &-header {
    border: none;
  }
  &-footer {
    border: none;
  }
  .close-modal {
    display: flex !important;
    width: 100%;
    justify-content: end;
    padding-top: 5px;
    button.close {
      // bottom: 0;
      // left: 10px;
      background: transparent;
      border: none;
      font-size: normal;
      font-weight: bold;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      z-index: 9999999999999999999999999999;
      cursor: pointer;
      i {
        /* font-weight: bold; */
        font-size: 20px;
        // width: 8px;
        // height: 5px;
        // display: flex;
        // padding-top: 5px;
        // padding-right: 75px;
      }
    }
  }
}

.img-bottom {
  background: url('@/assets/bilhete/bottom.png') no-repeat;
  background-position: bottom;
  background-attachment: initial;
  position: absolute;
  background-size: 100% 48%;
  width: 100%;
  height: 379px;
  z-index: 0;
  bottom: 18px;
  @media(max-height: 700px) {
    background-size: 100% 20%;
  }
  display: none;
}
.img-border-left {
  background: url('@/assets/bilhete/esquerda.svg') repeat-y;
  background-size: 174%;
  background-position: bottom;
  background-attachment: initial;
  position: absolute;
  width: 100%;
  height: 127%;
  width: 22px;
  z-index: 0;
  top: 0px;
  display: none;
}
.img-border-rigth {
  background: url('@/assets/bilhete/direita.svg') repeat-y;
  background-size: 100%;
  background-position: bottom;
  background-attachment: initial;
  position: absolute;
  width: 100%;
  height: 100%;
  width: 15px;
  z-index: 0;
  top: 0px;
  right: 10px;
  display: none;
}

.card-fundo {
  background: rgba(11, 13, 25, 0.2);
  box-shadow: 0px 13px 27px #2E951F11;
  border-radius: 0px 0px 0px 0px;
}

.card-fundo-bilhete {
  background: rgba(11, 13, 25, 0.9);
  box-shadow: 0px 13px 27px #2E951F11;
  border-radius: 0px 0px 0px 0px;
}

.default-home {
  .modal-content {
    min-height: auto !important;
    /* max-height: 70vh !important; */
    margin-top: 20px;
    background-color: rgb(249, 253, 250);
  }
  .modal-content-style {
    border-top: 8px solid $sup25;
    box-shadow: 0px 13px 27px #2E951F11;
    border-radius: 10px;
    /* max-height: 755px; */
    /* background-color: rgb(249, 253, 250); */
  }

  .modal-dialog {
    align-items: self-start;
  }
}
.default-home-login {
  .modal-content {
    min-height: auto !important;
    /* max-height: 70vh !important; */
    margin-top: 20px;
    background-color: rgb(249, 253, 250);
  }
  .modal-content-style {
    // border-top: 8px solid $sup25;
    box-shadow: 0px 13px 27px #2E951F11;
    border-radius: 10px;
    background: #242530 !important;
    /* max-height: 755px; */
    /* background-color: rgb(249, 253, 250); */
  }

  .modal-dialog {
    align-items: self-start;
  }
}
.default-home-sair-cadastro {
  .modal-content {
    min-height: auto !important;
    /* max-height: 70vh !important; */
    margin-top: 20px;
    background-color: #242530 !important;
  }
  .modal-content-style {
    background-color: #242530 !important;
    border-top: none;
    box-shadow: 0px 13px 27px #2E951F11;
    border-radius: 10px;
    /* max-height: 755px; */
    /* background-color: rgb(249, 253, 250); */
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    background-color:  inherit;
  }

  .modal-dialog {
    align-items: self-start;
  }
}

</style>
