export default {
  path: '/esportes',
  name: 'esportes',
  component: () => import(/* webpackChunkName: "esportes" */'./index'),
  // meta: {
  //   title: 'Jogue Slots Online: Diversão e Prêmios | BICHO NO PIX',
  //   description: 'Descubra os melhores slots online no BICHO NO PIX e ganhe prêmios incríveis. Jogue agora e divirta-se com as melhores máquinas de slots!',
  //   ogDescription: 'Descubra os melhores slots online no BICHO NO PIX e ganhe prêmios incríveis. Jogue agora e divirta-se com as melhores máquinas de slots!',
  //   canonical: window.location.href,
  // },
}
