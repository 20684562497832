import http from '@/core/http'

const state = {
  cotacoes: []
}

const actions = {
  async getCotacoes ({ commit }, payload) {
    const result = await http.get('cotacoes')
    if (!result.data.success) throw Error(result.data.message)
    commit('ADD_COTACAO', result.data.data)
    return Promise.resolve(result.data.data)
  },
}
const mutations = {
  'ADD_COTACAO' (state, payload) {
    state.cotacoes = payload
  }
}
const getters = {
  listCotacoes: (state) => {
    return state.cotacoes
  },
}

export default { state, actions, mutations, getters, namespaced: true }
