import moment from 'moment'

export default (val, format = null, convert = 'DD/MM/YYYY') => {
  if (val === 'now') return moment().format(convert)
  if (format === null) return moment(val).format(convert)
  
  const data = moment(val, format).format(convert)
  if (data !== 'Invalid Date') return data
  return val
}
