// import bingo from './views/gameBingo/router'
// import raspadinha from './views/raspadinha/router'
import auth from './views/auth/router'
import resultados from './views/resultados/router'
import home from './views/home/router'
import apostas from './views/apostas/router'
import indexarBilhetes from './views/indexarBilhetes/router'
import minhasApostas from './views/minhasApostas/router'
import extrato from './views/extrato/router'
import perfil from './views/perfil/router'
import resgate from './views/resgate/router'
import creditos from './views/creditos/router'
import conferirBilhete from './views/conferirBilhete/router'
import produtos from './views/produtos/router'
import revenda from './views/revenda/router'
import revendaCadastro from './views/revenda/cadastro/router'
import meusBonus from './views/meusBonus/router'
import iframeCartelas from './views/iframe/router'
import iframeCartelas2 from './views/iframe_novo/router'
import cotacao from './views/cotacao/router'
import bnpBonus from './views/bnpBonus/router'
import promocoes from './views/promocoes/router'
import cassinos from './views/cassinos/router'
import afiliado from './views/afiliado/router'
import cadastroAfiliado from './views/cadastroAfiliado/router'
import cadastro from './views/cadastro/router'
import cadastroAfiliadoConcluido from './views/cadastroAfiliadoConcluido/router'
import bnp1 from './views/bnp1/router'
import bnp2 from './views/bnp2/router'
import instalacoes from './views/instalacoes/router'
import downloadRF from './views/download-RF/router'
import download from './views/download/router'
import videoComoJogar from './views/videoComoJogar/router'
import decifradorDeSonhos from './views/decifrador/router'
import esportes from './views/esportes/router'
import apostaPix from './views/apostaPix/router'
import termoseCondicoes from './views/termosECondicoes/router'
import politicaePrivacidade from './views/politicaPrivacidade/router'
// import recuperarSenhaTerminal from './views/recuperarSenhaTerminal/router'
import bilheteTerminal from './views/bilheteTerminal/router'

export default [
  // raspadinha,
  // bingo,
  home,
  resultados,
  auth,
  apostas,
  indexarBilhetes,
  minhasApostas,
  extrato,
  perfil,
  resgate,
  creditos,
  conferirBilhete,
  produtos,
  revenda,
  revendaCadastro,
  meusBonus,
  iframeCartelas,
  iframeCartelas2,
  cotacao,
  bnpBonus,
  promocoes,
  cassinos,
  afiliado,
  cadastroAfiliado,
  cadastro,
  cadastroAfiliadoConcluido,
  bnp1,
  bnp2,
  instalacoes,
  downloadRF,
  download,
  videoComoJogar,
  decifradorDeSonhos,
  esportes,
  apostaPix,
  termoseCondicoes,
  politicaePrivacidade,
  // recuperarSenhaTerminal,
  bilheteTerminal,
]
