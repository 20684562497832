
import axios from 'axios'
import session from '@/core/service/session'
import mitt from 'mitt'
import _config from '@/core/config'
import { _alert } from './service/utils'
import { size } from 'lodash'
// eslint-disable-next-line
import { verifyCache, saveCookiesUrl } from './cacheInterceptor'
// eslint-disable-next-line
const CryptoJS = require('crypto-js')
// const CLIENTE = axios.create({
//   baseURL: _config.API
// })
axios.defaults.baseURL = _config.API

const CLIENTE = axios.create({})
const Events = mitt()
// eslint-disable-next-line
const exceptionEncryptUrl = ['autenticar']

CLIENTE.interceptors.request.use((config) => {
  if (session.get('auth-token')) {
    // config.headers['authorization'] = session.get('auth-token')
    // config.setHeader()
    config.headers.common['authorization'] = session.get('auth-token')
    config.headers['x-access-token'] = session.get('auth-token')
  }

  config.headers['type'] = 'usuario'
  if (!config.headers['emit']) Events.emit('before-request')

  /* faz cache local de solicitacoes */
  const isCacheRequest = verifyCache(config.url)
  
  if (isCacheRequest) {
    config.adapter = (request) => {
      return new Promise((resolve) => {
        resolve({
          data: isCacheRequest.data,
          status: 202,
          statusText: 'OK CACHE REQUEST',
          headers: isCacheRequest.headers,
          config: request,
          request: {}
        })
      })
    }
  }

  if (config.method === 'post' && !exceptionEncryptUrl.includes(config.url) && config.data) {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(config.data), _config.KBATATINHA).toString()
    config.data = { key: ciphertext }
  }

  return config
}, (error) => {
  Events.emit('request-error')
  return Promise.reject(error)
})

// Add a response interceptor
CLIENTE.interceptors.response.use((config) => {
  if (!config.config.headers['emit']) Events.emit('after-response')
  // const errorToken = config && config.data && !config.data.success && config.data.message === 'Token não fornecido' ? 1 : 0
  // if (errorToken) {
  //   session.remove('auth-token')
  //   session.remove('associarJogoRevenda')
  //   setTimeout(() => { window.location.href = 'auth' }, 3000)
  // }
  /* Salva respostas nos cookies */
  if (config.data && config.data.success !== false && size(config.data.data)) {
    const bytes = CryptoJS.AES.decrypt(config.data.data, _config.KBATATINHA)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    saveCookiesUrl(config.config.url, config)
    config.data.data = decryptedData
  }
  
  if (config.headers['refresh_token']) {
    session.set('auth-token', config.headers['refresh_token'])
  }
  // (config.url)
  
  return config
}, (error) => {
  Events.emit('response-error')
  if (error.response) {
    // urls que nao emitem error e retiram o token, para nao redirecionar e continuar execução do bingo
    const exceptions = [
      '/bingo/list-cartelas'
    ]

    const endpoint = error.response.config.url.replace(error.response.config.baseURL, '')
    if (exceptions.indexOf(endpoint) !== -1) {
      return Promise.reject(error.response.data)
    }

    if (error.response.status === 401) {
      _alert('Sessão expirada!', 'warning')
      session.remove('auth-token')
      session.remove('associarJogoRevenda')
      document.getElementsByTagName('body')[0].removeAttribute('style')
      setTimeout(() => { window.location = '/' }, 1200)
    }
    // Events.$emit('response-error')
    return Promise.reject(error.response.data)
  }
})

export default CLIENTE
