export default {
  path: '/decifrador-de-sonhos',
  name: 'decifrador-de-sonhos',
  component: () => import('./index.vue'),
  meta: {
    title: 'Decifrador de Sonhos: Significados e Números para o Jogo do Bicho',
    description: 'Descubra os significados dos seus sonhos e os números correspondentes para o jogo do bicho. Explore nosso decifrador agora!',
    ogDescription: 'Descubra os significados dos seus sonhos e os números correspondentes para o jogo do bicho. Explore nosso decifrador agora!',
    canonical: window.location.href,
  },
}
