import 'bootstrap/dist/css/bootstrap.min.css'
import { createApp, h } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './core/router'
import store from './store'
import mitt from 'mitt'
import 'bootstrap-icons/font/bootstrap-icons.css'
import mask from 'maska'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import currency from '@/core/filters/currency'
import Toast from 'vue-dk-toast'
import gTag from 'vue-gtag'
import vuePaginate from 'vuejs-paginate-next'
import { createHead } from '@vueuse/head'
// import createjs from '@/core/plugins/installCreatejs'
// store.subscribe((action, state) => {
//   console.log(action.type)
//   const stateNow = Object.keys(state).map(v => {
//     const itens = Object.assign({}, state[v])
//     return itens
//   })
//   console.log(stateNow)
// })

// console.log(store._modules.root._children)

const Events = mitt()
const head = createHead()
// window.createjs.$store = store

const app = createApp({
  render: () => h(App)
})

app.config.globalProperties.Events = Events
app.config.globalProperties._Currency = currency

// colors para cada produto no javascript
app.config.globalProperties.$COLORS = {
  BT: '#079CD2',
  SUP25: '#43a047',
  SUP5: '#FF5454',
  QB: '#3949ab',
  QUINZAO: '#2195F2',
  SURPRESINHA: '#8811bc',
  SENINHA: '#ff9200',
  DEFAULT: '#FFF'
}

app.use(Toast).use(gTag, {
  config: {
    // id: 'G-Y9B21QGEV7',
    id: 'G-5Z4EYF0XTR',
    params: {}
  }
}).use(router).use(head).use(mask).use(vuePaginate).use(VueLoading, {
  loader: 'bars',
  color: '#007bff',
  with: 128,
  heigth: 128
}).use(store).mount('#app')

// eslint-disable-next-line
import "bootstrap/dist/js/bootstrap.js"

export { Events }
