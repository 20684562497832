export default {
  GRUPO: {
    titulo: 'Para começar a apostar, é muito fácil',
    sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,
  
    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a GRUPO e iremos concorrer 18 vezes o valor apostado.',
    imgAjuda2: true,
  
    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, é hora de escolher em quais prêmios deseja concorrer. Você pode participar separadamente no 1º, 2º, 3º, 4º, 5º e 1º ao 5º. Para obter premiação máxima o ganhador terá que acertar do 1° ao 5° prêmio.',
    imgAjuda3: true,
  
    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso do GRUPO você aposta escolhendo a partir de um grupo entre 01 a 25.',
    imgAjuda4: true,
  
    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar a sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,
  
    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio.',
    imgAjuda6: true,
    button6: true
  },
  
  DUPLA_DE_GRUPO: {
    titulo: 'Para começar a apostar, é muito fácil',
    sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,
  
    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a DUPLA DE GRUPO e iremos concorrer 18 vezes o valor apostado.',
    imgAjuda2: true,
  
    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, é hora de escolher em quais prêmios deseja concorrer. Você pode participar do 1º ao 2º e 1º ao 5º. Para obter premiação máxima o ganhador terá que acertar do 1° ao 5° prêmio.',
    imgAjuda3: true,
  
    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso do DUPLA DE GRUPO você apostar escolhendo de 2 a 10 grupos entre 01 a 25.',
    imgAjuda4: true,
  
    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar a sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,
  
    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio.',
    imgAjuda6: true,
    button6: true
  },
  
  PASSE: {
    titulo: 'Para começar a apostar, é muito fácil',
    sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,
  
    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a PASSE e iremos concorrer 90 vezes o valor apostado.',
    imgAjuda2: true,
  
    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, é hora de escolher em quais prêmios deseja concorrer. Você pode participar separadamente no 1º ao 2º e 1º ao 5º. Para obter premiação máxima o ganhador terá que acertar do 1° ao 5° prêmio.',
    imgAjuda3: true,
  
    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso do PASSE você apostar escolhendo de 2 a 10 grupos entre 01 a 25.',
    imgAjuda4: true,
  
    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar a sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,
  
    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio.',
    imgAjuda6: true,
    button6: true
  },
  TERNO_DE_GRUPO: {
    titulo: 'Para começar a apostar, é muito fácil',
    sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,
  
    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a TERNO DE GRUPO e iremos concorrer 150 vezes o valor apostado.',
    imgAjuda2: true,
  
    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, é hora de escolher em quais prêmios deseja concorrer. Você pode participar separadamente no 1º ao 3º, 1º ao 5º, 2º ao 4º, 3º ao 5º. Para obter premiação máxima o ganhador terá que acertar do 1° ao 5° prêmio.',
    imgAjuda3: true,
  
    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso do TERNO DE GRUPO você apostar escolhendo de 3 a 10 grupos entre 01 a 25.',
    imgAjuda4: true,
  
    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar a sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,
  
    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio.',
    imgAjuda6: true,
    button6: true
  },
}
