import store from '@/store'
import { Events } from '@/main'
import moment from 'moment'
moment.locale('pt-br')

export async function validateResultados ({ to, from, next }) {
  const segments = to.params.name
  let produto = null
  let loteria = null
  let data = null

  const loteriaMap = store.getters['resultados/getLoteriasMap']

  if (!loteriaMap) {
    console.error('loteriaMap não está definido')
    return next('/')
  }

  const loteriaValues = Object.values(loteriaMap)

  // Identifica o produto, a loteria (opcional) e a data
  if (segments.length === 2) {
    [produto, data] = segments
  } else if (segments.length === 3) {
    [produto, loteria, data] = segments

    if (loteriaValues.indexOf(loteria) === -1) {
      return next('/')
    }
  } else {
    return next('/')
  }

  const isSpecialDate = data === 'hoje' || data === 'ontem'
  const isValidDate = moment(data, 'DD-MM-YYYY', true).isValid()

  if (!isSpecialDate && !isValidDate) {
    return next('/')
  }

  if (data !== 'hoje' && data !== 'ontem') {
    data = moment(data, 'DD/MM/YYYY').format('YYYY/MM/DD')
  }

  await store.dispatch('resultados/setProdutoURL', produto)
  await store.dispatch('resultados/setLoteriaURL', loteria)
  await store.dispatch('resultados/setInitDateURL', data)
  await store.dispatch('listagemProdutos/setSelectProdutoAtual', produto)

  Events.emit('select:default:lotery', loteria)

  next()
}
