import http from '@/core/http'

const state = {
  conferir: null
}

const actions = {
  async getWinnerGames ({ commit }, payload) {
    const result = await http.post('get-winner-games', { ...payload })
    if (!result.data.success) throw Error(result.data.message)
    
    commit('SET_WINNER_GAME', result.data)
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'SET_WINNER_GAME' (state, payload) {
    state.conferir = payload
  }
}

const getters = {}

export default { state, actions, mutations, getters, namespaced: true }
