import http from '@/core/http'
import { Events } from '@/main'
import Cookies from 'js-cookie'
import loginCadastro from './loginCadastro'

const state = {
  saldos: {
    saldo: 0,
    credito: 0,
    premio: 0,
    bonus: 0
  },
  saldosRetained: {
    creditsRetained: 99999,
  },
  balanceLow: '',
  saldoInterval: false,
  depositsData: [],
  balanceLowShownAfterLogin: false,
  user: {}
}

const actions = {
  async getSaldos ({ commit, dispatch, state }) {
    const result = await http.get('saldos')
    if (!result.data.success) throw new Error(result.data.message)
    commit('SET_SALDOS', result.data.data)
    commit('SET_DEPOSITS_DATA', result.data.data)
    if (window.location.pathname !== '/credito-pix') {
      commit('VERIFY_BALANCE')
    }
    return Promise.resolve(result.data.data)
  },

  async getSaldosRetained ({ commit, dispatch, state }) {
    const result = await http.get('saldos/retained')
    if (!result.data.success) throw new Error(result.data.message)
    commit('SET_SALDOS_RETAINED', result.data.data)
    return Promise.resolve(result.data.data)
  },

  async setSaldos ({ commit }, payload) {
    commit('SET_SALVO_LOGIN', payload)
    return Promise.resolve(payload)
  }
}

const mutations = {
  'SET_SALVOS_LOGIN' (state, payload) {
    state.saldos = payload
  },
  'SET_SALDOS' (state, payload) {
    state.saldos = payload
  },
  'SET_SALDOS_RETAINED' (state, payload) {
    state.saldosRetained = payload
  },
  'SET_DEPOSITS_DATA' (state, payload) {
    state.depositsData = payload.depositsData
  },
  'VERIFY_BALANCE' (state) {
    if (state.saldos.saldo < 1 && !state.balanceLow) {
      const today = new Date().toISOString().split('T')[0]

      state.user = loginCadastro.state.user

      const popupKey = `popupBalanceLow_${state.user.cpf}_${today}`

      if (!Cookies.get(popupKey) && !state.balanceLowShownAfterLogin && window.location.pathname !== '/credito-pix') {
        Events.emit('open::modal::popupSaldoBaixo', true)
        Cookies.set(popupKey, 'true', { expires: 1 })
        state.balanceLowShownAfterLogin = true
      }
      state.balanceLow = true
    }
  },
}

const getters = {
  listSaldos (state) {
    return state.saldos
  },
  listSaldosRetained (state) {
    return state.saldosRetained
  },
  listDepositsInfo (state) {
    return state.depositsData
  }
}

export default { state, actions, mutations, getters, namespaced: true }
