/* eslint-disable */
import Alert from 'sweetalert2'
// eslint-disable-next-line no-unused-vars
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import vuex from 'vuex'
import numeral from 'numeral'
import {onBeforeUnmount, onMounted} from 'vue'
// eslint-disable-next-line no-unused-vars
import { sampleSize, range, map, zip, flatten, each, compact, size, maxBy } from 'lodash'
import session from './session'

export const randomize = function (multi = false, min = 0, max = 100, splice = 2) {
  let r = Math.floor(Math.pow(10, (min - 1)) + Math.random() * (Math.pow(10, min) - Math.pow(10, (min - 1)) + 1))
  let n = String(r).split('').map((x) => {
    return parseInt(x)
  })

  let aux = addItemEvery(((_.isArray(n)) ? n.slice(0, min).join('') : n), '-', splice)
  return aux.split('-').join('')
  // }
}

export const factdiv = (num, den) => {
  if (den > num) {
    return 0
  } else {
    var t = 1
    while (num > den) {
      t *= num--
    }
    return t
  }
}

export const Perm = (n, r) => {
  return factdiv(n, (n - r))
}

export const Fact = (x) => {
  if ((x >= 180) || (x !== Math.round(x))) {
    return -1
  }
  let t = 1
  while (x > 1) {
    t *= x--
  }
  return t
}

export const LnPerm = (n, k) => {
  if ((k > n) || (k <= 0)) {
    return 0
  } else {
    return (LnFact(n) - LnFact(n - k))
  }
}

export const LnFact = (x) => {
  if (x <= 1) x = 1
  if (x < 12) {
    return Math.log(Fact(Math.round(x)))
  } else {
    let invx = 1 / x
    let invx2 = invx * invx
    let invx3 = invx2 * invx
    let invx5 = invx3 * invx2
    let invx7 = invx5 * invx2

    let sum = ((x + 0.5) * Math.log(x)) - x
    sum += Math.log(2 * Math.PI) / 2
    sum += (invx / 12) - (invx3 / 360)
    sum += (invx5 / 1260) - (invx7 / 1680)
    return sum
  }
}

export const permutator = (inputArr) => {
  let result = []
  const permute = (arr, m = []) => {
    if (arr.length === 0) {
      result.push(m)
    } else {
      for (let i = 0; i < arr.length; i++) {
        let curr = arr.slice()
        let next = curr.splice(i, 1)
        permute(curr.slice(), m.concat(next))
      }
    }
  }
  permute(inputArr)
  return result
}

export const addItemEvery = function (str, item, every) {
  let _s = str
  for (let i = 0; i < _s.length; i++) {
    if (!(i % (every + 1))) {
      _s = _s.substring(0, i) + item + _s.substring(i)
    }
  }
  return _s.substring(1)
}

export const pad = function (array, length, replacer) {
  return padArray(array, length, replacer)
}

export const zeroPad = function (n) {
  return _.pad(String(n), 2, '0')
  // return n // _.str.pad(n, 2, '0')
}

export const premiosFormat = (e) => {
  let format = _.map(e, item => {
    if (typeof (item) === 'string') {
      if (String(item).length > 1) {
        return `${String(item).charAt(0)} ao ${String(item).charAt(1)}`
      }
      return item
    } else {
      return item
    }
  })
  return format
}

export const formatValue = (value) => {
  if (value < 10) {
    return `0${value}`
  }
  return value
}

/* Exibe mensagens de alertas */
export const _alert = (title = 'Aguarde ...', type = 'success', background = '', color = '', timer = 5000) => {
  const toasted = type !== 'error'
  return new Alert({
    title: `${title}`,
    customClass: 'animated fadeInUp',
    icon: type,
    showConfirmButton: false,
    showCloseButton: true,
    timer: timer,
    background: background,
    color: color,
    toast: toasted,
    position: toasted ? 'top' : ''
  })
}

export const useDetectOutsideClick = (component, callback) => {
    if (!component) return
    const listener = (event) => {
        if (event.target !== component.value && event.composedPath().includes(component.value)) {
            return
        }
        if (typeof callback === 'function') {
            callback()
        }
    }
    onMounted(() => { window.addEventListener('click', listener) })
    onBeforeUnmount(() => {window.removeEventListener('click', listener)})

    return {listener}
}

/* ativa modo impreção */
export const imprimir = (w) => {
  return w.print()
}

export const formatText = (val, separador = '') => {
  return _.isEmpty(val) ? '' : val + separador
}

export const formatHora = (val) => {
  let a = val.split(':')
  const format = _.map(a, (v, k) => formatValue(v))
  return format.join(':')
}
/* Retorna atual linguagem selecionada */
export const lang = (scope) => {
  let lang = scope.$language.current.split('_')
  switch (lang[0]) {
    case 'en':
      return 'en'
    case 'pt':
      return 'pt-br'
    case 'es':
      return 'es'
  }
  return scope.$language.current.toLowerCase()
}

export const configCartelas = (id) => {
  switch (id) {
    case 19:
      return {
        layout: {
          break: 5,
          buttons: 10,
          keyboard: true,
          multi: true,
          split: 1,
          startIndex: 0,
          base: true
        }
      }
    case 21:
    case 25:
    case 14:
    case 16:
    case 13:
      return {
        layout: {
          base: true
        }
      }
  }
}

export const permutation = function * heaps (arr, n) {
  if (n === undefined) n = arr.length
  if (n <= 1) yield arr
  else {
    for (let i = 0; i < n - 1; i++) {
      yield * heaps(arr, n - 1)
      if (n % 2 === 0) {
        // eslint-disable-next-line no-unused-vars
        [arr[(n - 1)], arr[i]] = [arr[i], arr[n - 1]]
      } else {
        // eslint-disable-next-line no-unused-vars
        [arr[(n - 1)], arr[0]] = [arr[0], arr[n - 1]]
      }
    }
    yield * heaps(arr, n - 1)
  }
}

/* formata palpites  */
export const formataPalpites = (val = null, modalidade = null) => {
  if (val === null || modalidade === null) return ''
  switch (modalidade) {
    // Pick Show
    case 'e':
      return val.join(`,`).replace(/,/g, ` - `)
      // Terno de grupo
    case 'V':
    case 'O':
    case 'M': // Milhar
    case 'C': // Centena
    case 'Z': // Dezena
    case 'D': // Dupla de Dezena
    case 'T': // Terno de Dezena
    case 'A': // Grupo
    case 'B': // Dupla de Grupo
    case 'S': // Passe
    case 'Q':
    case '3':
    case '4':
    case '5':
    case '7':
      return val.join().replace(/-/g, `-`).replace(/,/g, `, `)
    case 'N': // Milhar Combinada
    case 'F': // Centena Combinada
      return val.join(`,`).replace(/,/g, ` , `)
    default:
      return { val: val, mod: modalidade }
  }
}
export const nameModalidade = (val = null, modalidade = null) => {
  if (val === null || modalidade === null) return ''
  let format = null
  let mapStr = null
  switch (modalidade) {
    case 'M': // Milhar
    case 'C': // Centena
    case 'N': // Milhar Combinada
    case 'F': // Centena Combinada
      format = String(val)
      return format.replace(/,/g, `, `)
    // Pick Show
    case 'e':
      format = String(val).replace(/,/g, '')
      mapStr = String(format).match(new RegExp('.{' + 2 + '}', 'g'))
      return mapStr.join('-')
      // Terno de grupo
    case 'V':
    case 'Q':
    case '3':
    case '4':
    case '5':
      // format = String(val).replace(/,/g, '')
      // mapStr = String(format).match(new RegExp('.{' + 6 + '}', 'g'))
      // _.each(mapStr, (v, k) => {
      //   mapStr[k] = String(v).match(new RegExp('.{' + 2 + '}', 'g'))
      // })
      // return mapStr.join('*').replace(/,/g, '-').replace(/\*/g, `, `)
      format = String(val).split(',')
      _.each(format, (v, k) => {
        format[k] = String(v).match(new RegExp('.{2}', 'g')).join('-')
      })
      // mapStr = format.match(new RegExp('.{2}', 'g')).join('-')
      return format.join('*').replace(/,/g, '-').replace(/\*/g, `, `)
    case 'T': // Terno de Dezena
      format = String(val).replace(/,/g, '')
      mapStr = String(format).match(new RegExp('.{' + 6 + '}', 'g'))
      _.each(mapStr, (v, k) => {
        mapStr[k] = String(v).match(new RegExp('.{' + 2 + '}', 'g'))
      })
      return mapStr.join('*').replace(/,/g, '-').replace(/\*/g, `, `)
    case 'Z': // Dezena
    case 'A': // Grupo
      format = String(val).replace(/,/g, '')
      mapStr = String(format).match(new RegExp('.{' + 2 + '}', 'g'))
      return mapStr.join(',').replace(/,/g, `, `)
    case 'D': // Dupla de Dezena
    case 'B': // Dupla de Grupo
    case 'S': // PASSE

      format = String(val).replace(/,/g, '')
      mapStr = String(format).match(new RegExp('.{' + 4 + '}', 'g'))
      _.each(mapStr, (v, k) => {
        mapStr[k] = String(v).match(new RegExp('.{' + 2 + '}', 'g'))
      })
      return mapStr.join('*').replace(/,/g, '-').replace(/\*/g, `, `)
    case '7': // QUINA PREMIADA
      return val.split(',').map(it => it.match(new RegExp('.{1,2}', 'g')).join('-')).join(', ')
    // case 'N': // Milhar Combinada
    //   format = String(val).replace(/,/g, '')
    //   mapStr = String(format).match(new RegExp('.{' + 4 + '}', 'g'))
    //   _.each(mapStr, (v, k) => {
    //     mapStr[k] = String(v).match(new RegExp('.{' + 4 + '}', 'g'))
    //   })
    //   return mapStr.join('*').replace(/,/g, '-').replace(/\*/g, `, `)
    // case 'F': // Centena Combinada
    //   format = String(val).replace(/,/g, '')
    //   mapStr = String(format).match(new RegExp('.{' + 3 + '}', 'g'))
    //   _.each(mapStr, (v, k) => {
    //     mapStr[k] = String(v).match(new RegExp('.{' + 3 + '}', 'g'))
    //   })
    //   return mapStr.join('*').replace(/,/g, '-').replace(/\*/g, `, `)
    default:
      return { val: val, mod: modalidade }
  }
}

/* gera horarios pick Show */
export const geraHorarios = (locale, dtSistema) => {
  let init = moment(dtSistema, 'DD/MM/YYYY').hours(23).minutes(59).second(59).add(-1, 'days')
  let fill = _.range(0, 96)
  let map = _.map(fill, (v) => {
    init = moment(init).add(30, 'minutes')
    return init.toDate()
  })
  map = _.sortBy(map)
  return map
}

export const parametrosModalComprovante = (val, valueTotal, carrinho, enviado) => {
  let grouped = _.groupBy(carrinho, (o) => {
    if (o.idLoteria === 14) {
      return `${o.data}{-}${o.idLoteria}{-}${o.agrupa}`
    }
    return `${moment(o.data, 'DD/MM/YYYY H:mm:ss').format('DD/MM/YYYY')}{-}${o.idLoteria}`
  })

  let count = 0 // contador para cada item
  let globalIndex = 0
  let carrinhoMap = _.map(grouped, (item, k) => {
    let dadosBanco = val.B[globalIndex] // dados do retorno do bd
    let dadosAposta = Object.assign({}, val) // copia dados e deleta o que nao é necessario
    delete dadosAposta.BILHETES
    return {
      ...dadosAposta,
      total: valueTotal,
      jogos: ((bd, dados, $i) => {
        let carrinho = _.map(dados, (v, k) => {
          /* Retorna super 6 */
          let start = count === 0 ? 0 : count
          if (v.idProduto === 21 && v.repeticao) {
            let geracaoTeimosinha = _.range(start, v.repeticao + count) // se count > 0 soma com a quantidade de repetição
            count += v.repeticao
            // Gera repetição do produto 21 - super 6
            v.teimosinha = _.map(geracaoTeimosinha, (index, item, arr) => {
              return {
                idProduto: v.idProduto,
                dataConcurso: moment(bd.D, 'YYMMDDHHmmss').add((item === 0 ? 1 : item + 1) * 30, 'minutes').format('DD/MM/YYYY HH:mm:ss'),
                modalidade: v.modalidade,
                valor: v.valor,
                total: v.total,
                pos: k,
                final: arr.length + ((k === 0) ? 1 : k),
                quina: val.B[index]
              }
            })
          } else {
            let response = {
              loteria: v.loteria,
              idProduto: v.idProduto,
              dataConcurso: moment(bd.D, 'YYMMDDHHmmss'),
              modalidade: v.modalidade,
              valor: v.total,
              pos: count,
              ...val.B[count]
            }
            return response
          }
          /* Fim super6 */
          return {...v}
        })
        // eslint-disable-next-line
        if (dados[$i] && dados[$i].idLoteria !== 14) count++ // compara por idloteria
        return carrinho
      })(dadosBanco, item, globalIndex) // item = varios jogos de uma mesma loteria || dadosBando = retorno do ws
    }
  })
  return carrinhoMap
}

export const formatNumeral = (val, locale) => {
  const moneyFormat = '$ 0.00[,]00'

  const localeFormat = String(locale).replace(`_`, `-`).toLowerCase()
  return numeral(val).format(moneyFormat)
}

export const imageProdutoModalidade = (v) => {
  switch (v) {
    // tradicional
    case 'C':
    case 'M':
    case 'F': // centena invertida
    case 'MI': // milhar invertida
    case 'MC': // milhar e centena
    case 'MCI': // milhar e centena invertida
    case 'D': // milhar e centena invertida
    case 'DD':
      return 19
    // super 25
    case 'TG': // terno de grupo
    case 'G': // grupo
    case 'DG': // dupla de grupo
    case 'P': // passe
    case 'PI': // passe invertido
      return 25
    // super 5
    case 'S6':
      return 21
    case 'S5':
    case 'S7':
    case 'S8':
      return 13
    case 'SQL':
      return 14
    default:
      return 19
  }
}

export default {
  /* retorna id Carla name  */
  getCartela: (key) => {
    let id = key
    switch (key) {
      case 19:
        id = 'BILHETE_TRADICIONAL'
        return id
      case 20:
        id = 'SUPER_26'
        return id
    }
  }
}

export const cartelasGeradas = (state, {qtd, bonus = false}, pos = true) => {
  return new Promise((resolve, reject) => {
    if (qtd === 0) return []
    const select = []
    const Geralinha = (start, end, qtd) => {
      let intervalor = range(start, end)
      let itens = sampleSize(intervalor, qtd)
      return map(itens, v => String(v).padStart(2, '0'))
    }

    // quantidade a serem formadas
    let lista = range(0, qtd)

    // intervalo em cada geração
    let numerosMax = state.max / state.linhas
    let colunas = range(0, state.colunas)
    let itens = map(lista, (v, k) => {
      let linha = ''
      // gera colunas
      let count = numerosMax
      return map(colunas, el => {
        if (el === 0) {
          // gera 1 linha da cartela 1 porque nao pode ter o numero 0
          linha = Geralinha(1, count + 1, state.linhas)

          select.push(`${pos === true ? v : pos}#${sampleSize(linha, 1)[0]}`)
          return linha
        }
        // gera outras linhas
        let result = Geralinha(count + 1, count + numerosMax + 1, state.linhas)

        // Gera bola bonus baseado na config do concurso
        if (el === 2 && bonus) {
          result[2] = '00'
        }
        select.push(`${pos === true ? v : pos}#${sampleSize(result, 1)[0]}`)
        count = count + numerosMax
        return result
      })
    })
    console.log('itens', itens)
    let transposta = map(itens, v => zip(...v))
    itens = map(transposta, (v, k) => {
      return { key: pos === true ? k : pos, val: v, qtd: 0 }
    })
    return resolve({ itens, select })
  })
}

export const fomartCatelas = (palpites) =>
    palpites.map(palpite => palpite.replaceAll('-', '').match(new RegExp('.{10}', 'g')).map(v => v.match(new RegExp('.{2}', 'g'))))

export const cores = (v, getter) => {
  if (v === 'default') return getter[6]
  if (v <= 18) return getter[0]
  if (v > 18 && v <= 36) return getter[1]
  if (v > 36 && v <= 54) return getter[3]
  if (v > 54 && v <= 72) return getter[2]
  if (v > 72 <= 90) return getter[4]
}

export const coresLetras = (v, getter) => {
  if (v === 'default') return getter[6]
  if (v <= 18) return getter[0]
  if (v > 18 && v <= 36) return getter[1]
  if (v > 36 && v <= 54) return getter[2]
  if (v > 54 && v <= 72) return getter[3]
  if (v > 72 <= 90) return getter[4]
}
export const base36 = (number) => {
  const base36map = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let parsedNumber = number
  if (typeof number === 'string') {
    parsedNumber = parseInt(number, 10)
  }

  if (typeof parsedNumber !== 'number') {
    throw new TypeError('base36encode: parameter must be a positive integer or a string that can be parsed to one')
  }
  if (parsedNumber < 0) {
    throw new Error('base36encode: parameter must be a positive integer or a string that can be parsed to one')
  }

  const elements = []
  let value = parsedNumber

  while (value > 0) {
    elements.push(base36map[value % 36])
    value = Math.floor(value / 36)
  }

  return elements.reverse().join('')
}

export const convertToSlug = (text) => {
  const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
  const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
  const p = new RegExp(a.split('').join('|'), 'g')
  return text.toString().toLowerCase().trim()
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[\s\W-]+/g, '-') // Replace spaces, non-word characters and dashes with a single dash (-)
}

export const formatResult = (dados) => {
  return map(dados, v => {
    return compact(map(v.prizes, it => {
      if (it.max) {
        const iten = String(it.max).split('- ')
        it.cod = iten[0] || {}
        it.lottery = v.cod
        if (iten[1]) {
          it.numberFormat = String(iten[1]).trim().split('-') || {}
        } else {
          const resultado = iten[0].split(' ')
          it.cod = resultado.length > 2 ? resultado[0] + ' ' + resultado[1] : resultado[0]
          it.numberFormat = String(resultado.length > 2 ? resultado[2] : resultado[1]).trim().split('-') || {}
        }

        it.maxResult = true
        return it
      }
      it.maxResult = false
      it.lottery = v.cod
      it.numberFormat = String(it.number).trim().split('')
      it.groupFormat = String(it.group).trim().split(' ')
      return it.cod === 'SOMA' ? null : it
    }))
  })
}

export const formatResultInst = (dados) => {
  const [resultados] = dados
  const resultadoNumber = resultados && map(resultados.filter(it => it.data.number), v => {
      v.maxResult = false
      v.numberFormat = String(v.data.number).trim().split('')
      v.groupFormat = String(v.group)
      return v
  })

  const resultadoMax = resultados && map(resultados.filter(it => it.data.max), v => {
      v.maxResult = false
      v.numberFormat = String(v.data.max).match(/.{1,2}/g)
      v.groupFormat = String(v.group)
      return v
    })

    return [resultadoNumber, resultadoMax]
  }

  export const isValidCnpj = value => {
    let cnpj = value.replace(/[^\d]+/g,'');

    if(cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
          return false;

    return true;
}

export const isValidCpf = value => {
    if (typeof value !== "string") return false

    const cpf = value.replace(/[\s.-]*/igm, '')
    var soma = 0
    var resto = 0

    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
    ) {
        return false
    }


    for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)

    resto = (soma * 10) % 11

    if ((resto == 10) || (resto == 11))  resto = 0

    if (resto != parseInt(cpf.substring(9, 10)) ) return false

    soma = 0

    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)

    resto = (soma * 10) % 11

    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false

    return true
}

export const isValidCpfOrCnpj = (value) => {
    if (!value) return false
    return isValidCpf(value) || isValidCnpj(value)
}
export const saveDadosStorage = (v, atual) => {
  if (!v.modalidade || !v.modalidade.ID_MODAL) return
  session.setObject(`${atual}#${v.modalidade.ID_MODAL}`, v)
}

export const formatPremioModalidade = (v, { IN_PREMIO_SAO_DEZENAS }) => {
  if (IN_PREMIO_SAO_DEZENAS) return { text: v, id: v , dezena: true}

  let result = ''
  const text = String(v).split('')
  if (size(text) !== 1) result = map(text, it => `${it}º`).join('-')
  if (size(text) === 1) result = `${text[0]}º`
  return { text: result, id: v }
}

export const isMobile = () => {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

const formatPremios = (premio, modalidade, palpites = []) => {
  if (!palpites && !palpites[0]) return 'Não encontrado prêmio'
  const text = formatPremioModalidade(premio, modalidade)
  if (text.dezena) return String(palpites[0].val).split('-').length
  return text.id
}

export const possivelGanho = (game) => {  
  let premio = formatPremios(game.formatPremios.id, game.Modalidade, game.palpites)
  const { dezena } = formatPremioModalidade(premio, game.Modalidade)
  if (dezena) premio = parseInt(game.formatPremios.id)
  const cotacoes = map(game.Modalidade.AR_COTACAO, (item) => ({ ...item, VL_PREMIO: parseFloat(item.VL_PREMIO) }))
  const arrCotacoes = cotacoes.filter(it => it.IN_PREMIO === parseInt(premio))
  const cotacao = arrCotacoes.length ? maxBy(arrCotacoes, 'VL_PREMIO') : maxBy(cotacoes, 'VL_PREMIO')

  const premios = game.Modalidade.CD_PREM
  const qtdPalpites = game.palpites.length
  const isDividir = premios.some(item => size(String(item)) === 1)
  let qtdPremios = 1
  if (!dezena) {
    const [num1, num2] = String(game.formatPremios.id).split('')
    const qtd = String(game.formatPremios.id).length >= 2 ? range(parseInt(num1), parseInt(num2) + 1).length : 1
    qtdPremios = isDividir ? qtd : 1
  }

  const valor = ((Number(game.value) / qtdPalpites) / qtdPremios) * (cotacao ? cotacao.VL_PREMIO : game.Modalidade.COTACAO)
  game.ganho = valor
  return Math.floor(valor * 100) / 100
}