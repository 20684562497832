import http from '@/core/http'
import _config from '@/core/config'
const state = {
  dadosCPF: {},
}

const actions = {
  cadastrar: async ({ commit }, payload) => {
    const result = await http.post(`${_config.API}cadastro/user-create`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },

  savePreCadastro: async ({ commit }, payload) => {
    const result = await http.post(`${_config.API}cadastro/save-pre-registration`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },

  cadastrarAfiliado: async ({ commit }, payload) => {
    const result = await http.post(`${_config.API}cadastro/affiliate-create`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  userAfliadoExistente: async ({ commit }, payload) => {
    const result = await http.post(`${_config.API}cadastro/affiliate-create-existente`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },

  verificaCPF: async ({ commit }, payload) => {
    const result = await http.get('cadastro/busca-dados-cpf', payload)
    if (!result.data.success) throw new Error(result.data.message)
    commit('SET_DADOS_CPF', result.data.data)
    return Promise.resolve(result.data.data)
  },

  verificaBirth: async ({ commit }, payload) => {
    const result = await http.get('validacao-birth-user-web', payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result.data.data)
  },
}

const mutations = {
  'SET_DADOS_CPF' (state, result) {
    state.dadosCPF = result
  }
}

const getters = {
  getDadosCPF: (state) => {
    return state.dadosCPF
  },
}

export default { actions, mutations, state, getters, namespaced: true }
