export default {
  get: (key) => {
    return (localStorage[key]) ? JSON.parse(localStorage[key]) : null
  },
  set: (key, value = null) => {
    localStorage[key] = JSON.stringify(value)
    return localStorage[key]
  },
  getObject: (key) => {
    return JSON.parse(localStorage[key] || null)
  },
  setObject: (key, value) => {
    localStorage[key] = JSON.stringify(value)
    return localStorage[key]
  },
  remove: (key) => {
    return localStorage.removeItem(key) || false
  }
}
