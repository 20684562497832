// import modalidadeQUINZAO from './modalidades/mQUINZAO'
export default {
  videoModalidade: true,
  tableBicho: [],
  titulo: '15zão',
  active: true,
  listMolidades: true,
  isModalidadeCotacaoUnica: true, /* mostra modalidade mas apenas 1 */
  dadosCotacao: {
    text: 'Quinzão'
  },
  sub: '',

  /* 2 */
  sub2: 'Como apostar',
  sub21: `
    <div class="mb-2">
      O Quinzão concorre com o resultado da Lotofácil da Loteria Federal em todos os seus concursos. Em uma única ocorrência são extraídas aleatoriamente quinze dezenas de 01 a 25. O apostador escolhe se quer jogar 17,18,19,20,21,22 ou 23 Grupos. Durante uma única ocorrência, são extraídas aleatoriamente 15 dezenas, variando de 01 a 25. Você escolhe, no mínimo 17 e no máximo 23 dezenas e precisa acertar 15 dezenas para levar o prêmio. A cotação é de acordo com a quantidade de números jogados e a quantidade de acertos conforme a tabela abaixo:
    </div>

    <div class="text-pontilhados">
      <span> 17 Dezenas </span>
      <span class="mx-1"></span>
      <span>   10.000x </span>
    </div>

    <div class="text-pontilhados">
      <span> 18 Dezenas </span>
      <span class="mx-1"></span>
      <span>   2.100x </span>
    </div>

    <div class="text-pontilhados">
      <span> 19 Dezenas </span>
      <span class="mx-1"></span>
      <span>   450x </span>
    </div>

    <div class="text-pontilhados">
      <span> 20 Dezenas </span>
      <span class="mx-1"></span>
      <span>   110x </span>
    </div>

    <div class="text-pontilhados">
      <span> 21 Dezenas </span>
      <span class="mx-1"></span>
      <span>   35x </span>
    </div>

    <div class="text-pontilhados">
      <span> 22 Dezenas </span>
      <span class="mx-1"></span>
      <span>   10x </span>
    </div>

    <div class="text-pontilhados mb-3">
      <span> 23 Dezenas </span>
      <span class="mx-1"></span>
      <span>   4x </span>
    </div>

    <div class="mb-2"> Para jogar no Quinzão, siga os passos abaixo: </div>

    <div class="mb-2"> Marque a quantidade de dezenas que você quer jogar entre 17 e 23 números. </div>


    <ul class="lista">
      <li>
        Comece a marcar os seus números até completar a quantidade que que você escolheu, ou, se preferir, dê um clique no botão laranja para gerar uma aposta aleatória;
      </li>

      <li>
        Repita o processo para apostar em quantos palpites desejar; <br/>
        Se você errar ao escolher um número e quiser substituí-lo, clique sobre ele novamente para desmarcar. Se a aposta já foi inserida, para apagar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites; <br/>
        Ao realizar várias apostas, elas são empilhadas e só as últimas ficam visíveis no painel. As demais vão aparecer no bilhete ao final da aposta.
      </li>

      <li>
        Clique em "Continuar";
      </li>

      <li>
        Informe o valor que deseja apostar; <br/>
        A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: “+ Apostas” e “Finalizar”;
      </li>
      <li>
        Clicando em fazer mais apostas você volta para a tela inicial do Quinzão, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo;
      </li>

      <li>
        Clicando em finalizar você volta para a página principal do BICHO NO PIX, onde poderá apostar em outros produtos;
      </li>

    </ul>

  `,
  // ...modalidadeQUINZAO
}
