import { validateResultados } from '@/core/middleware/validateResultados'

export default {
  path: '/resultados/:name*',
  name: 'resultados',
  component: () => import(/* webpackChunkName: "resultado" */'./index'),
  meta: {
    title: 'Resultado do Jogo do Bicho Hoje  | BICHO NO PIX',
    description: 'Veja os resultados atualizados do jogo do bicho no BICHO NO PIX e confira se você ganhou. Resultados diários disponíveis para você conferir agora!',
    ogDescription: 'Veja os resultados atualizados do jogo do bicho no BICHO NO PIX e confira se você ganhou. Resultados diários disponíveis para você conferir agora!',
    canonical: window.location.href,
  },
  beforeEnter: async (to, from, next) => {
    await validateResultados({ to, from, next })
  }
}
