import http from '@/core/http'
// import { filter, groupBy, map, orderBy, size } from 'lodash'
const state = {
  minhasApostasRecents: [],
  minhasApostas: [],
  minhasApostasIndexados: [],
  meusBonus: {},
  concursosBonus: [],
  resultado: null,
  resultadoInst: null,
  resultadoBonus: null,
  totalGames: 0,
  totalIndexados: 0,
  lastResultBonus: [],
  c: 0,
  loader: false,
  minhasApostasCassinoRecents: [],
  minhasApostasCassino: [],
  hasPlacedBet: null
}

const actions = {
  async getMinhasApostas ({ commit }, payload) {
    commit('CHANGE_LOADER', true)
    const result = await http.post('extrato/list-games', { ...payload })
    commit('CHANGE_LOADER', false)
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_MINHAS_APOSTAS', result.data)
    return Promise.resolve(result.data)
  },
  async checkUserPrognosticBet ({ commit }, payload) {
    const result = await http.get('extrato/check-user-bet', payload)
    if (!result.data.data) throw Error(result.data.message)
    commit('SET_HAS_PLACED_BET', result.data.data)
    return Promise.resolve(result.data)
  },
  async loaderMinhasApostas ({ commit, state }, payload) {
    commit('CHANGE_LOADER', !state.loader)
  },

  async getMinhasApostasCassinos ({ commit }, payload) {
    commit('CHANGE_LOADER', true)
    const result = await http.post('extrato/list-cassino-games', { ...payload })
    commit('CHANGE_LOADER', false)
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_MINHAS_APOSTAS_CASSINOS', result.data)
    return Promise.resolve(result.data)
  },

  async getMinhasApostasIndexados ({ commit }, payload) {
    const result = await http.post('extrato/games-indexados', { ...payload, pageSize: 10 })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_MINHAS_APOSTAS_INDEXADOS', result.data)
    return Promise.resolve(result.data)
  },

  async getResultados ({ commit }, payload) {
    const result = await http.get('resultados/prognosticos', { params: { ...payload } })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_RESULTADO', result.data.data.result)
    return Promise.resolve(result.data)
  },

  async getResultadosInst ({ commit }, payload) {
    const result = await http.get('resultados/prognosticos-inst', { params: { ...payload } })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_RESULTADO_INST', result.data.data.result)
    return Promise.resolve(result.data)
  },

  async obterConcursosBonus ({ commit }, payload) {
    const result = await http.get('extrato/concursos')
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_LISTA_CONCURSOS_BONUS', result.data)
    return Promise.resolve(result.data)
  },

  async getMeusBonus ({ commit }, payload) {
    commit('RESET_LISTA_BONUS')
    const result = await http.get('extrato/cupons', { params: { ...payload } })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_LISTA_BONUS', result.data)
    return Promise.resolve(result.data)
  },

  async limparListaBonus ({ commit }, payload) {
    commit('RESET_LISTA_BONUS')
  },

  async getResultadosBonus ({ commit }, payload) {
    const result = await http.get('resultados/bonus', { params: { ...payload } })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_RESULTADO_BONUS', result.data.data.result)
    return Promise.resolve(result.data)
  },

  async getUltimoResultadosBonus ({ commit }, payload) { // pega o ultimo resultado do bonus
    const result = await http.get('resultados/bonus', { params: { ...payload } })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_ULTIMO_RESULTADO_BONUS', result.data.data.result)
    return Promise.resolve(result.data.data.result)
  },

  async resetResultados ({ commit }) {
    commit('RESET_RESULTADO')
  },

  async limparResultadosBonus ({ commit }) {
    commit('RESET_RESULTADO_BONUS')
  },
}

const mutations = {
  'SET_MINHAS_APOSTAS' (state, payload) {
    state.minhasApostasRecents = (payload.data || []).filter(it => it.isRecent)
    state.minhasApostas = (payload.data || []).filter(it => !it.isRecent)
    state.totalGames = payload.total
  },

  'SET_MINHAS_APOSTAS_CASSINOS' (state, payload) {
    state.minhasApostasCassinoRecents = (payload.data || []).filter(it => it.isRecent)
    state.minhasApostasCassino = (payload.data || []).filter(it => !it.isRecent)
    // state.totalGames = payload.total
  },

  'SET_MINHAS_APOSTAS_INDEXADOS' (state, payload) {
    state.minhasApostasIndexados = payload.data
    state.totalIndexados = payload.total || 1
  },

  'SET_LISTA_CONCURSOS_BONUS' (state, payload) {
    state.concursosBonus = payload.data
  },

  'SET_LISTA_BONUS' (state, payload) {
    state.meusBonus = payload.data
    state.totalMeusBonus = payload.total || 1
  },

  'CHANGE_LOADER' (state, payload) {
    state.loader = payload
  },

  'SET_HAS_PLACED_BET' (state, payload) {
    state.hasPlacedBet = payload.data
  },

  'SET_RESULTADO' (state, payload) {
    state.resultado = payload
  },

  'SET_RESULTADO_INST' (state, payload) {
    state.resultadoInst = payload
  },

  'RESET_RESULTADO' (state, payload) {
    state.resultado = null
    state.resultadoInst = null
  },

  'RESET_LISTA_BONUS' (state, payload) {
    state.meusBonus = {}
    state.totalMeusBonus = 0
  },

  'SET_RESULTADO_BONUS' (state, payload) {
    state.resultadoBonus = payload
  },

  'SET_ULTIMO_RESULTADO_BONUS' (state, payload) {
    state.lastResultBonus = payload
  },

  'RESET_RESULTADO_BONUS' (state, payload) {
    state.resultadoBonus = null
  },
}

const getters = {
  minhasApostas: (state) => state.minhasApostas,
  minhasApostasRecents: (state) => state.minhasApostasRecents,
  minhasApostasPage: (state) => (page, total) => {
    const dados = state.minhasApostas
    const paginacao = dados.slice(page * total - total, page * total)
    return paginacao
  },
  minhasApostasIndexados: (state) => state.minhasApostasIndexados,
  totalGames: (state) => state.totalGames,
  totalIndexados: (state) => state.totalIndexados,
  loader: (state) => state.loader,
  resultado: (state) => state.resultado,
  resultadoInst: (state) => state.resultadoInst,
  meusBonus: (state) => state.meusBonus,
  totalMeusBonus: (state) => state.totalMeusBonus,
  resultadoBonus: (state) => state.resultadoBonus,
  concursosBonus: (state) => state.concursosBonus,
  ultimoResultadoBonus: state => state.lastResultBonus,
  minhasApostasCassinoRecents: state => state.minhasApostasCassinoRecents,
  minhasApostasCassino: state => state.minhasApostasCassino,
  getHasPlacedBet: state => state.hasPlacedBet,
}

export default { state, actions, mutations, getters, namespaced: true }
