export default {
  MILHAR: {
    videoModalidade: true,
    titulo: 'Milhar',
    sub: 'Como funciona',

    // titulo: 'Para começar a apostar, é muito fácil',
    // sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,

    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a MILHAR e iremos concorrer 5.000 vezes o valor apostado.',
    imgAjuda2: true,

    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, é hora de escolher em quais prêmios deseja concorrer. Você pode participar separadamente no 1º, 2º, 3º, 4º, 5º ou 1º ao 5º prêmio. Para obter premiação máxima o ganhador terá que acertar do 1° ao 5° prêmio.',
    imgAjuda3: true,

    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso da MILHAR você apostar escolhendo 4 números de 0 a 9.',
    imgAjuda4: true,

    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar a sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,

    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio.',
    imgAjuda6: true,
    button6: true
  },

  CENTENA: {
    videoModalidade: true,
    titulo: 'Centena',
    sub: 'Como funciona',

    // titulo: 'Para começar a apostar, é muito fácil',
    // sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,

    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a CENTENA e iremos concorrer 600 vezes o valor apostado.',
    imgAjuda2: true,

    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, é hora de escolher em quais prêmios deseja concorrer. Você pode participar separadamente no 1º, 2º, 3º, 4º, 5º ou 1º ao 5º prêmio. Para obter premiação máxima o ganhador terá que acertar do 1° ao 5° prêmio.',
    imgAjuda3: true,

    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso da CENTENA você aposta escolhendo 3 números de 0 a 9.',
    imgAjuda4: true,

    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar a sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,

    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio.',
    imgAjuda6: true,
    button6: true
  },

  DEZENA: {
    videoModalidade: true,
    titulo: 'Dezena',
    sub: 'Como funciona',

    // titulo: 'Para começar a apostar, é muito fácil',
    // sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,

    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a DEZENA e iremos concorrer 60 vezes o valor apostado.',
    imgAjuda2: true,

    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, é hora de escolher em quais prêmios deseja concorrer.Você pode participar separadamente no 1º, 2º, 3º, 4º, 5º ou 1º ao 5º prêmio. Para obter premiação máxima o ganhador terá que acertar do 1° ao 5° prêmio.',
    imgAjuda3: true,

    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso da DEZENA você aposta escolhendo 2 números de 0 a 9.',
    imgAjuda4: true,

    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar a sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,

    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio.',
    imgAjuda6: true,
    button6: true
  },

  DUQUE_DE_DEZENA: {
    videoModalidade: true,
    titulo: 'Duque de Dezena',
    sub: 'Como funciona',

    // titulo: 'Para começar a apostar, é muito fácil',
    // sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,

    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a DUQUE DE DEZENA e iremos concorrer 300 vezes o valor apostado.',
    imgAjuda2: true,

    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, você concorre automaticamente do 1º ao 5º prêmio. Para obter premiação máxima, o ganhador terá que acertar todas dezenas premiadas.',
    imgAjuda3: true,

    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso da DUQUE DE DEZENA você aposta escolhendo de 2 a 10 dezenas de 00 a 99.',
    imgAjuda4: true,

    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar a sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,

    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio.',
    imgAjuda6: true,
    button6: true
  },

  CENTENA_INVERTIDA: {
    videoModalidade: true,
    titulo: 'Centena Invertida',
    sub: 'Como funciona',

    // titulo: 'Para começar a apostar, é muito fácil',
    // sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,

    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a CENTENA INVERTIDA e iremos concorrer 600 vezes o valor apostado.',
    imgAjuda2: true,

    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, é hora de escolher em quais prêmios deseja concorrer. Você pode participar separadamente no 1º, 2º, 3º, 4º, 5º ou 1º ao 5º prêmio. Para obter premiação máxima o ganhador terá que acertar do 1° ao 5° prêmio.',
    imgAjuda3: true,

    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso da CENTENA INVERTIDA você aposta escolhendo de 3 à 10 números de 0 a 9.',
    imgAjuda4: true,

    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar a sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,

    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio.',
    imgAjuda6: true,
    button6: true
  },

  MILHAR_INVERTIDA: {
    videoModalidade: true,
    titulo: 'Milhar Invertida',
    sub: 'Como funciona',

    // titulo: 'Para começar a apostar, é muito fácil',
    // sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,

    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a MILHAR INVERTIDA  iremos concorrer 5.000 vezes o valor apostado.',
    imgAjuda2: true,

    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, é hora de escolher em quais prêmios deseja concorrer. Você pode participar separadamente no 1º, 2º, 3º, 4º, 5º ou 1º ao 5º prêmio. Para obter premiação máxima o ganhador terá que acertar do 1° ao 5° prêmio.',
    imgAjuda3: true,

    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso da MILHAR INVERTIDA você aposta escolhendo de 4 a 10 números de 0 a 9.',
    imgAjuda4: true,

    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,

    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio',
    imgAjuda6: true,
    button6: true
  },

  TERNO_DE_DEZENA: {
    videoModalidade: true,
    titulo: 'Terno de Dezena',
    sub: 'Como funciona',

    // titulo: 'Para começar a apostar, é muito fácil',
    // sub: '1- Escolha a sua loteria',
    sub1: 'Você pode apostar em quantas loterias preferir dentre as disponíveis no dia. Os horários de sorteio ocorrem para Vertical Loto às 11:20, 14:20, 16:20, 18:20, 21:20 e FEDERAL toda quarta-feira e sábado.',
    imgAjuda: true,

    /* 2 */
    sub2: '2- Escolha a modalidade',
    sub21: 'Você pode escolher 01 modalidade para apostar. Cada modalidade possui cotações específicas e condições exclusivas para apostar. Nesse exemplo utilizamos a TERNO DE DEZENA e iremos concorrer 5.000 vezes o valor apostado.',
    imgAjuda2: true,

    /* 3 */
    sub3: '3- Escolha o seu prêmio ',
    sub31: 'Após selecionar sua modalidade, você concorre automaticamente do 1º ao 5º prêmio. Para obter premiação máxima, o ganhador terá que acertar todas dezenas premiadas.',
    imgAjuda3: true,

    /* 4 */
    sub4: '4- Escolha os seus números da sorte',
    sub41: 'Forme o seu palpite escolhendo a quantidade de números necessários de acordo com a modalidade escolhida. No caso do TERNO DE DEZENA você aposta escolhendo de 3 a 10 dezenas de 00 a 99.',
    imgAjuda4: true,

    /* 5 */
    sub5: '5- Insira o valor da aposta',
    sub51: 'Agora é só preencher o campo com o valor que deseja realizar a sua aposta e clicar no botão “APOSTAR”. <br> Valor máximo por palpite R$ 100,00!',
    imgAjuda5: true,

    /* 6 */
    sub6: '6- Concluindo sua aposta',
    sub61: 'Após preencher toda a sua cartela, agora é hora de conferir o seu bilhete, validar a sua aposta e torcer pelo seu prêmio.',
    imgAjuda6: true,
    button6: true
  },
  DUPLA_DE_GRUPO: {
    videoModalidade: true,
    titulo: 'Dupla de Grupo',
    sub: 'Como funciona'
  },
  TERNO_DE_GRUPO: {
    videoModalidade: true,
    titulo: 'Terno de Grupo',
    sub: 'Como funciona'
  },
  GRUPO: {
    videoModalidade: true,
    titulo: 'Grupo',
    sub: 'Como funciona'
  },
  QUADRA: {
    videoModalidade: true,
    titulo: 'Quadra',
    sub: 'Como funciona'
  },
  QUINA: {
    videoModalidade: true,
    titulo: 'Quina',
    sub: 'Como funciona'
  },
  PASSE: {
    videoModalidade: true,
    titulo: 'Passe',
    sub: 'Como funciona'
  },
  Unidade: {
    videoModalidade: true,
    titulo: 'Unidade',
    sub: 'Como funciona'
  },
}
