import http from '@/core/http'

const state = {
  ganhadores: [],
  ganhosPorProdutos: []
}

const actions = {
  async getLastWinners ({ commit }, payload) {
    const result = await http.get('top-winners', { ...payload })
    if (!result.data.success) throw Error(result.data.message)

    commit('SET_LAST_WINNERS', result.data)
    return Promise.resolve(result.data)
  },

  async getWinnersCategories ({ commit }, payload) {
    const result = await http.get('winner-categories')
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_GANHOS_POR_PRODUTOS', result.data)
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'SET_LAST_WINNERS' (state, payload) {
    state.ganhadores = payload.data
  },
  'SET_GANHOS_POR_PRODUTOS' (state, payload) {
    state.ganhosPorProdutos = payload.data
  }
}

const getters = {
  listGanhadores: state => state.ganhadores,
  ganhosPorProdutos: state => state.ganhosPorProdutos
}

export default { state, actions, mutations, getters, namespaced: true }
