<template>
  <div class="spinner-border text-primary" :style="{width: `${width}rem`, height: `${width}rem`, margin: margin }" role="status"></div>
</template>

<script>
export default {
  name: 'defaultSimpleLoader',
  props: {
    width: {
      type: Number,
      default: 2
    },
    margin: {
      type: [String, Boolean],
      default: '0px 7px'
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/styles/variables';

</style>
