import BT from './BT'
import SUP25 from './SUP25'
import SUP5 from './SUP5'
import QB from './QB'
import SENINHA from './SENINHA'
import SURPRESINHA from './SURPRESINHA'
import QUINZAO from './QUINZAO'
import KENODALOOK from './KENODALOOK'
const state = {
  configuracao: { BT, SUP25, SUP5, QB, SENINHA, SURPRESINHA, QUINZAO, KENODALOOK },
  default: {
    titulo: 'Em construção aguarde...!'
  }
}

const mutations = {}

const getters = {}

export default { mutations, state, getters }
