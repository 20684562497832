const jsonConfig = require('./../../package.json')

module.exports = {
  RESTART_INSTANCE: 1,
  keyPair: process.env.VUE_APP_KEY_PAIR,
  URL_AWS: process.env.VUE_APP_URL_AWS || 'https://s3-images-plublic-feira.s3.amazonaws.com',
  // URL: process.env.VUE_APP_URL || 'http://localhost:8080/',
  URL: process.env.VUE_APP_URL || 'http://localhost:8080',
  URL_IBGE: process.env.VUE_APP_URL_IBGE || 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
  API: process.env.VUE_APP_API || 'http://localhost:1000/',
  API_VIA_CEP: process.env.VUE_APP_API_VIA_CEP || 'https://viacep.com.br/ws/',
  KEY_RECAPTCHA_SITE: process.env.VUE_APP_KEY_RECAPTCHA_SITE || '6Lf52Z8mAAAAADI7xK7PcA6EnmMBTrm9m7VKEykF',
  APP_VERSION: jsonConfig.version ? `V-${jsonConfig.version}` : '',
  URL_IFRAME_CARTELAS: process.env.VUE_APP_IFRAME_CARTELAS || 'https://d2ib1sp6rqhejw.cloudfront.net',
  URL_IFRAME_CARTELAS2: process.env.VUE_APP_IFRAME_CARTELAS2 || 'https://d293xryyo2aykl.cloudfront.net',
  URL_IFRAME_GALAXSYS: process.env.VUE_APP_IFRAME_GALAXSYS || 'https://partnerapi-gli.stg-digi.com/GamesLaunch/Launch',
  KBATATINHA: process.env.VUE_APP_KBATATINHA || 'C(I9)RT786TeSe!.^~BGOI()jfiUU(!usy',
  START_GAME: process.env.VUE_APP_START_GAME || '/prognosticos-buy',
  SAVE: process.env.VUE_APP_SAVE_GAME || 'prognosticos-save',
  GAME_OPEN: process.env.VUE_APP_START_GAME_OPEN || 'prognosticos-buy-open'
}
