import modalidesSUP25 from './modalidades/mSUP25'
export default {
  active: true,
  titulo: 'COMO APOSTAR ',
  // sub: 'Ou se preferir, confira o passo a passo para realizar a sua aposta.',
  // sub1: 'Para começar a apostar, é muito fácil!',
  sub12: '1º - ESCOLHA A SUA LOTERIA',
  imgAjuda: true,

  /* 2 */
  sub2: '2º - MODALIDADES E COTAÇÕES x 1',
  sub21: `
    <div class="text-pontilhados">
      <span> GRUPO </span>     
      <span class="mx-1"></span> 
      <span>   18 </span>
    </div>

    <div class="text-pontilhados">
      <span> DUPLA DE GRUPOS </span>      
      <span class="mx-1"></span> 
      <span> 18 (1º ao 5º) ÷ N° de combinações </span>
    </div>

    <div class="text-pontilhados">
      <span> DUPLA DE GRUPOS </span>      
      <span class="mx-1"></span> 
      <span> 160 (1º e 2º) ÷ N° de combinações </span>
    </div>

    <div class="text-pontilhados">
      <span> TERNO DE GRUPOS  </span>      
      <span class="mx-1"></span> 
      <span> 150 (1º ao 5º) ÷ N° de combinações </span>
    </div>

    <div class="text-pontilhados">
      <span> TERNO DE GRUPOS </span>      
      <span class="mx-1"></span> 
      <span> 1.300 (1º ao 3º) ÷ N° de combinações </span>
    </div>

    <div class="text-pontilhados">
      <span> TERNO DE GRUPOS </span>      
      <span class="mx-1"></span> 
      <span> 1.300 (2º ao 4º) ÷ N° de combinações </span>
    </div>

    <div class="text-pontilhados">
      <span> TERNO DE GRUPOS </span>      
      <span class="mx-1"></span> 
      <span> 1.300 (3º ao 5º) ÷ N° de combinações </span>
    </div>

    <div class="text-pontilhados">
      <span> PASSE </span>      
      <span class="mx-1"></span> 
      <span> 90 (1º ao 5º)  </span>
    </div>


    <div class="mt-2 bold">
    • Passe (2 grupos) é o acerto de um grupo no 1º e outro do 2º ao 5º prêmio
    </div>
`,
  imgAjuda2: true,

  /* 3 */
  sub3: '3º - ESCOLHA EM QUAL PRÊMIO APOSTAR',
  sub31: 'Para o Grupo pode se escolher no 1º, 2º, 3º, 4º ou 5º ou do 1º ao 5º prêmio',
  imgAjuda3: true,

  /* 4 */
  sub4: '4º - Escolha os seus número da sorte',
  sub41: `<div class="f12"> Você pode escolher de 01 a 25 grupos na modalidade desejada. <br/>
    • Pode-se repetir os grupos escolhidos </div>.
  `,
  imgAjuda4: true,

  /* 5 */
  sub5: '5º - Insira o valor da aposta',
  sub51: 'Agora é só preencher o campo com o valor que deseja realizar sua aposta e clicar no botão “apostar”.',
  imgAjuda5: true,

  /* 6 */
  sub6: ' 6º - CONCLUINDO SUA APOSTA',
  sub61: 'Confira o seu bilhete e clique no botão “PAGAR”.',
  imgAjuda6: true,
  /*  -----------------------------------------------
    MODALIDADES 
  -----------------------------------------------
  */
  ...modalidesSUP25
  
}
