import http from '@/core/http'
import _config from '@/core/config'
const state = {
  bilhete: null,
}

const actions = {
  cadastrar: async (_, payload) => {
    const result = await http.post(`${_config.API}revenda/cadastrar`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },

  async resetPassword ({ commit }, payload) {
    const result = await http.post('revenda/trocar-senha', payload)
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },

  async getBilhete ({ commit }, payload) {
    const result = await http.get('revenda/buscar-bilhete', { params: payload })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_BILHETE', result.data)
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'SET_BILHETE' (state, payload) {
    state.bilhete = payload.data
  },
}

const getters = {
  bilhete: (state) => state.bilhete 
}

export default { actions, mutations, state, getters, namespaced: true }
