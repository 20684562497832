import http from '@/core/http'
// eslint-disable-next-line
import { map  } from 'lodash'
import { possivelGanho } from '@/core/service/utils'
const state = {
  extrato: [],
  total: 0,
  loader: false,
  extratoComprovante: false
}

const actions = {
  async getExtrato ({ commit }, payload) {
    commit('CHANGE_LOADER', true)
    // const result = await http.post('extrato/credito', payload)
    const result = await http.post('extrato/credito', payload)
    commit('CHANGE_LOADER', false)
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_MINHAS_APOSTAS', result.data)
    return Promise.resolve(result.data)
  },

  getBilheteTransmissions: async ({ commit }, payload) => {
    const aposta = await http.post('extrato/games', payload)
    if (!aposta.data.success) throw new Error(aposta.data.message)

    commit('ADD_COMPROVANTE_EXTRATO', aposta.data.data)
    return Promise.resolve(aposta)
  },

  clearExtrato: async ({ state }, _) => {
    state.extratoComprovante = false
    return Promise.resolve(true)
  },
}

const mutations = {
  'SET_MINHAS_APOSTAS' (state, payload) {
    state.extrato = payload.data
    state.total = payload.total
  },
  'ADD_COMPROVANTE_EXTRATO' (state, payload) {
    state.extratoComprovante = payload
  },

  'CHANGE_LOADER' (state, payload) {
    state.loader = payload
  }
}

const getters = {
  extrato: (state) => state.extrato,
  extratoPage: (state) => (page, total) => {
    const dados = state.extrato
    const paginacao = dados.slice(page * total - total, page * total)
    return paginacao
  },
  total: (state) => state.total,
  loader: (state) => state.loader,
  extratoComprovante: (state, getters, root, root2, rot3) => {
    if (!state.extratoComprovante) return false
    const obj = state.extratoComprovante
    console.log(getters)
    const item = map(obj.games, v => {
      const formatCalculoGanho = { ...v, formatPremios: { id: v.premios.replace(/\D/g, '') }, Modalidade: v.dadosModalidade } 
      const calculo = possivelGanho(formatCalculoGanho)
      return { ...v, ganho: calculo }
    })

    const dados = {
      dataHora: obj.dataHora,
      resultadoInsta: false,
      gameBonus: { format: false },
      total: obj.total,
      games: item,
      // games: [{
      //   dataApostaFormat: obj.dataApostaFormat,
      //   loterias: obj.dados.formatLoterias,
      //   modalidade: obj.dados.modalidade,
      //   palpites: obj.dados.formatPalpites,
      //   palpiteArray: obj.dados.palpites,
      //   premios: obj.dados.formatPremios,
      //   premiosArray: obj.dados.premios,
      //   total: obj.dados.total,
      //   resultadoInsta: false,
      //   produtoId: obj.dados.produtoId
      // }]
    }
    return dados
  }
}

export default { state, actions, mutations, getters, namespaced: true }
