import http from '@/core/http'
import { size } from 'lodash'
// import moment from 'moment'
const state = {
  listaSolicitacoesCreditos: '',
  loader: false,
  filtros: false,
  iterationRev: false,
  hasDeposits: true
}

const actions = {
  async solicitarCreditos ({ commit }, payload) {
    const result = await http.post('creditos/solicitar', { ...payload })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_SOLICITACOES_CREDITOS', result.data.data)
    return Promise.resolve(result.data)
  },

  async getSolicitacoesCreditos ({ commit }, payload) {
    const result = await http.get('creditos/solicitacoes-creditos', { params: { type: payload } })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_SOLICITACOES_CREDITOS', result.data.data)
    return Promise.resolve(result.data)
  },

  async enviarComprovante (_, payload) {
    const result = await http.post('creditos/enviar-comprovante', payload)
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },

  async cancelarSolicitacaoCredito (_, payload) {
    const result = await http.post('creditos/cancelar-solicitacao-credito', payload)
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },

  async ativar (_, payload) {
    const result = await http.post('credits/activate', { ...payload })
    if (!result.data.success) throw Error(result.data.message)
    return Promise.resolve(result.data)
  },
  async iteracoesWebRevendas ({ commit }) {
    const result = await http.get('creditos/iteracoes-revendas')
    if (!result.data.success) throw Error(result.data.message)

    commit('SET_REVENDA_ITERACOES', result.data)
    return Promise.resolve(result.data)
  },

  async getPrimeiroDeposito ({ commit }) {
    const result = await http.get('creditos/tem-primeiro-deposito')
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_HAS_DEPOSITS', result.data.data)
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'SET_SOLICITACOES_CREDITOS' (state, payload) {
    state.listaSolicitacoesCreditos = payload
  },
  'SET_REVENDA_ITERACOES' (state, { data }) {
    state.iterationRev = size(data) ? data : false
  },
  'SET_HAS_DEPOSITS' (state, payload) {
    state.hasDeposits = payload.hasDeposits
  }
}

const getters = {
  listaSolicitacoesCreditos: state => state.listaSolicitacoesCreditos,
  iteracoesRevendas: state => state.iterationRev,
  hasDeposits: state => state.hasDeposits
}

export default { state, actions, mutations, getters, namespaced: true }
