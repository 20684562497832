<template>
  <!-- eslint-disable -->

  <div class="menu" v-if="isAuth">

    <div class="d-flex justify-content-between card" >
        <!-- <div :class="['card-item ']">
          <router-link active-class="active" :to="{ name: ''}" class="v2" :href="whatsappLink" target="_blank">
            <img src="@/assets/icons/ic-suport.svg"/>
            <span class="f10"> Suporte  </span>
          </router-link>
        </div> -->

        <div :class="['card-item']">
          <router-link active-class="active" @click="setProdutoFix" :to="{ name: 'home'}" class="v2">
            <img src="@/assets/icons/ic-apostar.svg"/>
            <span class="f10"> Apostar  </span>
          </router-link>
        </div>

        <div :class="['card-item']">
          <router-link active-class="active" :to="{ name: 'minhasApostas'}" class="v2" >
            <img src="@/assets/icons/minhas_apostas.svg" />
            <span class="f10"> Minhas apostas </span>
          </router-link>
        </div>

        <div :class="['card-item']">
          <router-link active-class="active" :to="{ path: '/resultados/jogo-do-bicho/hoje'}" class="v2">
              <img src="@/assets/icons/resultados-bnp.svg" />
            <span class="f10"> Resultados  </span>
          </router-link>
        </div>

        <!-- <div :class="['card-item ']">
          <router-link active-class="active" :to="{ name: ''}" class="v2" @click="openPromocoes">
            <img src="@/assets/icons/ic-promocoes.svg" />
            <span class="f10"> Promoções  </span>
          </router-link>
        </div> -->

        <!-- <div :class="['card-item ']">
          <router-link active-class="active" :to="{ name: 'cotacoes'}" class="v2" >
            <img src="@/assets/icons/cotacao.svg" />
            <span class="f10"> Cotacões  </span>
          </router-link>
        </div> -->

        <div :class="['card-item ']">
          <router-link active-class="active" :to="{ name: 'esportes'}" class="v2" >
            <img src="@/assets/icons/sports_soccer.svg" />
            <span class="f10"> Esportes  </span>
          </router-link>
        </div>

        <div :class="['card-item ']" v-if="listCarrinho.qtd">
          <router-link  active-class="active" :to="{ name: ''}" @click="openModalCarrinho" class="v2">
            <img src="@/assets/icons/receipt.png" style="width: 17px;height: 35px;"/>
            <!-- <span class="f10"> Cupom  </span> -->
            <span v-if="listCarrinho.qtd > 0" :class="['position-absoluted start-100 translate-middle rounded-pill','badge']">
            <span>{{ listCarrinho.qtd }}</span>
            </span>
          </router-link>
        </div>
      </div>
      <span class="VERSION bold f10 d-block text-center"> {{  vversion  }} </span>

    <!-- Salvar vai que volta né -->
    <!-- <div class="d-flex justify-content-between card" >
      <div :class="['card-item ']">
        <router-link active-class="active" :to="{ name: 'home'}">
          <i class="bi bi-ui-checks-grid f19"> </i>
          <span class="f10"> Apostar  </span>
        </router-link>
      </div>

      <div :class="['card-item ']">
        <router-link active-class="active" :to="{ name: 'resultados'}" class="p-2">
          <template v-if="router.name !== 'resultados'">
            <img src="@/assets/icons/resultado.svg" class="icon-result" />
          </template>
          <template v-else>
            <img src="@/assets/icons/resultado-white.svg" />
          </template>
          <span class="f10"> Resultados  </span>
        </router-link>
      </div>

      <div :class="['card-item ']">
        <router-link active-class="active" :to="{ name: 'indexar.bilhetes'}" class="p-2">
          <i class="bi bi-box-arrow-in-down f19"></i>
          <span class="f10"> Indexar  </span>
        </router-link>
      </div>


      <div :class="['card-item ']">
        <router-link active-class="active"  :to="{ name: 'conferir.bilhetes'}" class="p-2">
          <i class="bi bi-bookmark-check f19"></i>
          <span class="f10"> Conferir  </span>
        </router-link>
      </div>

      <div :class="['card-item ']">
        <router-link  active-class="active" :to="{ name: 'creditos'}" class="p-2">
          <i class="bi bi-coin f19"></i>
          <span class="f10"> Comprar  </span>
        </router-link>
      </div>

      <div :class="['card-item mx-2 ms-sm-3']">
          <a href="javascript:void(0)" @click="openModalCarrinho">
            <i class="bi bi-cart4 f19"></i>
            <span class="f10"> Carrinho  </span>
            <span class="card-number center"> {{  listCarrinho.qtd }} </span>
          </a>
      </div>

    </div> -->


  </div>


  <!-- Foooter sem autenticacao -->
  <div class="menu" v-if="!isAuth">

    <div class="d-flex justify-content-between card" >

      <!-- Gambis para link apple -->
      <!-- <div v-if="!lixoApple" :class="['card-item ']">
        <router-link active-class="active" :to="{ name: 'auth'}" class="v2 teste-button">
          <i class="bi bi-ui-checks-grid f19"> </i> -->
          <!-- <template v-if="router.name === 'auth'">
            <img src="@/assets/icons/mini-logo-white.svg" />
          </template>
          <template v-else>
            <img src="@/assets/icons/mini-logo.svg" />
          </template> -->
          <!-- <span class="f10"> Baixar APP  </span>
        </router-link>
      </div> -->

      <!-- <div v-else :class="['card-item ']">
        <router-link active-class="active" :to="{ name: 'auth'}" class="v2" @click.prevent="verificarToastIOS">
          <template v-if="router.name === 'auth'">
            <img src="@/assets/icons/mini-logo-white.svg" />
          </template>
          <template v-else>
            <img src="@/assets/icons/mini-logo.svg" />
          </template>

          <span class="f10"> Baixar APP  </span>
        </router-link>
      </div> -->
      <!-- Fim gambiarra -->

      <!-- <div :class="['card-item ']">
        <router-link active-class="active" :to="{ name: ''}" class="p-2 v2" :href="whatsappLink" target="_blank">
          <img src="@/assets/icons/ic-suport.svg"/>
          <span class="f10"> Suporte  </span>
        </router-link>
      </div> -->

      <div :class="['card-item']">
        <router-link active-class="active"  @click="openModal('open::modal::login', true)" :to="{ name: 'home'}" class="v2">
          <img src="@/assets/icons/ic-apostar.svg"/>
          <span class="f10"> Apostar  </span>
        </router-link>
      </div>

      <div :class="['card-item']">
        <router-link active-class="active" :to="{ name: 'minhasApostas'}" @click="openModal('open::modal::login', true)" class="v2" >
          <img src="@/assets/icons/minhas_apostas.svg" />
          <span class="f10"> Minhas apostas </span>
        </router-link>
      </div>

      <div :class="['card-item']">
        <router-link active-class="active" :to="{ path: '/resultados/jogo-do-bicho/hoje'}" class="v2">
            <img src="@/assets/icons/resultados-bnp.svg" />
          <span class="f10"> Resultados  </span>
        </router-link>
      </div>

      <!-- <div :class="['card-item ']">
        <router-link active-class="active" :to="{ name: ''}" @click="openPromocoes" class="p-2 v2">
          <img src="@/assets/icons/ic-promocoes.svg" />
          <span class="f10"> Promoções  </span>
        </router-link>
      </div> -->

      <!-- <div :class="['card-item ']">
        <router-link active-class="active" :to="{ name: 'cotacoes'}" class="v2" >
          <img src="@/assets/icons/cotacao.svg" />
          <span class="f10"> Cotacões  </span>
        </router-link>
      </div> -->

      <div :class="['card-item ']">
          <router-link active-class="active" :to="{ name: 'esportes'}" class="v2" >
            <img src="@/assets/icons/sports_soccer.svg" />
            <span class="f10"> Esportes  </span>
          </router-link>
        </div>

      <div :class="['card-item ']" v-if="false">
        <router-link  active-class="active" :to="{ name: 'home'}" @click="openModal('open::modal::login', true)" class="v2">
          <img src="@/assets/icons/contract-2.svg" />
          <span class="f10"> Cupom  </span>
          <span v-if="listCarrinho.qtd > 0" :class="['position-absoluted start-100 translate-middle rounded-pill','badge']">
            <span>{{ listCarrinho.qtd }}</span>
          </span>
        </router-link>
      </div>

    </div>
    <span class="VERSION bold f10 d-block text-center"> {{  vversion  }} </span>
  </div>

</template>
<!-- eslint-enable -->
<script>
import { computed, inject, onMounted, ref, getCurrentInstance } from 'vue-demi'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { _alert } from '@/core/service/utils'
import { sample } from 'lodash'
// eslint-disable-next-line
export default {
  name: 'menu-footer',
  setup () {
    const app = getCurrentInstance()
    const { Events } = app.appContext.config.globalProperties

    const toast = inject('$toast')
    const router = useRoute()
    const store = useStore()
    const isAuth = computed(() => store.getters['login/auth'])
    let deferredPrompt = null
    const lixoApple = ref(false)
    const addBtn = ref(null)
    const vversion = computed(() => store.getters['_getVersion'])

    const listCarrinho = computed(() => {
      return store.getters['carrinho/listCarrinho']
    })

    const setProdutoFix = () => {
      // store.dispatch('listagemProdutos/setSelectProdutoAtual', 'BT')
    }

    const openPromocoes = () => {
      _alert('Promoções em Breve !', 'info', 'var(--background-gradient-dark, linear-gradient(90deg, #090A14 3.02%, #0D0F1E 100%))', 'white')
    }

    const openModal = (modal, value) => {
      Events.emit(modal, value)
    }

    const openModalCarrinho = () => Events.emit('modal::open::finalizar::aposta', true)

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    }

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)

    const verificarToastIOS = () => {
      if (isIos() && !isInStandaloneMode()) {
        toast('Click no ícone informado e selecione adicinar tela!', {
          positionX: 'center',
          duration: '10000000',
          type: 'passive',
          class: 'lixoApple',
          slotRight: '<span> <i class="f20 bi bi-box-arrow-up"></i> </span>'
        })
      }
    }

    const telefoneSuporte = computed(() => store.getters['login/telefoneSuporte'])

    const whatsappLink = computed(() => {
      const message = 'Suporte Bicho no Pix, tenho uma dúvida, poderia me ajudar?'
      const tel = sample(telefoneSuporte.value.whatsappSuport)

      return `https://api.whatsapp.com/send?phone=${tel}&text=${encodeURIComponent(message)}`
    })

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      deferredPrompt = e
      if (!addBtn.value) return
      addBtn.value.addEventListener('click', (e) => {
        deferredPrompt.prompt()
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt')
          } else {
            console.log('User dismissed the A2HS prompt')
          }
          deferredPrompt = null
        })
      })
    })

    onMounted(() => {
      addBtn.value = document.getElementsByClassName('teste-button')[0]
    })

    return {
      isAuth,
      router,
      verificarToastIOS,
      toast,
      lixoApple,
      openModalCarrinho,
      listCarrinho,
      openModal,
      openPromocoes,
      whatsappLink,
      telefoneSuporte,
      setProdutoFix,
      vversion
    }
  }
}

</script>
<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/bnp_variables';
.lixoApple{
  background: red;
}

.span-apostar {
  margin-bottom: 5px;
}

.badge {
  top: 35%;
  background: #DC3545 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  border-radius: 50% !important;
  width: 20px;
  height: 20px;

  & span {
    color: #FFFFFF !important;
    font-family: Blinker !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
}

.menu {
  position: fixed;
  bottom: 0;
  z-index: 4;
  left: 50%;
  display:flex;
  justify-content: center;
  background: #1A1B27;
  transform: translate(-50%, 0%);
  /* min-width: 450px; */
  width: 414px;
  height: 60px;
  /* @media(max-width: 769px) { */
  min-width: 100%;
  /* } */

  .card{
    background: #1A1B27;
    height: 95%;
    justify-content: center;
    width: 80%;
    flex-shrink: 0;
    flex-direction: row;
    font-weight: bold;
    border-radius: 5px;
    position: relative;
    box-shadow: none !important;
    border: none !important;

    @media(max-width: 350px) {
      width: 70%;
    }
    &-item {
      a {
        font-size: 12px;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        color: $menu-footer;
        top: 0;
        border-radius:0;
        transition: top 0.4s ease-in-out,
        opacity 0.4s ease-in-out,
        background 0.4s ease-in-out,
        border-radius 0.4s ease-in-out;
        img {
          width: 25px;
          height: 41px;
          padding-top: 11px;
          padding-bottom: 5px;
          margin: 0 auto;
          &.icon-result{
            width: 25px;
          }
          &.apostar{
            /* width: 27px; */
            width: 20px;
            padding-bottom: 0;
          }
        }
        &.v2 {
          color: #6B6D81;
        }
      }
      /* a.active {
        overflow: hidden;
        opacity: 1;
        color: white;
        background: $v2-icon;
        position: relative;
        top: -15px;
        border-radius: 20px;
        height: 58px;
        padding: 0.5rem!important;
        z-index: 100000;
        img {
          width: 20px;
          margin: 0 auto;
        }
      } */
    }
    &-number {
      background: $apostar;
      width: 20px;
      height: 20px;
      position: absolute;
      border-radius: 50%;
      color: white;
      font-weight: bold;
      top: 2px;
      right: 3px;
    }
  }
  span{
    display: flex;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.VERSION {
  transform: rotate(-90deg);
  position: absolute;
  bottom: 18px;
  opacity: 0.1;
  right: 5px !important;
  font-size: 9px !important;
  font-family: monospace !important;
  @media(max-width: 992px) {
    right: 8px;
  }
}
</style>
