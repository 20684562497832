import http from '@/core/http'

const state = {
  listaGames: [],
  game: {}
}

const actions = {
  async obterSlotsCassinos ({ commit }, payload) {
    const result = await http.get('slots-casinos')
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_GAMES', result.data.data)
  },

  async obterSlotsCassinosHome ({ commit }, payload) {
    const result = await http.get('slots-casinos/get-games-home')
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_GAMES', result.data.data)
  },

  async addGameSlot ({ commit }, payload) {
    commit('SET_GAME', payload)
  },

  async getGameById ({ commit }, payload) {
    const result = await http.get('slots-casinos/get-game-by-id', { params: { gameId: payload } })
    if (!result.data.success) throw Error(result.data.message)
    commit('SET_GAME', result.data.data)
  }
}

const mutations = {
  'SET_GAMES' (state, payload) {
    state.listaGames = payload
  },

  'SET_GAME' (state, payload) {
    state.game = payload
  },
}

const getters = {
  listaGames: state => state.listaGames,
  game: state => state.game
}

export default { state, actions, mutations, getters, namespaced: true }
