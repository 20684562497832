// import mSUP5 from './modalidades/mSUP5'
export default {
  tableBicho: [],
  listMolidades: true,
  isModalidadeCotacaoUnica: true, /* mostra modalidade mas apenas 1 */
  active: true,
  dadosCotacao: {
    text: 'SUPER 5'
  },
  sub: '',
  // imgAjuda: true,

  /* 2 */
  sub2: 'Como apostar',
  sub21: `
    <div class="mb-2">
      O Super 5 concorre com o resultado da Look em todos os seus concursos diários. Em um único evento, são sorteadas 5 dezenas aleatórias entre 01 e 26. Você pode jogar em 5, 6, 7 ou 8 dezenas. Se jogar apenas 5 dezenas, você concorre com a quina, quadra, terno ou dupla. Se jogar mais de 5 dezenas, concorre apenas com a quina, quadra e terno.
    </div>

    <div class="mb-2">
      A cotação é de acordo com a quantidade de números jogados e a quantidade de acertos conforme a tabela abaixo:
    </div>

    <div class="text-pontilhados">
      <span> 5 dezenas – Quina </span>
      <span class="mx-1"></span> 
      <span>   6.000x </span>
    </div>

    <div class="text-pontilhados">
      <span> 5 dezenas – Quadra </span>
      <span class="mx-1"></span> 
      <span>   100x </span>
    </div>

    <div class="text-pontilhados">
      <span> 5 dezenas – Terno </span>
      <span class="mx-1"></span> 
      <span>   7x </span>
    </div>

    <div class="text-pontilhados">
      <span> 5 dezenas – Dupla </span>
      <span class="mx-1"></span> 
      <span>   1x </span>
    </div>

    <div class="text-pontilhados">
      <span> 6 dezenas – Quina </span>
      <span class="mx-1"></span> 
      <span>   2.000x </span>
    </div>

    <div class="text-pontilhados">
      <span> 6 dezenas – Quadra </span>
      <span class="mx-1"></span> 
      <span>   45x </span>
    </div>

    <div class="text-pontilhados">
      <span> 6 dezenas – Terno </span>
      <span class="mx-1"></span> 
      <span>   4x </span>
    </div>

    <div class="text-pontilhados">
      <span> 7 dezenas – Quina </span>
      <span class="mx-1"></span> 
      <span>   600x </span>
    </div>

    <div class="text-pontilhados">
      <span> 7 dezenas – Quadra </span>
      <span class="mx-1"></span> 
      <span>   20x </span>
    </div>

    <div class="text-pontilhados">
      <span> 7 dezenas – Terno </span>
      <span class="mx-1"></span> 
      <span>   2,50x </span>
    </div>

    <div class="text-pontilhados">
      <span> 8 dezenas – Quina </span>
      <span class="mx-1"></span> 
      <span>   250x </span>
    </div>

    <div class="text-pontilhados">
      <span> 8 dezenas – Quadra </span>
      <span class="mx-1"></span> 
      <span>   10x </span>
    </div>

    <div class="text-pontilhados mb-3">
      <span> 8 dezenas – Terno </span>
      <span class="mx-1"></span> 
      <span>   1,5x </span>
    </div>

    <div class="mb-2"> Para jogar no Super 5, siga os passos abaixo: </div>
    <div class="mb-2"> Para jogar no Super 5, marque a quantidade de dezenas que você quer jogar entre 5 e 8 números. </div>

    <ul class="lista">
      <li> 
        Comece a marcar os seus números até completar a quantidade que que você escolheu, ou, se preferir, dê um clique no botão laranja para gerar uma aposta aleatória;
      </li>
      <li> 
        Repita o processo para apostar em quantos palpites desejar. Se você errar ao escolher um número e quiser substituí-lo, clique no xis no final da linha; <br/>
        Se a aposta já foi inserida, para alterar, clique no “X” ao lado do palpite, ou clique no “X” ao lado do botão laranja para remover todos os palpites; <br/>
        Ao realizar várias apostas, elas são empilhadas e só as últimas ficam visíveis no painel. As demais vão aparecer no bilhete ao final da aposta;
      </li>
      <li> 
        Clique em "Continuar";
      </li>

      <li> 
        Informe o valor que deseja apostar;
        A mensagem, "Aposta Adicionada ao bilhete" será exibida por alguns segundos, depois desaparece automaticamente, e o seu bilhete é exibido na tela. Ele contém todos os detalhes da aposta, inclusive quanto você vai ganhar se acertar, e o valor total jogado. Abaixo do valor você verá dois botões: “+ Apostas” e “Finalizar.
      </li>
      <li> 
        Clicando em fazer mais apostas você volta para a tela inicial do Super 5, onde pode continuar apostando neste produto, e finalizar, se você tiver concluído o seu jogo;
      </li>

      <li> 
        Clicando em finalizar você volta para a página principal do BICHO NO PIX, onde poderá apostar em outros produtos;
      </li>

    </ul>
  `,

  // modalidades
  // ...mSUP5
}
