import { createStore } from 'vuex'
import modules from './modules'
import _config from '@/core/config'
import playerYoutube from 'youtube-player'
import http from '@/core/http'

export default createStore({
  state: {
    menu: false,
    estados: [],
    tabActive: '',
    video: {
      id: false,
      config: false
    }
  },
  actions: {
    setMenu: async ({ state }, payload) => {
      state.menu = payload
    },
    setTabActive: async ({ state }, payload) => {
      state.tabActive = payload
    },
    nextTabActive: async ({ state }, _) => {
      state.tabActive = state.tabActive + 1
    },

    destroyVideo: async ({ state }, _) => {
      console.log('testete', state.video.addEventListener)
      if (!state.video.addEventListener && !state.video.config) return
      state.video.stopVideo()
      state.video.destroy()
      state.video.destroy(true)
      state.video.config = false
    },

    playVideo: async ({ state }, payload) => {
      if (state.config && payload === state.config.videoId) return

      state.video.config = {
        controls: 1,
        autoplay: 1,
        loop: 1,
        videoId: false,
        height: '100%',
        width: '100%',
        playerVars: {
          autoplay: 1,
          controls: 0,
          autohide: 1,
          wmode: 'opaque',
          origin: `https://${window.location.host}`,
        }
      }
      state.video.config = {}
      state.video.config.videoId = payload
      try {
        state.video = playerYoutube(`youtube-${payload}`, state.video.config)
        return Promise.resolve(true)
      } catch (error) {
        console.log('deu error')
        return Promise.resolve(true)
      }
    },
    addStatistica: async ({ state }, payload) => {
      let { event, type, url } = payload

      if ((!event.target || !event.target.href) && event) throw new Error('Evento não presente ')

      if (!type) type = 'click'

      await http.post('campanha-statisticas', { event: event?.target.href || url, type })
    },

    addStatisticaURL: async ({ state }, { router, route }) => {
      if (String(router.options.history.location).indexOf('btag') !== -1) {
        const result = await http.post(`${_config.API}campanha-statisticas`, { event: window.location.href, type: 'click' })
        if (!result.data.success) throw new Error(result.data.message)
      }
    }
  },
  mutations: {},
  getters: {
    _getVersion: () => String(_config.APP_VERSION).toLocaleLowerCase().replace('v-', ''),
    getTabActive: state => state.tabActive
  },
  modules: { ...modules }
})
