<template>
  <!-- efeito quando abrir o menu bg fundo preto -->
  <div v-show="menu" class="bg"></div>
  <main :class="['h-100 mini-celulares', route.name === 'auth' || route.name === 'revenda' ? 'bg-print' : '']">
    <span class="VERSION bold f10 d-block text-center d-flex flex-nowrap align-items-end"> {{ vversion }} </span>
    <div v-if="true">
      <!-- <div :class="['d-flex d-column w-100', menu ? 'scroll' : '']" v-if="plataformaAtiva  && plataformaAtiva.isEnable && !loader"> -->
      <div :class="['d-flex d-column w-100', menu ? 'scroll' : '']" v-if="!loader">
        <Menu v-if="!isAffiliateRoute" />
        <div :class="[menu ? 'open' : 'not']">
          <topDownload v-if="!closeBannerDownload" />
          <topMenu :class="[menu ? 'open-header' : 'not-header']" v-if="!isAffiliateRoute" />
          <div
            :class="['', menu ? 'open-content' : isAffiliateRoute ? `affiliate-content ${route.name}` : 'not-content p-2']">
            <router-view />
            <CardFAQ class="mb-5" v-if="route.name === 'auth' || route.name === 'home'" />

            <!-- certificado -->
            <div v-if="exiCertificado" class="info-certificado d-flex flex-column justify-content-center pt-2 mt-2">
              <div class="w-100 center mb-2 gap-3">

                <a href="#anj-21f536a0-138f-4ad2-aa97-f06ce522506d" @click="openModalJogoResponsavel(true)">
                  <img :src="require('@/assets/footer-icons/plus18-icon.webp')" alt="maior de 18 anos">
                </a>

                <div id="anj-21f536a0-138f-4ad2-aa97-f06ce522506d"
                  data-anj-seal-id="21f536a0-138f-4ad2-aa97-f06ce522506d" data-anj-image-size="30"
                  data-anj-image-type="basic-small"></div>

                <a href="https://www.gamblingtherapy.org" target="_blank">
                  <img :src="require('@/assets/footer-icons/gt-icon.webp')" alt="" style="width: 40px;">
                </a>

                <a href="https://access.gaminglabs.com/Certificate/Index?i=535" target="_blank">
                  <img :src="require('@/assets/footer-icons/gli-white.jpg')" alt="Gaming Labs Certified GLI"
                    class="img-fluid" style="width: 120px; height: 50px;">
                </a>

                <a href="https://transparencyreport.google.com/safe-browsing/search?url=www.bichonopix.com"
                  target="_blank">
                  <img :src="require('@/assets/footer-icons/google.svg')" alt="Google Safe Browsing"
                    style="width: 100px; height: 50px;">
                </a>
              </div>
              <div class=" w-100 center f10 mb-2">
                bichonopix.com is operated by Vert Investments N.V., registered in the Commercial Register of Curaçao
                under number 163732 and License number ALSI-082309009-FI4 issued by the GAMING BOARD OF ANJOUAN at Union
                of Comoros in accordance with the Betting and Gaming act of 2005 of Anjouan.
              </div>

              <div class="d-flex justify-content-center wrapper-btn my-2">
                <a href="/politicadeprivacidade" class="btn- btn-primary mx-2">Política de Privacidade</a>
                <a href="/termosecondicoes" class="btn- btn-primary mx-2">Leia os termos e condições</a>
              </div>

              <div class="w-100 center f10 pb-2">
                © 2023 bichonopix.com Todos os direitos reservados. support@bichonopix.com
              </div>

            </div>

            <btnAjuda
              v-if="['/apostas', '/decifrador-de-sonhos', '/esportes'].indexOf(route.path) === -1 && !isAffiliateRoute" />
            <MenuFooter v-if="!menu && iframeCartelas === false && !isSlots && !isAffiliateRoute && !cadastro && !deposito" />
          </div>

          <ModalCarrinho />
          <ModalLoginAposta />
          <ModalQrCodePix />
        </div>
      </div>

      <!-- Site em manutencao -->
      <!-- <div v-if="!plataformaAtiva || (plataformaAtiva  && plataformaAtiva.isEnable === false) && !loader"> -->
      <!-- <div v-if="!loader">
        <span class="VERSION bold f10 d-block text-center"> {{  vversion  }} </span>
        <Menu />
        <siteManutencao />
        <div :class="[menu ? 'open' : 'not']" >
            <topDownload v-if="!closeBannerDownload" />
            <topMenu :class="[menu ? 'open-header' : 'not-header']" />
        </div>
      </div> -->
      <!-- loader -->
      <div class="d-flex justify-content-center" v-if="loader">
        <viewLoader />
      </div>
    </div>

    <!-- <div v-if="showRaspadinha" class="raspadinha-tela">
      <Raspadinha />
    </div> -->

    <div>
      <modalJogoResponsavel :show="showJogoResponsavel" />
    </div>

    <div>
      <ModalLogin :visible="showLogin" />
    </div>

    <div>
      <ModalRecuperarSenha :visible="showRecuperarSenha" />
    </div>

    <div>
      <ModalCadastro :visible="showCadastro" />
    </div>

    <div>
      <ModalCadastroSair :visible="showCadastroSair" />
    </div>

    <div>
      <ModalTermoUso :visible="showTermoUso" />
    </div>

    <div>
      <ModalPoliticaPrivacidade :visible="showPoliticaPrivacidade" />
    </div>

    <div>
      <ModalAfiliado />
    </div>

    <div>
      <modalDepositoRealizado :visible="showModalDepositoRealizado" />
    </div>

    <div>
      <ModalPopupSaldoBaixo :visible="showModalPopupSaldoBaixo" />
    </div>

  </main>
</template>
<script>

import topMenu from '@/views/layout/header'
import Menu from '@/views/layout/nav'
import MenuFooter from '@/views/layout/menuFooter.vue'
import { useStore } from 'vuex'
// eslint-disable-next-line
import { computed, onMounted, ref, getCurrentInstance, defineAsyncComponent, watch, onBeforeUnmount, reactive } from 'vue'
import session from './core/service/session'
import { _alert } from './core/service/utils'
import { useRoute, useRouter } from 'vue-router'
// import Raspadinha from '@/views/raspadinha/initRaspadinha.vue'
import modalJogoResponsavel from '@/views/produtos/components/modalJogoResponsavel.vue'
// import ModalLogin from '@/views/auth/components/modalLogin.vue'
// import ModalCadastro from '@/views/auth/components/modalCadastro.vue'
// import ModalRecuperarSenha from '@/views/auth/components/modalRecuperarSenha.vue'
// import { init as firebaseInit } from '@/core/service/firebase'
// import siteManutencao from '@/views/layout/manutencao.vue'
import viewLoader from '@/components/loader.vue'
import Cookies from 'js-cookie'
export default {
  name: 'INIT',
  components: {
    topMenu,
    Menu,
    MenuFooter,
    // Raspadinha,
    // siteManutencao,
    viewLoader,
    modalJogoResponsavel,
    ModalLogin: defineAsyncComponent(() => import('@/views/auth/components/modalLogin.vue')),
    ModalCadastro: defineAsyncComponent(() => import('@/views/auth/components/modalCadastro.vue')),
    ModalCadastroSair: defineAsyncComponent(() => import('@/views/auth/components/modalCadastroSair.vue')),
    ModalRecuperarSenha: defineAsyncComponent(() => import('@/views/auth/components/modalRecuperarSenha.vue')),
    ModalCarrinho: defineAsyncComponent(() => import('@/views/apostas/components/modal/modalFinalizarAposta.vue')),
    ModalTermoUso: defineAsyncComponent(() => import('@/views/auth/components/modalTermoUso.vue')),
    ModalPoliticaPrivacidade: defineAsyncComponent(() => import('@/views/auth/components/modalPoliticaPrivacidade.vue')),
    ModalAfiliado: defineAsyncComponent(() => import('@/components/modalAfiliado.vue')),
    CardFAQ: defineAsyncComponent(() => import('@/views/auth/components/cardFAQ.vue')),
    btnAjuda: defineAsyncComponent(() => import('@/components/btnAjuda')),
    topDownload: defineAsyncComponent(() => import('@/views/layout/cardDownload.vue')),
    modalDepositoRealizado: defineAsyncComponent(() => import('@/views/layout/cardDepositoRealizado.vue')),
    ModalPopupSaldoBaixo: defineAsyncComponent(() => import('@/views/layout/cardPopupSaldoBaixo.vue')),
    ModalLoginAposta: defineAsyncComponent(() => import('@/views/apostas/components/modal/modalLoginAposta.vue')),
    ModalQrCodePix: defineAsyncComponent(() => import('@/views/apostas/components/modal/modalQrCodePix.vue')),
  },
  setup () {
    const store = useStore()

    const carrinho = ref(false)
    const menu = computed(() => store.state.menu)
    // const user = computed(() => store.getters['login/getUser'])
    const isAuth = computed(() => store.getters['login/auth'])

    const route = useRoute()
    const router = useRouter()
    const loader = ref(false)
    const showCadastro = ref(false)
    const showCadastroSair = ref(false)
    const showLogin = ref(false)
    const showRecuperarSenha = ref(false)
    const showTermoUso = ref(false)
    const showPoliticaPrivacidade = ref(false)
    const closeBannerDownload = ref(false)
    const showModalDepositoRealizado = ref(false)
    const showModalPopupSaldoBaixo = ref(false)
    // const optionsLocation = {
    //   enableHighAccuracy: true,
    //   timeout: 10000 * 20,
    //   maximumAge: 0
    // }
    const iframeCartelas = computed(() => {
      const show = ['/cartelas', '/cartelas2', '']
      return show.indexOf(route.path) !== -1
    })

    const cadastro = computed(() => {
      return route.path === '/cadastro'
    })

    const deposito = computed(() => {
      return route.name === 'creditos'
    })

    const isSlots = computed(() => {
      return ['slotsIframe', 'esportes'].includes(route.name)
    })

    const exiCertificado = computed(() => {
      const show = ['/', '/home']
      return show.indexOf(route.path) !== -1
    })

    const isAffiliateRoute = computed(() => ['cadastro.afiliado', 'cadastro.afiliado.concluido', 'bnp1', 'bnp2', 'recover.password.reseller'].includes(route.name))

    /* Raspadinha ativada tem que iniciar o canvas dela e deixar tudo desabilitado */
    // const showRaspadinha = computed(() => store.state.raspadinha.animacao)
    const vversion = computed(() => store.getters['_getVersion'])
    // const plataformaAtiva = computed(() => store.getters['login/telefoneSuporte'])
    const plataformaAtiva = computed(() => true)
    const showJogoResponsavel = ref(false)
    const app = getCurrentInstance()
    const { Events } = app.appContext.config.globalProperties

    // const watchPositionGPS = () => navigator.geolocation.watchPosition(successPosition, failurePosition, optionsLocation)

    // const getCurrentPositionGPS = () => {
    //   navigator.geolocation.getCurrentPosition(successPosition, failurePosition, optionsLocation)
    // }

    // const successPosition = (position) => {
    //   if (!user.value.id) return
    //   const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = position.coords
    //   const dados = { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed }
    //   // store.dispatch('login/addPositions', { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed })
    //   if (position.coords) session.set('gps', dados)
    // }

    const openModalJogoResponsavel = (e) => {
      showJogoResponsavel.value = e
    }

    // const failurePosition = (err) => { console.log('err', err) }

    onMounted(async () => {
      session.remove('gps') // remover posicao de sessao

      closeBannerDownload.value = Cookies.get('closeBannerDownload') === 'true'

      Events.on('close::banner::download', () => {
        closeBannerDownload.value = true
      })

      setTimeout(() => { // certificado
        try {
          // eslint-disable-next-line
          anj_21f536a0_138f_4ad2_aa97_f06ce522506d.init()
        } catch (error) {
          console.log('error certificado', error)
        } /* link de verificacao de certificado  */
      }, 5000)

      /* controle div carriho */
      Events.on('open::modal::carrinho', e => {
        carrinho.value = e
      })

      Events.on('open::modal::cadastro-sair', e => {
        showCadastroSair.value = e
      })
      Events.on('open::modal::cadastro', e => {
        router.replace({ name: 'cadastro' })
        Events.emit('modal::login::close::produto::selecionado')
        // showCadastro.value = e
      })
      Events.on('open::modal::login', e => { showLogin.value = e })
      Events.on('open::modal::login::produto::selecionado', e => { showLogin.value = true })
      Events.on('open::modal::recuperarSenha', e => {
        showLogin.value = !e
        showRecuperarSenha.value = e
      })
      Events.on('open::modal::termoUso', e => { showTermoUso.value = e })
      Events.on('open::modal::politicaPrivacidade', e => { showPoliticaPrivacidade.value = e })
      Events.on('open::modal::depositoRealizado', e => { showModalDepositoRealizado.value = e })
      Events.on('open::modal::popupSaldoBaixo', e => { showModalPopupSaldoBaixo.value = e })

      Events.on('modal::close', e => {
        carrinho.value = e
        showLogin.value = e
        showCadastro.value = e
        showRecuperarSenha.value = e
        showTermoUso.value = e
        showPoliticaPrivacidade.value = e
        showCadastroSair.value = e
        showModalDepositoRealizado.value = e
        showModalPopupSaldoBaixo.value = e
      })

      // firebaseInit()
      loader.value = false

      store.dispatch('addStatisticaURL', { router, route })

      store.dispatch('login/suporteWhatsapp').then(() => {
        loader.value = false
        if (!plataformaAtiva.value.isEnable) {
          session.remove('auth-token')
          // store.dispatch('login/logout')
        }
      })

      const token = session.get('auth-token')
      if (token) {
        store.dispatch('login/getDadosUsuario').catch(e => {
          _alert('Faça login novamnete!')
          store.dispatch('login/logout')
        })

        store.dispatch('saldos/getSaldos')
      }

      // if (!('geolocation' in navigator)) {
      //   // this.errorStr = 'Geolocation is not available.'
      //   console.log('erro geolocation')
      //   return
      // }

      // if ('permissions' in navigator) {
      //   navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      //     if (result.state === 'granted') {
      //       watchPositionGPS()
      //     }
      //     if (result.state === 'prompt') {
      //       getCurrentPositionGPS()
      //     }
      //   }).catch(e => {
      //     console.log(e)
      //   })
      // }
      // abre modal jogo responsavel navbar
      Events.on('modal::produtos::jogoResponsavel', (e) => {
        showJogoResponsavel.value = e
      })

      window.addEventListener('popstate', () => {
        // pegando o evento de voltar do navegador e se bater no produtos jogando na home para não buggar o apostas
        const currentPath = route.path
        if (currentPath === '/produtos') router.replace({ name: 'home' })
      })

      const loaderInicial = document.querySelector('#loading-screen')
      if (loaderInicial) {
        loaderInicial.style.display = 'none'
      }
    })

    watch(route, (to) => {
      const referralId = session.get('referralId')
      if (to.name === 'home' && isAuth && referralId) {
        // Events.emit('open::modal::afiliado', true)
      }
    }, { flush: 'pre', immediate: true, deep: true })

    watch(showCadastro, (e) => {
      // router.push({ query: e ? { modal: 'cadastrar' } : null })
      if (e) {
        router.push({ query: { modal: 'cadastrar' } })
      }

      if (!e && e !== undefined) {
        router.push({ query: null })
      }
    })

    return {
      menu,
      route,
      // showRaspadinha,
      vversion,
      plataformaAtiva,
      loader,
      showJogoResponsavel,
      iframeCartelas,
      carrinho,
      showCadastro,
      showLogin,
      showRecuperarSenha,
      showTermoUso,
      showPoliticaPrivacidade,
      exiCertificado,
      openModalJogoResponsavel,
      isSlots,
      isAffiliateRoute,
      showCadastroSair,
      cadastro,
      closeBannerDownload,
      showModalDepositoRealizado,
      showModalPopupSaldoBaixo,
      deposito,
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_init.scss';

.affiliate-content {
  margin: 0 auto;
  max-width: 468px;
}

.bg-print {
  background: $bg-index;
}

.mini-celulares {
  min-height: 550px;
}

.not-content {
  // padding-top: 75px !important;
}

.open-content {
  padding-top: 120px !important;
}

@keyframes bgAnimation {
  0% {
    background-color: #000;
    opacity: 0;
  }

  100% {
    opacity: 0.9;
  }
}

@keyframes down-menu {
  0% {
    bottom: -100px;
  }

  100% {
    bottom: 0px
  }
}

.bg {
  animation: bgAnimation 0.5s ease-in-out;
  position: fixed;
  z-index: 10;
  transition: all 1s ease-in;
  background: #000;
  opacity: 0.9;
  width: 100%;

  height: 100%;
}

.btn- {
  background-color: transparent;
  border-radius: 0 0 8px 8px;
  padding: 6px;
  border: 1px solid #50515B;
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  color: #FFF;
  transition: all .3s ease-in-out;
  &:hover {
    background: #FFF;
    color: #232530;
  }
}

body {
  margin: 0;
  background: $bg-pages;
  background-attachment: fixed;
  color: $colorFonts;

  &.bnp-bg {
    background: #000 !important;
  }
}

.overflowhidden {
  overflow: hidden !important;
}

.raspadinha-tela {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 99.5vh;
}

.scroll {
  overflow: hidden;
}

// .fade-enter-active{
// animation: down-menu 1s linear;
// }

/* Antiga animação menu movendo o body da pagina toda */
.open {
  width: 100%;
  // transition: all linear 1s;
  // margin-top: 68px;
  // margin-left: 40px;
  // border-radius: 0 20px;
  // border-radius: 20px 0 0 0;
  // position: relative;
  z-index: 2;

  // box-shadow: -10px 34px 93px -17px $primary;
  // min-width: 400px;
  &-header {
    // transition: border-radius linear 1s;
    // border-radius: 20px 0 0 0;
  }

  &-content {
    //  transition: all linear 1s;
    margin: 0 auto;
    // padding-left: 20px;
    // margin-bottom: 80px;
    max-width: 468px;
  }
}

.not {
  // transition: all linear 1s;
  box-shadow: none;
  width: 100%;
  background-color: #0c0e1c !important;

  &.bnp-bg {
    background-color: #000 !important;
  }

  &-header {
    //  transition: border-radius linear 1s;
    // border-radius: 0 0 0 0;
  }

  &-content {
    // transition: all linear 1s;
    margin: 0 auto;
    margin-bottom: 80px;
    max-width: 468px;
    // padding: 0px 20px;
  }
}

.VERSION {
  position: fixed;
  color: white;
  right: 10px;
  bottom: 0;

  opacity: 0.1;
  right: 5px;
  font-size: 9px;
  font-family: monospace !important;
}

.info-certificado {
  border-top: 1px solid white;

  div>a:last-child>img {
    @media(max-width: 446px) {
      width: 80px !important;
    }
  }

  div>a:nth-child(4)>img {
    @media(max-width: 446px) {
      width: 90px !important;
    }
  }
}
</style>
