import http from '@/core/http'
import _config from '@/core/config'
const state = {}

const actions = {
  jogosUsuarioWeb: async ({ commit }, payload) => {
    const result = await http.post(`${_config.API}associated-web-user-games`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  }
}
const mutations = {}

const getters = {}

export default { actions, mutations, state, getters, namespaced: true }
