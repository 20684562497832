import http from '@/core/http'

const state = {
  raspadinhas: [],
  animacao: false,
  winners: false
}

const actions = {
  async getRaspadinhas ({ commit }) {
    const result = await http.get('raspadinhas')
    if (!result.data.success) throw new Error(result.data.message)
    commit('SET_DADOS', result.data.data)
    return Promise.resolve(result.data.data)
  },

  async confirmationWinners ({ commit }, payload) {
    const result = await http.post('scratch/confirmation', payload)
    if (!result.data.success) throw new Error(result.data.message)
    commit('SET_DADOS_WINNERS', result.data.data)
    return Promise.resolve(result.data.data)
  },

  async startAnimacaoRaspadinha ({ commit }, payload = false) {
    commit('SET_START_ANIMACAO', payload)
    return Promise.resolve(true)
  },

  async clearRaspadinha ({ state }, payload) {
    state.winners = false
    state.raspadinhas = false
  }
}

const mutations = {
  'SET_DADOS' (state, payload) {
    state.raspadinhas = payload
  },

  'SET_START_ANIMACAO' (state, payload) {
    state.animacao = payload
  },

  'SET_DADOS_WINNERS' (state, payload) {
    state.winners = payload
  }
}

const getters = {
  listRaspadinhas: state => state.raspadinhas,
  getWinnerRaspadinha: state => state.winers
}

export default { state, actions, mutations, getters, namespaced: true }
