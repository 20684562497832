export default {
  path: '/cotacoes',
  name: 'cotacoes',
  component: () => import('./index.vue'),
  meta: {
    title: 'Cotações Atualizadas do Jogo do Bicho | BICHO NO PIX',
    description: 'Confira as cotações mais recentes do jogo do bicho online no BICHO NO PIX. Acompanhe e aposte com as melhores informações atualizadas!',
    ogDescription: 'Confira as cotações mais recentes do jogo do bicho online no BICHO NO PIX. Acompanhe e aposte com as melhores informações atualizadas!',
    canonical: window.location.href,
  }
}
