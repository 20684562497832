import { createRouter, createWebHistory } from 'vue-router'
import creteRoutes from '../../allRoutes'
import store from '@/store'
import session from '../service/session'
import { _alert } from '../service/utils'
import { Events } from '@/main'
// import { useHead } from '@vueuse/head'
import { notAuthRouters } from './exceptionAuth'
const routes = [...creteRoutes]

export const notAuthNames = notAuthRouters

const router = createRouter({
  history: createWebHistory(process.env.URL),
  routes,
  linkActiveClass: 'link',
  linkExactActiveClass: 'link'
})

router.beforeEach(async (to, from) => {
  console.log('to', to)
  console.log('from', from)

  // const { meta } = to

  /*   useHead({
    title: meta.title,
    meta: [
      { name: 'description', content: meta.description },
      { property: 'og:description', content: meta.description },
    ],
    link: [
      { rel: 'canonical', href: `https://bichonopix.com${to.fullPath}` }
    ]
  }) */

  // rotas abertas que podem ser acessadas sem estar logado
  if (!(to.path === '/' || to.name === 'auth')) session.remove('redirectAfterLogin')
  const unauthenticatedPaths = ['cadastro']

  console.log('to', to)
  console.log('from', from)
  if (String(to.name).indexOf('bnp') !== -1) notAuthNames.push(to.name)

  console.log(notAuthNames.filter(v => v === to.name)[0], to.from)
  const access = notAuthNames.filter(v => v === to.name)[0]

  /* Cadastrar influenciador */
  if (to.query.btag) {
    session.set('btag', to.query.btag)
    store.dispatch('login/getTagByAfilliate', { btag: to.query.btag }).then(res => {
      /* eslint-disable */
      fbq('init', res.data)
      fbq('track', 'PageView');
    /* eslint-enable */
    })
  }

  if (to.query.atag) {
    session.set('atag', to.query.atag)
  }

  if (to.query.referralId) {
    session.set('referralId', to.query.referralId.toLocaleLowerCase())
  }

  const isAuth = session.get('auth-token')
  // console.log('rotas', from.path, to.path)

  if (isAuth && unauthenticatedPaths.some(it => it === to.name)) return { name: 'auth' }

  if (from.path === '/' && to.path === '/' && !isAuth) return true
  if (from.path !== '/' && to.path === '/' && !isAuth) return true

  if (access && !isAuth) return true

  // caso tenha logado para ver o resultado
  const resultado = session.get('redirect-result') || false

  if (from.path === '/' && to.path === '/resultados' && !isAuth) {
    store.dispatch('login/openModal', 'resultado')
  }
  if (from.path === '/' && to.path === '/credito-pix' && !isAuth) {
    store.dispatch('login/openModal', 'resultado')
  }

  if (!isAuth && resultado) return true

  if (!isAuth) {
    session.set('redirectAfterLogin', { path: to.path, query: to.query })
    const isCadastro = unauthenticatedPaths.indexOf(to.path) !== -1
    if (isCadastro) return { name: to.path }
    return { name: 'auth' }
  }
  store.dispatch('saldos/getSaldos')
    .then((result) => {
      if (result.depositsData && result.depositsData.firstDeposit) {
        if (result.depositsData.firstDeposit && !result.depositsData.closedModalFirstDeposit) {
          Events.emit('open::modal::depositoRealizado', true)
        }
      }
    })
    .catch(async (e) => {
      if (e.message === 'Service Unavailable' || e.message === 'Cannot read properties of undefined (reading \'url\')') return
      console.log('error logout', e.message)

      store.dispatch('logout')
      session.remove('auth-token')
      session.remove('redirectAfterLogin')

      if (String(e.message).indexOf('Usuário bloqueado') !== -1) _alert(e.message, 'warning')
      else _alert('Sessão expirada!', 'warning')
      setTimeout(() => { window.location = '/' }, 1200)
    })
})

export default router
