import { range, map, last } from 'lodash'

export const tabelaGrupos = () => {
  const bichos = ['Avestruz', 'Águia', 'Burro', 'Borboleta', 'Cachorro', 'Cabra', 'Carneiro', 'Camelo', 'Cobra', 'Coelho', 'Cavalo', 'Elefante', 'Galo', 'Gato', 'Jacaré', 'Leão', 'Macaco', 'Porco', 'Pavão', 'Peru', 'Touro', 'Tigre', 'Urso', 'Veado', 'Vaca']
  const items = range(1, 26)
  let count = 1
  const format = map(items, (v, k) => {
    let dezenas
    let isDezenas
    if (k === 0) {
      dezenas = range(count, v + 4)
      isDezenas = `${String(dezenas[0]).padStart(2, '0')} - ${String(last(dezenas)).padStart(2, '0')}`
    }

    if (k > 0) {
      count = count + 4
      dezenas = range(count, count + 4)
      isDezenas = `${String(dezenas[0]).padStart(2, '0')} - ${String(last(dezenas)).padStart(2, '0')}`
    } 
    
    return {
      dezenas: isDezenas,
      bicho: bichos[v - 1],
      index: String(v).padStart(2, '0')
    } 
  })

  return format
}
