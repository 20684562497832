import http from '@/core/http'
import _config from '@/core/config'
import axios from 'axios'
import { orderBy, assign, cloneDeep } from 'lodash'

const state = {
  dados: {
    jsonFlags: {
      whatsappActive: ''
    }
  },
  oldDados: {
    jsonFlags: {
      resetPassword: ''
    }
  },
  estados: [],
  distritos: [],
  oldDadosPix: {},
  endereco: {},
  afiliado: ''
}

const headerAuth = (userToken, password) => window.btoa(`${userToken}:${password}`)

const actions = {
  getUsuario: async ({ commit }, payload) => {
    const result = await http.post(`${_config.API}cadastro/view`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    commit('ADD_USER', result.data.data)
    return Promise.resolve(result)
  },

  updatedPassword: async ({ commit }, payload) => {
    const headers = { headers: { Authorization: 'Basic ' + headerAuth(payload.email, payload.senhaAtual) } }
    const result = await http.post(`${_config.API}senha/mudar-senha`, payload, headers)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },

  updatedDadosUsuario: async ({ commit }, payload) => { // edita dados do usuario
    const result = await http.post(`${_config.API}cadastro`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },

  confirmarTelefone: async (_, payload) => {
    const result = await http.get(`${_config.API}solicitar-confirmacao-telefone`, { params: { ...payload } })
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },

  validarCodigo: async (_, payload) => {
    const result = await http.post(`${_config.API}/confirmar-telefone`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  getEstados: async ({ commit }, payload) => {
    const result = await axios.get(`${_config.URL_IBGE}`)
    result.data = orderBy(result.data, ['sigla'])
    commit('SET_ESTADOS', result.data)
  },
  getDistrito: async ({ commit }, payload) => {
    const result = await axios.get(`${_config.URL_IBGE}${payload}/distritos`)
    commit('SET_DISTRITOS', result.data)
  },
  getEndereco: async ({ rootState, state }, payload) => {
    const result = await axios.post('busca-cep', payload)
    if (result.data.data.erro) throw new Error('Endereço não encontrado')

    const dados = result.data.data
    state.dados.city = dados.localidade
    state.dados.state = dados.uf
    state.dados.address = dados.logradouro
    state.dados.neighborhood = dados.bairro

    return Promise.resolve(dados)
  },
  gerarOTP: async (_, payload) => {
    const result = await http.post(`${_config.API}generate-otp`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  verificarOTP: async (_, payload) => {
    const result = await http.post(`${_config.API}verify-otp`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  cancelarOTP: async (_, payload) => {
    const result = await http.post(`${_config.API}cancel-otp`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  validarOTP: async (_, payload) => {
    const result = await http.post(`${_config.API}validate-otp`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  hasActiveOTP: async (_, payload) => {
    const result = await http.get(`${_config.API}has-active-otp`, { params: payload })
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },
  enviarCodWpp: async (_, payload) => {
    const result = await http.post(`${_config.API}send-wpp-code`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },

  cadastrarAfiliado: async (_, payload) => {
    const result = await http.post(`${_config.API}cadastro/afiliado`, payload)
    if (!result.data.success) throw new Error(result.data.message)
    return Promise.resolve(result)
  },

  obterAfiliado: async ({ commit }, payload) => {
    const result = await http.get(`${_config.API}cadastro/obter-afiliado`, { params: payload })
    if (!result.data.success) throw new Error(result.data.message)
    commit('SET_AFILIADO', result.data.data)
    return Promise.resolve(result)
  }
}
const mutations = {
  'ADD_USER' (state, payload) {
    if (payload.pix && payload.pix.keyCode) {
      payload.pix.termoPix = true
    } else {
      const pix = Object.assign({}, payload.pix)
      payload.pix = {
        keyCode: '',
        state: pix.state || '',
        bank: pix.bank || '',
        city: pix.city || '',
        termoPix: false
      }
    }
    // gambiarra para evitar alteracao de estado do msm objeto nos outros para evitar perca dos dados do pix
    const aaa = {}
    if (payload.pix) {
      Object.assign(aaa, payload.pix)
    }

    state.oldDadosPix = aaa
    state.dados = cloneDeep(payload)
    const [birthYear, birthMonth, birthDay] = state.dados.birth.split('-')
    const [firstName, ...lastName] = state.dados.fullName.split(' ')
    state.dados.birthYear = birthYear
    state.dados.birthMonth = birthMonth
    state.dados.birthDay = birthDay
    state.dados.firstName = firstName
    state.dados.lastName = lastName.join(' ')
    state.dados.password = payload.jsonFlags.resetPassword ? payload.clearPassword : payload.password
    state.oldDados = assign({}, payload)
  },

  'SET_ESTADOS' (state, payload) {
    state.estados = payload.map(v => ({ label: v.sigla, value: v.sigla, id: v.sigla, text: v.sigla }))
  },
  'SET_DISTRITOS' (state, payload) {
    state.distritos = payload.map(v => ({ label: v.nome, value: v.nome, id: v.nome, text: v.nome }))
  },
  'SET_AFILIADO' (state, payload) {
    state.afiliado = payload
  }
}

const getters = {
  listUser: (state) => state.dados,
  oldUser: (state) => state.oldDados,
  afiliado: (state) => state.afiliado,
}

export default { actions, mutations, state, getters, namespaced: true }
